import { IDepartment } from "interfaces";

import { Form, Row, Col, Show, Card, Tag, Timeline, Icons } from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";

export const DepartmentShow = () => {
  const { queryResult } = useShow<IDepartment>({
    metaData: {
      populate: "*",
    },
  });
  const { isLoading } = queryResult;
  let recordDepartment: any;
  recordDepartment = queryResult.data?.data ? queryResult.data?.data : [];
  
  return (
    <Show title="Department Information" isLoading={isLoading}>
      <Form layout="vertical">

        <Row gutter={16} style={{marginBottom: "1%"}}>
          <Col className="gutter-row" span={12}>
            <Card>
              <Form.Item label={<Tag color="blue">Department code</Tag>} name="dept_code">
                <p
                  style={{ marginBottom: 0, fontWeight: 600 }}
                >{`- ${recordDepartment?.dept_code}`}</p>
              </Form.Item>

              <Form.Item label={<Tag color="green">Department name</Tag>} name="dept_name">
                <p
                  style={{ marginBottom: 0, fontWeight: 600 }}
                >{`- ${recordDepartment?.dept_name}`}</p>
              </Form.Item>

              <Form.Item label={<Tag color="geekblue">Description</Tag>} name="dept_description" style={{marginBottom: 0}}>
                <p
                  style={{ marginBottom: 0, fontWeight: 600 }}
                >{`- ${recordDepartment?.dept_description}`}</p>
              </Form.Item>
              
            </Card>
          </Col>

          <Col className="gutter-row" span={12}>
            <Card>
              <Form.Item label={<Tag color="orange">Company</Tag>} name="company" style={{marginBottom: 0}}>
                <Timeline style={{marginTop: "2%",marginLeft: "2%"}}>
                  {recordDepartment &&
                    recordDepartment?.company?.map(
                      (
                        { id, company_name }: { id: string; company_name: string }
                      ) => (
                        // <p
                        //   style={{ marginBottom: 0, fontWeight: 600 }}
                        // >{`${id} ${company_name}`}</p>
                        <Timeline.Item color="#eb6134" dot={<Icons.InfoCircleOutlined />}>{`${id} ${company_name}`}</Timeline.Item>
                      )
                    )}
                </Timeline>
              </Form.Item>
            </Card>
          </Col>
        </Row>

      </Form>
    </Show>
  );
};
