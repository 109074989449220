import {
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { IContentPostType } from "interfaces";
import dayjs from "dayjs";
import React from "react";

export const ContentPostTypeList: React.FC = () => {
  const compId = localStorage.getItem("COMPANY_ID");

  const { tableProps, searchFormProps } = useTable<
    IContentPostType,
    HttpError,
    IContentPostType
  >({
    metaData: {
      populate: ["company"],
    },
    initialFilter: [
      {
        //field       : "emp_status][id]",
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params:any) => {
      const fillters: CrudFilters = [];
        const { content_type_name } = params;
        console.log(content_type_name)
        fillters.push(
          {
            field: "content_type_name",
            operator: "contains",
            value: content_type_name,
          },
        
        );

      return fillters;
    },
  });

  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  return (
    <List
      title="Leave List"
      headerProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="content_type_name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => onReset()} htmlType="submit" type="dashed">
            <Icons.UndoOutlined /> Reset
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} size="small">
        <Table.Column
          dataIndex="content_type_name"
          title="Content Type Name"
          render={(value: any, record: any) => {
            return <TextField value={record?.content_type_name} />;
          }}
        />

        <Table.Column
          dataIndex="createdAt"
          title="Created"
          render={(value: any, record: any) => {
            return (
              <TextField
                value={dayjs(record?.createdAt).format("YYYY-MM-DD")}
              />
            );
          }}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Update"
          render={(value: any, record: any) => {
            return (
              <TextField
                value={dayjs(record?.updatedAt).format("YYYY-MM-DD")}
              />
            );
          }}
        />

        <Table.Column<IContentPostType>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
