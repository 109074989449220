import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Table,
} from "@pankod/refine-antd";
import React from "react";

interface ITableUserLog {
  dataSource: any[];
  columns: any;
}
export const TableLogUser: React.FC<ITableUserLog> = ({
  columns,
  dataSource,
}) => {
  return (
    <>
      <Col span={24}>
        <Form layout="inline">
          <Form.Item>
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>
          </Form.Item>
        </Form>
      </Col>

      <Col span={24}>
        <Table columns={columns} dataSource={dataSource} />
      </Col>
    </>
  );
};
