import { Avatar, Col, Icons, List, Row } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { BaseRowSatistic, BaseStatistic } from "components/common/statistic";
import React, { ReactNode, useEffect } from "react";
import { AbsentStatistics, CardStatistics, TaskStatistics } from "./statistics";

interface dataStatisticProps {
  total: number;
  label: string;
}
interface IRowSatisticProps {
  header: string;
  headerPercen: string;
  total: string;
  percen: number;
  footerLabel: string;
}
interface IStaticsCard {
  name: string,
  total: string,
  hours: string,
  percen: number,
}
export interface IDataTotal {
  name: string 
  value : number
}
export interface IDataState {
  name: string 
  value : number
}

export interface IDataList {
  name: string 
  stateValue : string
}

export const DashboardAdmin: React.FC = () => {
  const iconsStatistic: ReactNode[] = [
    <Icons.CodeSandboxOutlined />,
    <Icons.DollarCircleOutlined />,
    <Icons.SketchOutlined />,
    <Icons.TeamOutlined />,
  ];

  const dataStatistic: dataStatisticProps[] = [
    {
      total: 112,
      label: "Projects",
    },
    {
      total: 44,
      label: "Clients",
    },
    {
      total: 37,
      label: "Tasks",
    },
    {
      total: 218,
      label: "Clients",
    },
  ];
  const dataRowSatistic: IRowSatisticProps[] = [
    {
      header: "New Employees",
      headerPercen: "+10%",
      total: "10",
      percen: 70,
      footerLabel: "Overall Employees 218",
    },
    {
      header: "Earnings",
      headerPercen: "+12.5%",
      total: "$1,42,300",
      percen: 70,
      footerLabel: "Previous Month $1,15,852",
    },
    {
      header: "Expenses",
      headerPercen: "-2.8%",
      total: "$8,500",
      percen: 70,
      footerLabel: "Previous Month $7,500",
    },
    {
      header: "Profit",
      headerPercen: "-75%",
      total: "$1,12,000",
      percen: 70,
      footerLabel: "Previous Month $1,42,000",
    },
  ];

  const staticsCard:IStaticsCard[] = [
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
  ]

  const dataTotal:IDataTotal[] = [
    {
      name:"Total Tasks",
      value: 385
    },
    {
      name:"Overdue Tasks",
      value: 19
    },
  ]

  const dataState:IDataState[] = [
    {
      name:"Completed Tasks",
      value: 166
    },
    {
      name:"Inprogress Tasks",
      value: 115
    },
    {
      name:"Inprogress Tasks",
      value: 115
    },
    {
      name:"Inprogress Tasks",
      value: 115
    },
    {
      name:"Inprogress Tasks",
      value: 115
    },
  ]

  const dataList:IDataList[] = [
    {
      name: "Martin Lewis",
      stateValue: "Pending",
    },
    {
      name: "Martin Lewis",
      stateValue: "Approved",
    },
  ];
  
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      // console.log(position)
    });
  }, [])
  
  return (
    <List
      
      title={<h3 style={{ marginBottom: 0 }}>Welcome Admin!</h3>}
      // pageHeaderProps={{
      //   extra: (
      //     <Space>
      //       <CreateButton />
      //       <CsvBaseUpload setDataCsv={setDataCsv} />
      //       <ExportButton onClick={triggerExport} loading={exportLoading} />
      //     </Space>
      //   ),
      // }
    >
      {/* <Row gutter={[26, 16]}>
        <Col span={24}>
          <Row gutter={[26, 16]}>
            {dataStatistic &&
              dataStatistic.map(
                ({ total, label }: dataStatisticProps, idx: number) => (
                  <Col key={idx} span={6}>
                    <BaseStatistic
                      icon={iconsStatistic[idx]}
                      total={total}
                      label={label}
                    />
                  </Col>
                )
              )}
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <BaseRowSatistic data={dataRowSatistic} />
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <CardStatistics statics={staticsCard}/>
            </Col>
            <Col span={8}>
              <TaskStatistics total={dataTotal} states={dataState}/>
            </Col>
            <Col span={8}>
              <AbsentStatistics dataList={dataList}/>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </List>
  );
};
