import { Col, Edit, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ILeaveType } from "interfaces";

export const LeaveTypeEdit = () => {

  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<ILeaveType>({
      redirect: "list",
      metaData: {
        populate : ["leave_condition" ,"create_by" , "edit_by"]
      } 
    });
  return (
      <Edit title="Edit Leave Type" isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Leave Code"
              name="leave_type_code"
              rules={[
                {
                  required: true,
                  message: "Please enter leave code.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}>
            
          </Col>
          <Col span={11}>
            <Form.Item
              label={t("leave_type.name")}
              name="leave_type_name"
              rules={[
                {
                  required: true,
                  message: "Please enter leave name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={1} style={{display:"flex" , alignItems:"center"}}>
             Start
          </Col>
         
          <Col span={5}>
            <Form.Item
              label="Year"
              name={["leave_condition", "start_year"]}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter leave day.",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Month"
              name={["leave_condition", "start_month"]}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter leave day.",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}>
           
          </Col>
          <Col span={1} style={{display:"flex" , alignItems:"center"}}>
             To
          </Col>
          <Col span={5}>
            <Form.Item
              label="Year"
              name={["leave_condition", "to_year"]}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter leave day.",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Month"
              name={["leave_condition", "to_month"]}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter leave day.",
              //   },
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}>
           
          </Col>
          <Col span={4}>
            <Form.Item
              label="Leave Days"
              name="leave_days"
              rules={[
                {
                  required: true,
                  message: "Please enter leave day.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  )
}