import { Tabs } from "@pankod/refine-antd";
import React, { ReactNode } from "react";

interface IItem {
  label: string;
  children: ReactNode;
}
interface ICustomTab {
  items: IItem[];
}
export const CustomTab: React.FC<ICustomTab> = ({ items }) => {
  return (
    <Tabs
      className="sad"
      tabBarStyle={{
        background: "#fff",
        padding: "0 1em ",
        borderRadius: 5,
      }}
      defaultActiveKey="1"
    >
      {items.map((value,index) => (
        <Tabs.TabPane tab={value.label} key={index}>
          {value.children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
