import { Form, Input, Select, Upload,message } from "@pankod/refine-antd";
import { useStrapiUpload, getValueProps } from "@pankod/refine-strapi-v4";
import type { UploadProps } from "antd";

import { axiosInstance } from "authProvider";
import { useEffect } from "react";
import { API_URL, TOKEN_KEY } from "../../../../constants";

export const UserProfile: React.FC = () => {
  const { Option } = Select;

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024*1024) > 5 ? false : true
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount:1,
    accept:".png,.jpeg,.jpg",
    action:`${API_URL}/api/upload`,
    name:"files",
    headers:{ Authorization: `Bearer ${TOKEN_KEY}`,},
    listType:"picture"
  };

  useEffect(() => {
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${TOKEN_KEY}`,
    };
    return () => {};
  }, []);

  return (
    <>
      <Form.Item
        name="avatar"
        label="Avatar"
        valuePropName="fileList"
        getValueProps={(data) => getValueProps(data, "")}
      >
        <Upload.Dragger
          {...props}
        >
          <p className="ant-upload-text">
            Profile Drag & drop a file in this area, File size 5 MB, File type
            .png, .jpeg, .jpg
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label="Prefix"
        name="prefix"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select style={{ width: 130 }}>
          <Option value="Mr.">นาย</Option>
          <Option value="Mrs.">นาง</Option>
          <Option value="Ms.">นางสาว</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="First name"
        name="first_name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="last_name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone no"
        name="phone_no"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
