import {
  CreateButton,
  EditButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { ILeaveManagement } from "interfaces";
import React from "react";

type Props = {};

const LeaveManagementList = (props: Props) => {
  const compId = localStorage.getItem("COMPANY_ID");

  const { tableProps, searchFormProps } = useTable<ILeaveManagement, HttpError>(
    {
      metaData: {
        populate: ["leave_type", "company"],
      },
      hasPagination: false,
      initialFilter: [
        {
          //field       : "emp_status][id]",
          field: "company][id]",
          operator: "eq",
          value: compId,
        },
      ],
      onSearch: (params) => {
        const fillters: CrudFilters = [];
        // const { leave_type_name } = params;
        // fillters.push({
        //   field: "leave_type_name",
        //   operator: "contains",
        //   value: leave_type_name,
        // });

        return fillters;
      },
    }
  );

  return (
    <List
      title="Leave Type List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Table {...tableProps} size="small">
        <Table.Column
          dataIndex="leave_type"
          title="Leave Name"
          render={(value: any) => {
            return <TextField value={value.leave_type_name} />;
          }}
        />
        <Table.Column
          title="Longevity"
          render={(value: any, record: any) => {
            const val = `${record.longevity_year} year ${record.longevity_month} days`;

            return <TextField value={val} />;
          }}
        />
        <Table.Column
          dataIndex="receive_leave"
          title="Receive Leave"
          render={(value: any) => {
            return <TextField value={value} />;
          }}
        />

        <Table.Column<ILeaveManagement>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default LeaveManagementList;
