import {
  AntdList,
  Avatar,
  Badge,
  Button,
  Card,
  EditButton,
  Space,
  Tooltip,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";

function CompanyCard({ item }) {
  const { list } = useNavigation();
  function selectCompany(id, project_name, company_code) {
    const objCompany = {
      company_name: project_name,
      company_code,
    };

    localStorage.setItem("COMPANY_ID", id);
    localStorage.setItem("COMPANY_NAME", project_name);
    localStorage.setItem("COMPANY", JSON.stringify(objCompany));

    // localStorage.setItem(
    //   "Default_VALUE",
    //   JSON.stringify({ department: user_profiles.department.id })
    // );

    list("");
  }

  return (
    <>
      <AntdList.Item
        actions={[
          <Space key={item.id} direction="vertical" align="end">
            {/* <Button>sadas</Button> */}
          </Space>,
        ]}
      >
        <AntdList.Item>
          <Badge.Ribbon text={item.company_code}>
            <Card
              bordered={true}
              hoverable={true}
              cover={
                <div
                  className="card_cons"
                  onClick={() =>
                    selectCompany(item.id, item.company_name, item.company_code)
                  }
                >
                  <img
                    alt={item.company_name}
                    src={
                      item.logo?.url ??
                      "https://tsxkeep.s3.ap-southeast-1.amazonaws.com/office_building_ca917c7965.png?updated_at=" +
                        Date.now()
                    }
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              }
              // cover={

              //     <div
              //         className="card_cons"
              //         //onClick={(): void =>show('projects',id)}
              //         onClick={() => selectCompany(item.id, item.company_name)}
              //         style={{
              //             // backgroundImage: "url(" + (item.logo ? item.logo.url  : "https://gw.alipayobjects.com/zos/basement_prod/5edc7f4d-3302-4710-963b-7b6c77ea8d06.svg")  + ")",
              //             backgroundImage: "url(" + "https://gw.alipayobjects.com/zos/basement_prod/5edc7f4d-3302-4710-963b-7b6c77ea8d06.svg" + ")",
              //             backgroundSize: "cover",
              //         }}
              //     >
              //     </div>
              // }
              actions={[
                <Tooltip title="View" color={"blue"} key={"blue"}>
                  <EyeOutlined alt="View" />
                </Tooltip>,
                <Tooltip title="Edit" color={"yellow"} key={"yellow"}>
                  {/* <EditOutlined alt="Edit" /> */}
                  <EditButton
                    hideText
                    icon={<EditOutlined />}
                    size="small"
                    resourceName="companyselect"
                    recordItemId={item.id}
                    style={{ border: 0 }}
                  />
                </Tooltip>,
                // <Tooltip title="Delete" color={"red"} key={"red"}>
                //     <DeleteOutlined alt="DeleteView" />
                // </Tooltip>
              ]}
            >
              <Card.Meta
                onClick={() =>
                  selectCompany(item.id, item.company_name, item.company_code)
                }
                style={{ height: "80px" }}
                // avatar={
                //     <Avatar src={item.logo ? item.logo.url:''} />
                // }
                title={item.company_name}
                description={
                  item.company_description ? item.company_description : ``
                }
              />
            </Card>
          </Badge.Ribbon>
        </AntdList.Item>
      </AntdList.Item>
    </>
  );
}

export default CompanyCard;
