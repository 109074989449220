import {
  List,
  Table,
  Icons,
  Form,
  Button,
  Avatar,
  useTable,
  TextField,
  Space,
  ExportButton,
  Input,
  useModalForm,
  Modal,
  Row,
  Col,
} from "@pankod/refine-antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { DatePicker, DatePickerProps, TimePicker } from "antd";
import {
  CanAccess,
  CrudFilters,
  HttpError,
  useApiUrl,
  useCustom,
  useExport,
  useList,
  useUpdate,
} from "@pankod/refine-core";
import { IAttendance } from "interfaces";
import moment from "moment";
import { useState } from "react";

export const AttendancAdmin: React.FC = () => {
  const format = "YYYY-MM-DD";
  const apiUrl = useApiUrl();
  const { RangePicker } = DatePicker;
  const [dateseasch, setDateseasch] = useState<any>([
    moment(new Date()).format(format),
    moment(new Date()).format(format),
  ]);
  const { mutate } = useUpdate<IAttendance, HttpError, {}>();
  const compID = localStorage.getItem("COMPANY_ID");
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
  } = useTable<IAttendance, HttpError, IAttendance>({
    resource: "attendances",
    metaData: {
      populate: [
        "user_profile",
        "user_profile.companies",
        "user_profile.branches",
      ],
    },
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],

    permanentFilter: [
      {
        field: "user_profile].[companies].[id]",
        operator: "eq",
        value: compID,
      },
      //   {
      //   field: "time_date",
      //   operator: "eq",
      //   value: dateseasch,
      // },
      {
        field: "time_date",
        operator: "between",
        value: dateseasch,
      },
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { first_name, emp_no } = params;
      filters.push(
        {
          field: "time_date",
          operator: "between",
          value: dateseasch,
        },
        {
          field: "user_profile].[emp_no]",
          operator: "containss",
          value: emp_no,
        },
        {
          field: "user_profile].[first_name]",
          operator: "containss",
          value: first_name,
        }
      );

      return filters;
    },
  });

  const clocks = useList<any>({
    resource: "clock/curtime_user",
    metaData: {
      populate: ["user_profile", "user_profile.companies"],
    },
    config: {
      filters: [
        {
          field: "user_profile].[companies].[id]",
          operator: "eq",
          value: compID,
        },
        {
          field: "date_track",
          operator: "between",
          value: dateseasch,
        },
      ],
      pagination: {
        pageSize: 1000,
      },
    },
  });

  const { data: dataClocks } = clocks;

  

  const { modalProps, formProps, show, id, close } = useModalForm<IAttendance>({
    resource: "attendances",
    action: "edit",
  });

  const mount: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const year: number[] = [];
  for (let i = 2015; i <= new Date().getFullYear(); i++) {
    year.push(i);
  }

  const onReset = () => {
    setDateseasch([
      moment(new Date()).format(format),
      moment(new Date()).format(format),
    ]);
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  const exportUserInfo = () => {
    let obj: string[] = [];

    dataClocks?.data.map((val: string) => obj.push(`${val}\r\n`));

    const blob = new Blob([...obj], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "user-info";
    link.href = url;
    link.click();
  };

  const { triggerExport, isLoading: exportLoading } = useExport<IAttendance>({
    resourceName: "attendances",
    metaData: {
      populate: [
        "user_profile",
        "user_profile.companies",
        "user_profile.branches",
      ],
      pagination : false
    },
    sorter: [{ order: "asc", field: "time_date" }],
    filters: [
      {
        field: "user_profile].[companies].[id]",
        operator: "eq",
        value: compID,
      },
      {
        field: "time_date",
        operator: "between",
        value: dateseasch,
      },
    ],
    mapData: (item) => {
      return {
        Branch: item.user_profile.branches[0].branch_name,
        Date: item.time_date,
        "Employee ID": item.user_profile.emp_no,
        Prefix: item.user_profile.prefix,
        "First name": item.user_profile.first_name,
        "Last name": item.user_profile.last_name,
        "Clock In": item.time_in,
        "Clock Out": item.time_out,
        "Time summary": item.time_summary,
        "Remark Check In": item.track_loc_in ?? "-",
        "Remark Check Out": item.track_loc_out ?? "-",
      };
    },
  });

  // const onChange: DatePickerProps["onChange"] = (_, dateString) =>
  //   setDateseasch(dateString);

  const ButtomTest = (props: any) => {
    return <Button type="dashed">5555</Button>;
  };

  const CustomTimePicker = (props: any) => {
    const { onChange, value } = props;
    const time = value ?? "00:00:00";
    return (
      <TimePicker
        allowClear={false}
        format={"HH:mm"}
        defaultValue={moment(time, "HH:mm")}
        onChange={onChange}
      />
    );
  };

  const onChangeDateRange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    // console.log("Selected Time: ", value);
    setDateseasch(dateString);
    console.log("Formatted Selected Time: ", dateString);
  };
  return (
    <List
      title="Attendance"
      pageHeaderProps={{
        extra: (
          <Space>
            <ExportButton
              disabled={tableProps.dataSource?.length === 0 ? true : false}
              onClick={triggerExport}
              loading={exportLoading}
            >
              Export CSV.
            </ExportButton>
            <Button
              disabled={tableProps.dataSource?.length === 0 ? true : false}
              onClick={() => exportUserInfo()}
            >
              Export Text File
            </Button>
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        {/* <Form.Item
          name="time_date"
          getValueProps={(value) => ({
            value: value ? moment(value) : moment(dateseasch),
          })}
        >
          <DatePicker allowClear={false} onChange={onChange} />
        </Form.Item> */}
        <Form.Item
          name="time_date_rang"
          getValueProps={(value) => ({
            value: value
              ? [moment(value[0]), moment(value[1])]
              : [moment(dateseasch[0]), moment(dateseasch[1])],
          })}
        >
          <RangePicker allowClear={false} onChange={onChangeDateRange} />
        </Form.Item>

        <Form.Item name="emp_no">
          <Input placeholder="Employee ID" />
        </Form.Item>

        <Form.Item name="first_name">
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => onReset()} htmlType="submit" type="dashed">
            <Icons.UndoOutlined /> Reset
          </Button>
        </Form.Item>
        {/* <CanAccess resource="attendance_list" action="export">
          <ButtomTest />
        </CanAccess> */}
      </Form>
      <br />
      <Table {...tableProps} size="small" rowKey={(val) => val.id}>
        <Table.Column
          title="Branches"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField
                  value={record.user_profile?.branches[0]?.branch_name}
                />
              </>
            );
          }}
        />
        <Table.Column
          align="center"
          width={35}
          title=""
          render={(value: any, record: any) => {
            return (
              <>
                {record ? (
                  <Avatar
                    size={32}
                    src={record.user_profile?.line_pictureUrl}
                  />
                ) : (
                  <>
                    <Avatar size={32} icon={<Icons.UserOutlined />} />
                  </>
                )}
              </>
            );
          }}
        />

        <Table.Column
          title="Name"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField
                  value={`${record.user_profile?.prefix} ${record.user_profile?.first_name} ${record.user_profile?.last_name}`}
                />
              </>
            );
          }}
        />
        <Table.Column
          title="Employee ID"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField value={`${record.user_profile?.emp_no}`} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="time_date"
          title="Date"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField value={value} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="time_in"
          title="Clock In"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField value={value} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="time_out"
          title="Clock Out"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField value={value} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="time_summary"
          title="Working Period"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField value={value} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="track_loc_in"
          title="Remark Clock In"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField
                  value={
                    value
                      ? value === "OVER_LOC"
                        ? "OUT OF OFFICE RADIUS"
                        : value
                      : "-"
                  }
                />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex="track_loc_out"
          title="Remark Clock Out"
          render={(value: any, record: any) => {
            return (
              <>
                <TextField
                  value={
                    value
                      ? value === "OVER_LOC"
                        ? "OUT OF OFFICE RADIUS"
                        : value
                      : "-"
                  }
                />
              </>
            );
          }}
        />
      </Table>
      <Modal {...modalProps} width={520}>
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values: any) => {
            values.time_in = values.time_in
              ? moment(values.time_in, "HH:mm:ss").format("HH:mm:ss.SSS")
              : values.time_in;

            values.time_out = values.time_out
              ? moment(values.time_out, "HH:mm:ss").format("HH:mm:ss.SSS")
              : values.time_out;
            mutate(
              {
                resource: "attendances",
                id: id ?? 0,
                values: values,
              },
              {
                onError: (error: any, variables: any, context: any) => {
                  console.log("An error occurred:", error.response);
                },
                onSuccess: (data: any, variables, context: any) => {
                  close();
                },
              }
            );
          }}
        >
          <Row gutter={[8, 8]}>
            <Col span={5}>
              <Form.Item label="Clock in" name="time_in">
                <CustomTimePicker />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Clock out" name="time_out">
                <CustomTimePicker />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                label="Remark"
                name="remark"
                rules={[
                  {
                    required: true,
                    message: "Please enter time remark.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item label="Title" name="title">
                        <Input />
                    </Form.Item>
                    */}
        </Form>
      </Modal>
    </List>
  );
};
