import { Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { DashboardAdmin, DashboardEmployee } from "components/page/dashboard";
import { useEffect } from "react";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  // const { t } = useTranslation();
  const compID = localStorage.getItem("COMPANY_ID");
  const userType = localStorage.getItem("USER_TYPE");
  const { list } = useNavigation();

  useEffect(() => {
    if (!compID) {
      localStorage.removeItem("COMPANY");
      localStorage.removeItem("COMPANY_NAME");
      localStorage.removeItem("Default_VALUE");
      list("companyselect");
    }
  }, [compID]);

  return (
    <>
      {userType && userType === "Admin" ? (
        <DashboardAdmin />
      ) : (
        <DashboardEmployee />
      )}
    </>
  );
};
