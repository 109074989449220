import {
  Button,
  Col,
  Create,
  Form,
  Icons,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  TextField,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { DatePicker, DatePickerProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { ICompany, IDepartment, IEmployee, IPlan } from "interfaces";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import {
  HttpError,
  useCreate,
  useDelete,
  useUpdate,
} from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";

type IFormPlan = {
  company: number;
  date: string[];
  department: number;
  shift: string;
};

const { RangePicker } = DatePicker;

export const CreateAll: React.FC = () => {
  const comId: any = localStorage.getItem("COMPANY_ID");
  const depart: any = localStorage.getItem("Default_VALUE");

  const dt: any = new Date();
  const format = "YYYY-MM-DD";
  const from = useRef<any>();
  const [dateseasch, setDateseasch] = useState<any>([
    dayjs(new Date()).format(format),
    dayjs(new Date()).format(format),
  ]);
  const [dates, setDates] = useState<any>();
  const [search, setSearch] = useState<string[]>(["1991-01-01", "1991-01-01"]);
  const [dataColum, setdataColum] = useState<ColumnsType<any>>([]);

  const { mutate } = useCreate<any, HttpError, {}>();
  const { mutate: update } = useUpdate<IPlan, HttpError, {}>();
  const { mutate: deletePlan } = useDelete();
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
  } = useTable<IEmployee, HttpError, IFormPlan>({
    resource: "user-profile/plan",
    metaData: {
      populate: [
        "companies",
        "plan_managements",
        "plan_managements.shift_management",
        "department",
      ],
    },
    permanentFilter: [
      {
        field: "companies].[id]",
        operator: "eq",
        value: comId,
      },
      {
        field: "department].[id]",
        operator: "eq",
        value: from.current?.getFieldValue("department") ?? null,
      },
      {
        field: "plan_managements].[plan_date]",
        operator: "between",
        value: search,
      },
    ],
  });
  const { loading } = tableProps;

  const { selectProps: companyProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: "company_name",
  });

  const { selectProps: department } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: comId,
      },
    ],
  });

  const { selectProps: shift } = useSelect<IDepartment>({
    resource: "shift-managements",
    optionLabel: "shift_code",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: comId,
      },
    ],
  });

  const {
    modalProps,
    formProps,
    show: editModalShow,
    close,
    id,
  } = useModalForm<IPlan>({
    resource: "plan-managements",
    action: "edit",
    metaData: {
      populate: "*",
    },
  });

  const createPlan = (
    shift: string,
    department: number,
    company: number,
    date: string[]
  ) =>
    mutate({
      resource: "user-profile/plan",
      values: {
        date: date,
        shift,
        department,
        company,
      },
    });

  const onChangeDateRange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setDateseasch(dateString);
  };

  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }
    return false;
    // const tooLate =
    //   dates[0] &&
    //   current.diff(dates[0], "days") >
    //     new Date(
    //       new Date(dates[0]).getFullYear(),
    //       new Date(dates[0]).getMonth() + 1,
    //       0
    //     ).getDate() -
    //       new Date(dates[0]).getDate();

    // const tooEarly =
    //   dates[1] &&
    //   current.diff(dates[1], "days") <
    //     new Date(
    //       new Date(dates[1]).getFullYear(),
    //       new Date(dates[1]).getMonth() + 1,
    //       0
    //     ).getDate() -
    //       new Date(dates[1]).getDate();

    // return !!tooEarly || !!tooLate;
  };

  // const onOpenChange = (open: boolean) => {
  //   if (open) {
  //     setDates([null, null]);
  //   } else {
  //     setDates(null);
  //   }
  // };

  const setColum = (numDate: number) => {
    const columns: ColumnsType<any> = [
      {
        title: "Profile",
        dataIndex: "profile_name",
        ellipsis: true,
        key: "profile_name",
        fixed: "left",
        width: 175,
        render: (text) => <TextField value={text} />,
      },
    ];

    for (let index = 0; index < numDate; index++) {
      columns.push({
        title: `${index + 1}`,
        dataIndex: `${index + 1}`,
        key: `${index + 1}`,
        width: 75,
        align: "center",
        render: (_, record) => {
          return (
            <>
              {record[index + 1]?.shift_management?.color && (
                <Popconfirm
                  title=""
                  cancelText="Delete"
                  okText="View"
                  icon={<></>}
                  onCancel={() => {
                    deletePlan(
                      {
                        resource: "plan-managements",
                        id: record[index + 1].id,
                      },
                      {
                        onSuccess() {
                          refetch();
                        },
                      }
                    );
                  }}
                  onConfirm={() => editModalShow(record[index + 1].id)}
                >
                  <Tag
                    style={{ cursor: "pointer" }}
                    color={record[index + 1].shift_management.color}
                    // onClick={() => editModalShow(record[index + 1].id)}
                  >
                    <TextField
                      value={record[index + 1].shift_management.shift_code}
                    />
                  </Tag>
                </Popconfirm>
              )}
            </>
          );
        },
      });
    }
    setdataColum(columns);
  };

  const strToDate = (strDate: string) => {
    const date = new Date(strDate);
    const numDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    return numDate;
  };

  return (
    <Create
      isLoading={loading && loading ? true : false}
      title="Create Plan"
      footerButtons={() => <></>}
    >
      <Form
        {...searchFormProps}
        ref={from}
        onFinish={(values: any) => {
          values.date = [dateseasch[0], dateseasch[1]];

          setColum(strToDate(dateseasch[0]));

          createPlan(
            values.shift,
            values.department,
            values.company,
            values.date
          );

          setSearch([dateseasch[0], dateseasch[1]]);
        }}
        initialValues={{
          company: parseInt(comId),
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={5}>
            <Form.Item label="Company" name="company">
              <Select placeholder="Select" {...companyProps} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select placeholder="Select" {...department} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item
              label="Shift"
              name="shift"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select placeholder="Select" {...shift} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="date"
              name="date"
              getValueProps={(value) => ({
                value: value
                  ? [dayjs(value[0]), dayjs(value[1])]
                  : [dayjs(dateseasch[0]), dayjs(dateseasch[1])],
              })}
            >
              <RangePicker
                // onCalendarChange={(val) => setDates(val)}
                // disabledDate={disabledDate}
                allowClear={false}
                onChange={onChangeDateRange}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={3}>
            <Button htmlType="submit" type="primary">
              <Icons.AppstoreAddOutlined /> Create Plan
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        {...tableProps}
        loading={false}
        columns={dataColum}
        scroll={{ x: 1300 }}
        size="small"
        rowKey={(val) => val.id}
      />

      <Modal
        {...modalProps}
        width={400}
        bodyStyle={{ paddingTop: 16 }}
        title="Edit Shift"
        className="modeal-custom"
      >
        <Form
          {...formProps}
          onFinish={(values: any) => {
            update(
              {
                resource: "plan-managements",
                id: id ?? 0,
                values: values,
              },
              {
                onError: (error: any, variables: any, context: any) => {
                  console.log("An error occurred:", error.response);
                },
                onSuccess: (data: any, variables, context: any) => {
                  refetch();
                  close();
                },
              }
            );
          }}
          layout="vertical"
        >
          <Form.Item label="Date" name="plan_date">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Profile"
            name="user_profile"
            getValueProps={(value) => ({
              value: value ? `${value.first_name} ${value.last_name}` : "",
            })}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Old Shift"
            name={["shift_management", "shift_code"]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item label="New Shift" name={["shift_management", "id"]}>
            <Select placeholder="Select" {...shift} />
          </Form.Item>
        </Form>
      </Modal>
    </Create>
  );
};
