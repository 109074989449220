export const columDesc = [
  {
    name: "Phone",
    value: "phone_no",
    option: false,
  },
  {
    name: "Email",
    value: "email",
    option: false,
  },
  {
    name: "Birthday",
    value: "date_of_birth",
    option: false,
  },
  {
    name: "Address",
    value: "address",
    option: false,
  },
  {
    name: "Gender",
    value: "gender",
    option: false,
  },
  {
    name: "Reports to",
    value: "-",
    option: false,
  },
];

export const columPers = [
  {
    name: "Passport No",
    value: "-",
    option: false,
  },
  {
    name: "Passport Exp Date",
    value: "-",
    option: false,
  },
  {
    name: "Tel",
    value: "-",
    option: false,
  },
  {
    name: "Nationality",
    value: "-",
    option: false,
  },
  {
    name: "Religion",
    value: "-",
    option: false,
  },
  {
    name: "Marital status",
    value: "-",
    option: false,
  },
  {
    name: "Employment of spouse",
    value: "-",
    option: false,
  },
  {
    name: "No. of children",
    value: "-",
    option: false,
  },
];

export const columEmer = [
  {
    name: "Primary",
    value: "-",
    option: false,
  },
  {
    name: "Name",
    value: "-",
    option: false,
  },
  {
    name: "Relationship",
    value: "-",
    option: false,
  },
  {
    name: "Phone",
    value: "-",
    option: false,
  },
  {
    name: "Secondary",
    value: "-",
    option: true,
  },
  {
    name: "Name",
    value: "-",
    option: false,
  },
  {
    name: "Relationship",
    value: "-",
    option: false,
  },
  {
    name: "Phone",
    value: "-",
    option: false,
  },
];

export const columBank = [
  {
    name: "Bank name",
    value: "-",
    option: false,
  },
  {
    name: "Bank account No",
    value: "-",
    option: false,
  },
  {
    name: "IFSC Code",
    value: "-",
    option: false,
  },
  {
    name: "PAN No",
    value: "-",
    option: false,
  },
];

