import { Form, Input } from "@pankod/refine-antd";

export const User: React.FC = () => {
  return (
    <>
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid email',
          },
          {
            required: true,
            message: "Please enter email",
          },
          
        ]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter password",
          },
          () => ({
            validator(_, value){
              if (!value || value.length >= 8 &&  value.length <= 16) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Password must be at least 8-16 characters'));
            }
          })
        ]}
      >
        <Input.Password type="Password" placeholder="●●●●●●●●"/>
      </Form.Item>
    </>
  );
};
