import { Col, Create, Form, Input, Row, useForm } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { mediaUploadMapper } from '@pankod/refine-strapi-v4';
import { IPosition } from 'interfaces';

export const PositionCreate = () => {

  const compID          = localStorage.getItem("COMPANY_ID");

  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IPosition>({
      redirect: "list",
    });
  return (
    <Create title="Create Position" saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical" onFinish={(values: any) => {
          values.company =compID
         return formProps.onFinish?.({
            ...mediaUploadMapper(values)
            //,stores: store,
        });
      }}>
         <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("position.code")}
                  name="pos_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Position code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("position.name")}
                  name="pos_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Position name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Form.Item
              label={t("position.desc")}
              name="pos_description"
              rules={[
                {
                  required: true,
                  message: "Please enter Position description.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
         </Row>
      </Form>
    </Create>
  )
}
