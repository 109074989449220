import {
  Button,
  Col,
  Form,
  Icons,
  Input,
  Modal,
  Row,
  useModalForm,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { BaseMenu } from "components/common/menu";
import { IRole } from "interfaces";
import React from "react";

interface IItemProps {
  id: string;
  custom_role_name: string;
}
interface IRoleMstProps {
  data: IItemProps[];
  onSelectRole?: (val: string) => any;
  comId?: string;
  userId?: string
}

export const RoleMasters: React.FC<IRoleMstProps> = ({
  data,
  onSelectRole = () => ({}),
  comId,
  userId
}) => {
  const {
    modalProps: modalCreate,
    formProps: formCreate,
    show: showCreate,
  } = useModalForm<IRole>({
    action: "create",
  });
  const {
    modalProps: modalEdit,
    formProps: formEdit,
    show: showEdit,
  } = useModalForm<IRole>({
    action: "edit",
  });

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          {" "}
          <Button
            style={{
              width: "100%",
            }}
            onClick={() => showCreate()}
            type="primary"
            size="large"
            icon={<Icons.PlusOutlined />}
          >
            Add Roles
          </Button>
        </Col>
        <Col span={24}>
          <BaseMenu
            items={data}
            onShowEdit={(val: string) => showEdit(val)}
            onSelectRole={(val: string) => onSelectRole(val)}
          />
        </Col>
      </Row>
      <Modal
        {...modalCreate}
       
        width={500}
        bodyStyle={{ paddingTop: 16 }}
        title="Add Role"
        className="modeal-custom"
      >
        <Form
          {...formCreate}
          onFinish={(values: any) => {
            values.company = comId;
            // values.user_creator = userId
            return formCreate.onFinish?.({
              ...mediaUploadMapper(values),
            });
          }}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Role name"
                name="custom_role_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter role name.",
                  },
                ]}
              >
                <Input className="input" size="large" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        {...modalEdit}
        width={500}
        bodyStyle={{ paddingTop: 16 }}
        title="Add Role"
        className="modeal-custom"
      >
        <Form {...formEdit}  onFinish={(values: any) => {
            values.user_last_editor = userId
            return formEdit.onFinish?.({
              ...mediaUploadMapper(values),
            });
          }} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Role name"
                name="custom_role_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter role name.",
                  },
                ]}
              >
                <Input className="input" size="large" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
