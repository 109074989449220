import "./styles.css";
export const MapMarker: React.FC<{ lat?: number; lng?: number }> = (
  props: any
) => {
  return (
    <>
       <div className="marker"></div>
    </>
  );
};
