import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";

import { TOKEN_KEY, API_URL } from "./constants";

import axios from "axios";

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { data, status } = await strapiAuthHelper.login(username, password);
    if (status === 200) {
      const { data: auth, status: statusZauth } = await axiosInstance.post(API_URL + "/api/users-permissions/user/zauth", null, { headers: { Authorization: `Bearer ${data.jwt}` } })
      if (statusZauth === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem("USER_ID", data.user.id.toString());
        localStorage.setItem("USER_TYPE", auth.result[0].user_type.type_name)

        const { data: profile, status } = await axiosInstance.get(API_URL + `/api/users?populate[0]=user_profile&filters[id][$eq]=${data.user.id.toString()}`, { headers: { Authorization: `Bearer ${data.jwt}` } })
        localStorage.setItem("PID", profile[0].user_profile.id);

        if (auth.result[0].user_type.id === 2) {

          return Promise.resolve("/companyselect");
        } else {
          localStorage.setItem("COMPANY_ID", auth.result[0].company.id);
          localStorage.setItem("COMPANY_NAME", auth.result[0].company.company_name);
          localStorage.setItem("COMPANY", JSON.stringify(auth.result[0].company))
          return Promise.resolve(`/emp/user-profiles/show/${profile[0].user_profile.id}`);
        }
      }
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    const token = localStorage.getItem(TOKEN_KEY);
   
    if (error && !token)
      return Promise.reject();

    return Promise.resolve()
  },
  checkAuth: () => {
    
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      return Promise.reject();
    }


    const { data, status } = await strapiAuthHelper.me(token);

    const { data: u } = await axiosInstance.get(API_URL + `/api/user-profiles?filters[user_permission][id][$eq]=${data.id.toString()}`, { headers: { Authorization: `Bearer ${token}` } })
    let user_profile = u.data;
    //let user_profile = user_data;
    
    if (status === 200) {
      const { id, username, email } = data;
      return Promise.resolve({
        id,
        username,
        email,
        user_profile
      });
    }

    return Promise.reject();
  },
};
