import React from "react";
import {
  useLogin,
  useNavigation,
} from "@pankod/refine-core";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from "@pankod/refine-antd";
import "./styles.css";
import { AuthHelper } from "@pankod/refine-strapi-v4";

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const { create, list, push } = useNavigation();
  const { mutate: login } = useLogin<ILoginForm>();

  localStorage.clear();

  const CardTitle = (
    <Title level={3} className="title">
      <div className="imageContainer">
        <img className="logoLogin" src="./images/logo2.png" alt="KEEP Logo" />
      </div>
      Sign in your account
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card hoverable title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={async (values) => login(values)}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input.Password
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Remember me
                    </Checkbox>
                  </Form.Item>

                  {/* <a
                    style={{
                      float: "right",
                      fontSize: "12px"
                    }}
                    href="#"
                  >
                    Forgot password?
                  </a> */}
                </div>
                <Button
                  style={{ marginBottom: "12px" }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Sign in
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={(): void => create("register")}
                  block
                >
                  Sign up
                </Button>
              </Form>
              {/* <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Don’t have an account?{" "}
                  <a href="#" style={{ fontWeight: "bold" }}>
                    Sign up
                  </a>
                </Text>
              </div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
