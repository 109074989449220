import { useRouterContext } from "@pankod/refine-core";
import React, { useEffect, useRef, useState } from "react";
import { CreateAll } from "./create_all";
import { CreateSingle } from "./create_single";

type PropsData = {
  queryString: string;
};

const initailData = {
  queryString: "",
};
export const CreatePlan: React.FC = () => {
  const { useLocation } = useRouterContext();
  const { search } = useLocation();
  const [data, setData] = useState<PropsData>(initailData);
  const efRender = useRef(false);

  useEffect(() => {
    efRender.current = true;
    if (efRender.current) {
      setData((pre) => ({ ...pre, queryString: search.split("=")[1] }));
    }

    return () => {
      efRender.current = false;
    };
  }, []);
  const { queryString } = data;
  return (
    <>
      {queryString && queryString === "all" ? <CreateAll /> : <CreateSingle />}
    </>
  );
};
