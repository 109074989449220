import {
  Checkbox,
  Col,
  Create,
  Form,
  Input,
  Radio,
  Row,
  Space,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ILeaveType } from "interfaces";
import { useState } from "react";

export const LeaveTypeCreate = () => {
  const t = useTranslate();
  const compId = localStorage.getItem("COMPANY_ID");

  const [accuCheck, setAccuCheck] = useState<boolean>(false);

  const { formProps, saveButtonProps } = useForm<ILeaveType>({
    redirect: "list",
  });

  

  return (
    <Create title="Create Leave Type" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{
          status: "ACTIVE"
        }}
        layout="vertical"
        onFinish={(values: any) => {
          console.log(values)
           values.company = compId;
           values.leave_accumulation_total = parseInt(values.leave_accumulation_total)
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Leave Code"
              name="leave_type_code"
              rules={[
                {
                  required: true,
                  message: "Please enter leave code.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label={t("leave_type.name")}
              name="leave_type_name"
              rules={[
                {
                  required: true,
                  message: "Please enter leave name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={4} style={{ display: "flex", fontWeight: "bold" }}>
            <Form.Item name="is_accumulation" valuePropName="checked" noStyle>
              <Checkbox
                // style={{
                //   fontSize: "12px",
                // }}
                onChange={(e) => setAccuCheck(e.target.checked)}
              >
                Accumulation
              </Checkbox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item
              rules={[
                {
                  required: accuCheck,
                  message: "Please enter accumulation total.",
                },
              ]}
              name="leave_accumulation_total"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" style={{ textAlign: "center" }} span={2}>
            <p>Total</p>
          </Col>

          <Col className="gutter-row" span={14}></Col>

          <Col span={3} style={{ display: "flex", fontWeight: "bold" }}>
            Status
          </Col>
          <Col className="gutter-row" span={21}>
            <Form.Item name="status">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="ACTIVE">Active</Radio>
                  <Radio value="INACTIVE">Inactive</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
