import { Button, Col, Progress, Row } from '@pankod/refine-antd'
import { BaseCard } from 'components/common/card'
import React from 'react'
interface IData {
    date: string,
    full_date: string,
    hours: string,
    break: string,
    overtime: string,
}
interface ITimeSheet {
    timesheet:IData
}
export const TimesSheet:React.FC<ITimeSheet> = ({timesheet}) => {
  return (
    <Col className="gutter-row" span={8}>
          <BaseCard
            size="small"
            title={
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p style={{ fontSize: 20, fontWeight: 500 }}>Timesheet</p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    marginLeft: 10,
                    color: "gray",
                  }}
                >
                  {timesheet.date}
                </p>
              </div>
            }
          >
            <Row gutter={[8, 30]} justify="center">
              <Col span={24}>
                <BaseCard size="small">
                  <p style={{ fontSize: 12, fontWeight: 700 }}>Punch In at</p>
                  <p style={{ fontSize: 12, fontWeight: 500 }}>
                    {timesheet.full_date}
                  </p>
                </BaseCard>
              </Col>
              <Col
                span={24}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Progress
                  type="circle"
                  percent={0}
                  format={() => `${timesheet.hours} hrs`}
                />
              </Col>
              <Col
                span={24}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Button type="primary" shape="round" size="large">
                  Punch Out
                </Button>
              </Col>
              <Col span={12}>
                <BaseCard size="small">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: 12, fontWeight: 700 }}>Break</p>
                    <p style={{ fontSize: 12, fontWeight: 500 }}>
                      {`${timesheet.break} hrs`}
                    </p>
                  </div>
                </BaseCard>
              </Col>
              <Col span={12}>
                <BaseCard size="small">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: 12, fontWeight: 700 }}>Overtime</p>
                    <p style={{ fontSize: 12, fontWeight: 500 }}>
                      {`${timesheet.overtime} hrs`}
                    </p>
                  </div>
                </BaseCard>
              </Col>
            </Row>
          </BaseCard>
        </Col>
  )
}