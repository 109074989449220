import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  ShowButton,
  Icons,
  CreateButton,
  Form,
  Input,
  Button,
  ExportButton,
} from "@pankod/refine-antd";
import { IDepartment } from "interfaces";
export const DepartmentList: React.FC<IResourceComponentsProps> = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<
    IDepartment,
    HttpError,
    IDepartment
  >({
    metaData: {
      populate: "company",
    },
    initialFilter: [
      {
        field: "company].[id]",
        operator: "eq",
        value: compID,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { dept_name } = params;
      fillters.push({
        field: "dept_name",
        operator: "containss",
        value: dept_name,
      });

      return fillters;
    },
  });
  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  const { triggerExport, isLoading: exportLoading } =
    useExport<IDepartment>({
      resourceName: "departments",
      sorter: [{ order: "asc", field: "id" }],
      filters: [{ field: "company].[id]", operator: "eq", value: compID }],
      exportOptions: {
        filename: `Department for KEEP`,
      },
      mapData: (item) => {
        return {
          "Department ID": item.id,
          "Department Name": item.dept_name,
        };
      },
    });


  return (
    <>
      <List
        title="Department List"
        pageHeaderProps={{
          extra: (
            <Space>
              <CreateButton />
              <ExportButton
                disabled={tableProps.dataSource?.length === 0 ? true : false}
                onClick={triggerExport}
                loading={exportLoading}
              >
                Export CSV.
              </ExportButton>
            </Space>
          ),
        }}
      >
        <Form {...searchFormProps} layout="inline">
          <Form.Item name="dept_name">
            <Input placeholder="Department Name" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={() => onReset()} htmlType="submit" type="dashed">
              <Icons.UndoOutlined /> Reset
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Table {...tableProps} rowKey={(val) => val.id} size="small">
          <Table.Column
            dataIndex="id"
            title="#"
            render={(value: any, record: any) => {
              return <TextField value={value} />;
            }}
          />
          <Table.Column
            dataIndex="dept_code"
            title="Department Code"
            render={(value: any, record: any) => {
              return <TextField value={record.dept_code} />;
            }}
          />

          <Table.Column
            dataIndex="dept_name"
            title="Department Name"
            render={(value: any, record: any) => {
              return <TextField value={record.dept_name} />;
            }}
          />
          <Table.Column
            dataIndex="dept_description"
            title="Department Description"
            render={(value: any, record: any) => {
              return <TextField value={record.dept_description} />;
            }}
          />
          <Table.Column
            dataIndex="company"
            title="Company"
            render={(value: any, record: any) => {
              return (
                <TextField
                  value={
                    record.company[0] ? record.company[0].company_name : ""
                  }
                />
              );
            }}
          />
          <Table.Column<IDepartment>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                ></ShowButton>
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
