import { CrudFilters, HttpError, IResourceComponentsProps, useExport } from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    Row,
    Col,
    Card,
    Statistic,
    Icons,
    DateField,
    CreateButton,
    Form,
    Radio,
    Avatar,
    Input,
    Divider,
    Button,
    ExportButton,
} from "@pankod/refine-antd";
import { IContentPost, IEmployee, IHoliday } from "interfaces";

export const HolidayList: React.FC<IResourceComponentsProps> = () => {
    const compID = localStorage.getItem("COMPANY_ID");
    const { tableProps,searchFormProps } = useTable<IHoliday,HttpError,IHoliday>({
        metaData: {
            populate: '*'
            // populate: ['last_editor_user','user_creator']
        },
        initialSorter:[
            {
                field   : "id",
                order   : "asc",
            }
        ],
        initialFilter: [ 
            {
                //field       : "emp_status][id]",
                field       : "company][id]",
                operator    : "eq",
                value       : compID
            }
        ],
        // onSearch: (params) => {
        //     const filters: CrudFilters = [];
        //     const { emp_status,emp_no,first_name } = params;
        //     //.log(emp_status);
        //     filters.push(
        //         {
        //             field       : "status",
        //             operator    : "contains",
        //             value       : emp_status
        //         },
        //         {
        //             field: "emp_no",
        //             operator: "contains",
        //             value: emp_no,
        //         },
        //         {
        //             field: "first_name", 
        //             operator: "eq",
        //             value: first_name,
        //         },
        //     );
        //     return filters;
        // }
    });

    // "data": {
    //     "holiday_date": "2022-09-05",
    //     "holiday_name": "string",
    //     "company": "string or id"
    //   }
    return (
        <>
            <List
                title="Holiday List"
                pageHeaderProps={{
                    extra: (
                        <Space>
                            <CreateButton />
                            {/* <ExportButton
                                onClick={triggerExport}
                                loading={exportLoading}
                            /> */}
                        </Space>
                        // <Row gutter={16}>
                        //     <Col span={12}>
                        //         <Card>
                        //         <Statistic
                        //             title="วันนี้"
                        //             value={20}
                        //             // precision={2}
                        //             valueStyle={{ color: '#3f8600' }}
                        //             prefix={<Icons.UserOutlined />}
                        //             suffix="คน"
                        //         />
                        //         </Card>
                        //     </Col>
                        //     <Col span={12}>
                        //         <Card>
                        //         <Statistic
                        //             title="เมื่อวาน"
                        //             value={24}
                        //             //precision={2}
                        //             valueStyle={{ color: '#cf1322' }}
                        //             prefix={<Icons.UserOutlined />}
                        //             suffix="คน"
                        //         />
                        //         </Card>
                        //     </Col>
                        //     <Col >
                        //         <CreateButton />
                        //     </Col>
                        // </Row>
                    ),
                }}
            >
                {/* <Divider/> */}
                {/* <Form
                    {...searchFormProps}
                    layout = "inline"
                >
                    <Form.Item name="emo_no">
                        <Input placeholder="Employee ID"/>
                    </Form.Item>
                    <Form.Item name="first_name">
                        <Input placeholder="Name"/>
                    </Form.Item>
                    <Form.Item initialValue="true" name="emp_status" >
                        <Radio.Group onChange={onStatusChange} >
                            <Radio.Button value="true">ACTIVE</Radio.Button>
                            <Radio.Button value="false">IN-ACTIVE</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item >
                        <Button htmlType="submit" type="primary">
                            <Icons.SearchOutlined /> Search
                        </Button>
                    </Form.Item>
                </Form> */}
                <br/>
                <Table {...tableProps} 
                    // bordered
                    size        = "small"
                    rowKey      = "id"
                >
                    {/* <Table.Column dataIndex="id" title="ID" /> */}

                    <Table.Column dataIndex="holiday_name" title="Holiday name" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="holiday_date" title="Holiday date" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="company" title="Company" 
                        render={(value:any,record:any)=>{
                            console.log(record)
                            return <>
                                <TextField value={record.company ? record.company.company_name : 'ไม่ระบุ'}  />
                            </>
                        }}
                    /> */}
 
                    <Table.Column<IEmployee>
                        width    = {50}
                        title    = "Actions"
                        align    = "center"
                        dataIndex= "actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    // disabled
                                />
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    disabled
                                ></ShowButton>
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
    );
};
