import {
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { IClock, IEmployee } from "interfaces";

export const ClockList: React.FC<IResourceComponentsProps> = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<IClock, HttpError, IClock>({
    metaData: {
      populate: ["user_profile" , "user_profile.companies"],
    },
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "user_profile].[companies].[id]",
        operator: "eq",
        value: compID,
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      // const { user_profile  } = params;
      // fillters.push({
      //   field: "user_profile.id",
      //   operator: "eq",
      //   value: "2",
      // });

      return fillters;
    },
  });

  return (
    <List
      title="Clock List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      {/* <Form {...searchFormProps} layout="inline">
        <Form.Item name="user_profile">
          <Input placeholder="Employee No" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
      </Form>
      <br /> */}

      <Table {...tableProps} rowKey={(row => row.id)} size="small">
        <Table.Column
          dataIndex="user_profile"
          title="User Profile"
          render={(value: any, record: any) => {
            return <TextField value={value ? `${value.first_name} ${value.last_name}` : "-"} />;
          }}
        />
         <Table.Column
          dataIndex={["user_profile" , "emp_no"]}
          title="User Profile"
          render={(value: any, record: any) => {
            return <TextField value={value ?? '-'} />;
          }}
        />
        <Table.Column
          dataIndex="time_track"
          title="Time"
          render={(value: any, record: any) => {
            return <TextField value={record.time_track} />;
          }}
        />
        <Table.Column
          dataIndex="date_track"
          title="Date"
          render={(value: any, record: any) => {
            return <TextField value={record.date_track} />;
          }}
        />
        <Table.Column<IClock>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton
                  disabled
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              </Space>
            )}
          />
      </Table>
    </List>
  );
};
