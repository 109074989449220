import { Show } from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";
import { IContentPost } from "interfaces";


export const ContentpostShow = () => {
  const { queryResult } = useShow<IContentPost>({
    metaData: {
      populate: "*",
    },
  });
  const { isLoading } = queryResult;
  let recordDepartment: any;
  recordDepartment = queryResult.data?.data ? queryResult.data?.data : [];
//   console.log(recordDepartment)
  return <Show title="Post Information">
    <div>Title :{recordDepartment?.post_title}</div>
    <div>Sub Title : {recordDepartment?.post_sub_title}</div>
    <div>content</div>
    <div dangerouslySetInnerHTML={{ __html: recordDepartment?.post_content }}></div>
    <div>Status : {recordDepartment?.post_status}</div>
  </Show>;
};
