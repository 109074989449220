import { Button, Card, Col, Icons, Row } from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { ReactNode } from "react";
import "./style.css";

interface ICustomCard {
  avatar?: ReactNode;
  title?: ReactNode | string;
  description: ReactNode | string;
  isEdit?: boolean;
  isEditTitle?: boolean;
  onEdit?: () => void;
}


export const CustomCard: React.FC<ICustomCard> = ({
  avatar,
  title,
  description,
  isEdit = true,
  isEditTitle = false,
  onEdit= () => ({}),
}) => {
  const { Meta } = Card;

  return (
    <Card
      className={`profile-card ${title ? "not-title" : ""} ${
        avatar ? "avatar-pro" : ""
      }`}
      bodyStyle={{ padding: 0 }}
    >
      <Meta
        className="meta-title"
        avatar={avatar}
        title={
          <div className="card-title">
            <div>{title}</div>
            {isEditTitle && (
              <Button
                type="primary"
                size="small"
                shape="circle"
                icon={<Icons.EditOutlined />}
                onClick={() => console.log("")}
              />
            )}
          </div>
        }
        description={
          <Row>
            <Col span={22}>{description}</Col>
            {isEdit && (
              <Col span={2} className="card-edit">
                <Button
                  type="primary"
                  size="small"
                  shape="circle"
                  icon={<Icons.EditOutlined />}
                  onClick={() => onEdit()}
                />
              </Col>
            )}
          </Row>
        }
      />
    </Card>
  );
};
