import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  ShowButton,
  Row,
  Col,
  Card,
  Statistic,
  Icons,
  DateField,
  CreateButton,
  Form,
  Radio,
  Avatar,
  Input,
  Divider,
  Button,
  ExportButton,
} from "@pankod/refine-antd";
import { IContentPost, IEmployee } from "interfaces";

export const ContentpostList: React.FC<IResourceComponentsProps> = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<
    IContentPost,
    HttpError,
    IContentPost
  >({
    metaData: {
      populate: [
        "*",
        "user_creator.user_profile.companies",
        "last_editor_user.user_profile.companies",
      ],
    },

    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "user_creator].[user_profile].[companies].[id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  function onStatusChange() {
    searchFormProps.form?.submit();
  }

  return (
    <>
      <List
        title="Post List"
        pageHeaderProps={{
          extra: (
            <Space>
              <CreateButton />
              {/* <ExportButton
                                onClick={triggerExport}
                                loading={exportLoading}
                            /> */}
            </Space>
            // <Row gutter={16}>
            //     <Col span={12}>
            //         <Card>
            //         <Statistic
            //             title="วันนี้"
            //             value={20}
            //             // precision={2}
            //             valueStyle={{ color: '#3f8600' }}
            //             prefix={<Icons.UserOutlined />}
            //             suffix="คน"
            //         />
            //         </Card>
            //     </Col>
            //     <Col span={12}>
            //         <Card>
            //         <Statistic
            //             title="เมื่อวาน"
            //             value={24}
            //             //precision={2}
            //             valueStyle={{ color: '#cf1322' }}
            //             prefix={<Icons.UserOutlined />}
            //             suffix="คน"
            //         />
            //         </Card>
            //     </Col>
            //     <Col >
            //         <CreateButton />
            //     </Col>
            // </Row>
          ),
        }}
      >
        {/* <Divider/> */}
        {/* <Form
                    {...searchFormProps}
                    layout = "inline"
                >
                    <Form.Item name="emo_no">
                        <Input placeholder="Employee ID"/>
                    </Form.Item>
                    <Form.Item name="first_name">
                        <Input placeholder="Name"/>
                    </Form.Item>
                    <Form.Item initialValue="true" name="emp_status" >
                        <Radio.Group onChange={onStatusChange} >
                            <Radio.Button value="true">ACTIVE</Radio.Button>
                            <Radio.Button value="false">IN-ACTIVE</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item >
                        <Button htmlType="submit" type="primary">
                            <Icons.SearchOutlined /> Search
                        </Button>
                    </Form.Item>
                </Form> */}
        <br />
        <Table
          {...tableProps}
          // bordered
          size="small"
          rowKey="id"
        >
          {/* <Table.Column dataIndex="id" title="ID" /> */}
          {/* <Table.Column align="center" width={35} dataIndex="avatar" title="Avatar" 
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <Avatar size={32} src={value.url} />
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

          <Table.Column
            dataIndex="post_title"
            title="Title"
            render={(value: any, record: any) => {
              return (
                <>
                  {/* <TextField value={record.first_name+" "+record.last_name}  /> */}
                  {/* <br/><TextField style={{fontSize:11}} value={record.position.pos_name}  /> */}
                  <TextField value={value} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="post_content"
            title="Content"
            ellipsis
            width={350}
            render={(value: any, record: any) => {
              return (
                <>
                  <div dangerouslySetInnerHTML={{ __html: value }}></div>
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="post_sub_title"
            title="Sub title"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="post_status"
            title="Post status"
            render={(value: any, record: any) => {
              // console.log(record)
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={["user_creator", "user_profile"]}
            title="Create by"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField
                    value={
                      value ? `${value.first_name} ${value.last_name}` : "-"
                    }
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={["last_editor_user", "user_profile"]}
            title="Updated by"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField
                    value={
                      value ? `${value.first_name} ${value.last_name}` : "-"
                    }
                  />
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="post_date"
            title="Sent Date"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />

          {/* <Table.Column
                        dataIndex={["category", "id"]}
                        title="Category"
                        render={(value) => {
                            if (isLoading) {
                                return <TextField value="Loading..." />;
                            }

                            return (
                                <TextField
                                    value={
                                        data?.data.find((item) => item.id === value)
                                            ?.title
                                    }
                                />
                            );
                        }}
                    /> */}
          <Table.Column<IEmployee>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  // disabled
                />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  // disabled
                ></ShowButton>
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
