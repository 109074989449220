import {
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  useForm,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IClock } from "interfaces";
import dayjs from "dayjs";
import {  useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Dayjs } from "dayjs";

export const ClockEdit = () => {
  const t = useTranslate();
  const { Option } = Select;

  const compID = localStorage.getItem("COMPANY_ID");
  const format = "HH:mm:ss";
  const [count, setCount] = useState(0);
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<string>();
  const [vUser, setUser] = useState<any>();

  const { formProps, saveButtonProps, formLoading } = useForm<IClock>({
    redirect: "list",
    metaData: {
      populate: ["user_profile", "user_profile.companies"],
    },
  });

  const handleDatePickerChange = (
    date: Dayjs | null,
    dateString: string,
    id: number
  ) => {
    setDate(dateString);
  };


  function onchangeUser(value: any) {
    setUser(value);
  }

  function handleTimePickerChange(time: any, timeString: any) {
    setTime(dayjs(timeString, "HH:mm:ss").format("HH:mm:ss.SSS"));
  }

  return (
    <Edit
      isLoading={formLoading}
      title="Edit Clock"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.time_track = time;
          values.date_track = date;
          values.user_profile = vUser;

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("clock.time")}
                  name="time_track"
                  rules={[
                    {
                      required: true,
                      message: "Please select time.",
                    },
                  ]}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value, "HH:mm:ss") : "",
                  })}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format={format}
                    onChange={(date, dateString) =>
                      handleTimePickerChange(date, dateString)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={9}>
                <Form.Item
                  label={t("clock.date")}
                  name="date_track"
                  rules={[
                    {
                      required: true,
                      message: "Please select date.",
                    },
                  ]}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : "",
                  })}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(date, dateString) =>
                      handleDatePickerChange(date, dateString, 1)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={9}>
                <Form.Item
                  label={t("clock.user-p")}
                  name={"user_profile"}
                  rules={[
                    {
                      required: true,
                      message: "Please select user.",
                    },
                  ]}
                  getValueProps={(value) => ({
                    value: value
                      ? `${value.first_name} ${value.last_name}`
                      : "-",
                  })}
                >
                  <Input readOnly/>
                  
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("clock.location")}
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter location.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("clock.clock-by")}
                  name="clock_by"
                  rules={[
                    {
                      required: true,
                      message: "Please enter clock by.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
