import {
  Button,
  ButtonProps,
  FormInstance,
  SaveButton,
  Space,
} from "@pankod/refine-antd";
import React from "react";
interface IUserAction {
  current: number;
  formLength: number;
  form: FormInstance<{}>;
  saveButtonProps: ButtonProps;
  gotoStep: (step: number) => void;
  setStateList: () => void;
}
export const ActionButtons: React.FC<IUserAction> = ({
  form,
  current,
  formLength,
  saveButtonProps,
  gotoStep,
  setStateList,
}) => {
  return (
    <>
      <Space>
        {current > 0 && (
          <>
            <Button
              onClick={() => {
                setStateList();
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          </>
        )}
        {current < formLength - 1 && (
          <SaveButton
            {...saveButtonProps}
            style={{ marginRight: 10 }}
            onClick={() => form.submit()}
          >
            Next
          </SaveButton>
        )}
     
      {current === formLength - 1 && (
        <SaveButton
          {...saveButtonProps}
          style={{ marginRight: 10 }}
          onClick={() => form.submit()}
        >
          Save
        </SaveButton>
      )}
       </Space>
    </>
    
  );
};
