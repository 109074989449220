import { Button, Upload, UploadProps } from "@pankod/refine-antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { Dispatch } from "react";
interface ICsvProps {
  setDataCsv : Dispatch<any>
}

export const CsvBaseUpload:React.FC<ICsvProps> = ({setDataCsv }) => {
  const re = /\s+|-/g; // replace spaces with dashes

  const csvFileToArray = (text:any) => {
    
    let csvHeader = text.toLowerCase().slice(0, text.indexOf("\n")).split(",");
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n").filter((fn:string) => fn != "");
    const last = csvHeader.slice(csvHeader.length-1)
    csvHeader = csvHeader.slice(0,csvHeader.length-1)
    const newHeader = [...csvHeader, last[0].split("\r")[0]]
    
    const array = csvRows.map((i:any) => {
      const values = i.split(",");
      const obj = newHeader?.reduce((object:any, header:any, index:any) => {  
   
       
        object[header.replace(re, '_')] = values[index]?.split('\"')[1] ?? values[index];
        return object;

        
      }, {});
      return obj;
    });
    setDataCsv(array)
  };

  const props: UploadProps = {
    beforeUpload(file: any) {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          if (file) {
            reader.onload = function (event) {
              const csvOutput = event.target?.result;
              csvFileToArray(csvOutput)
             
            };

            reader.readAsText(file);
          }
        };
      });
    },
    accept: ".csv"
  };
  return (
    <Upload {...props} >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};
