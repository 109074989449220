import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useLogout,
} from "@pankod/refine-core";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Tag,
} from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";

const { DownOutlined } = Icons;
const { Text } = Typography;
interface IHeaderProps {
  border?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ border = false }) => {
  const { i18n } = useTranslation();
  const { mutate: logout } = useLogout();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();
  const company = localStorage.getItem("COMPANY");
  const userType = localStorage.getItem("USER_TYPE");
  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={[currentLocale ? "en" : "th"]}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "Thai"}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
        borderBottom: `${border ? "1px solid #d7d3d3" : "none"}`,
      }}
    >
      {company ? (
        <div>
          <p style={{ marginBottom:0,fontWeight: 700 }}>{JSON.parse(company).company_name}</p>
          <p style={{ marginBottom:0,fontWeight: 700 }}>{`code: ${
            JSON.parse(company).company_code
          }`}</p>
        </div>
      ) : (
        <div></div>
      )}

      <div style={{ display: "flex" }}>
        <Space style={{ marginLeft: "8px" }}>
          {user?.avatar ? (
            <Avatar src={user?.avatar} alt={user?.name} />
          ) : (
            <Avatar size={40} icon={<Icons.UserOutlined />} />
          )}
        </Space>
        <div style={{ marginLeft: 30, textAlign: "end" }}>
          <p style={{ marginBottom:0, fontWeight: 700 }}>{user?.username}</p>
          <p style={{ marginBottom:0,fontWeight: 700, color: "rgb(0 0 0 / 25%)" }}>
            {userType}
          </p>
        </div>
        <Space style={{ marginLeft: 20 }}>
          <Button
            onClick={() => logout()}
            className="btn-logout"
            icon={<Icons.LogoutOutlined style={{ fontSize: 20 }} />}
          />
        </Space>
        <Dropdown overlay={menu}>
          <Button type="link">
            <Space>
              {" "}
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
    </AntdLayout.Header>
  );
};
