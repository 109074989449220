import { Avatar, List, Icons, Row, Col, Button } from "@pankod/refine-antd";
import { BaseCard } from "components/common/card";

export const DashboardEmployee: React.FC = () => {
  return (
    <List
      title={
        <div style={{ display: "flex" }}>
          <Avatar shape="square" size={64} icon={<Icons.UserOutlined />} />
          <div>
            <h3 style={{ marginBottom: 0 }}>Welcome, John Doe</h3>
            <h4>Monday, 20 May 2019</h4>
          </div>
        </div>
      }
      // pageHeaderProps={{
      //   extra: (
      //     <Space>
      //       <CreateButton />
      //       <CsvBaseUpload setDataCsv={setDataCsv} />
      //       <ExportButton onClick={triggerExport} loading={exportLoading} />
      //     </Space>
      //   ),
      // }}
    >
      <Row gutter={[26, 16]}>
        <Col span={18}>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              <h3>TODAY</h3>
            </Col>
            <Col span={24}>
              <BaseCard size="small">Richard Miles is off sick today</BaseCard>
            </Col>
            <Col span={24}>
              <BaseCard size="small">You are away today</BaseCard>
            </Col>
            <Col span={24}>
              <BaseCard size="small">You are working from home today</BaseCard>
            </Col>
            <Col span={24}>
              <h3>TOMORROW</h3>
            </Col>
            <Col span={24}>
              <BaseCard size="small">
                <p>2 people will be away tomorrow</p>
              </BaseCard>
            </Col>
            <Col span={24}>
              <h3>NEXT SEVEN DAYS</h3>
            </Col>
            <Col span={24}>
              <BaseCard size="small">2 people are going to be away</BaseCard>
            </Col>
            <Col span={24}>
              <BaseCard size="small">
                Your first day is going to be on Thursday
              </BaseCard>
            </Col>
            <Col span={24}>
              <BaseCard size="small">
                It's Spring Bank Holiday on Monday
              </BaseCard>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h4 style={{ marginBottom: 0 }}>PROJECTS</h4>
            </Col>
            <Col span={24}>
              <BaseCard size="small">
                <Row gutter={[16, 8]}>
                  <Col span={12}>
                    <div>
                      <p style={{ textAlign: "center" }}>71</p>
                      <p style={{ fontSize: 12, textAlign: "center" }}>TOTAL TASKS</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <p style={{ textAlign: "center" }}>14</p>
                      <p style={{ fontSize: 12 , textAlign: "center"}}>PENDING TASKS</p>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <p style={{ textAlign: "center" }}>2</p>
                      <p style={{ fontSize: 12, textAlign: "center" }}>
                        TOTAL PROJECTS
                      </p>
                    </div>
                  </Col>
                </Row>
              </BaseCard>
            </Col>
            <Col span={24}>
              <h4 style={{ marginBottom: 0 }}>YOUR LEAVE</h4>
            </Col>
            <Col span={24}>
              <BaseCard size="small">
                <Row gutter={[16, 8]}>
                  <Col span={12}>
                    <div>
                      <p>4.5</p>
                      <p style={{ fontSize: 12 }}>LEAVE TAKEN</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <p>4.5</p>
                      <p style={{ fontSize: 12 }}>LEAVE TAKEN</p>
                    </div>
                  </Col>
                  <Col span={24} style={{display:"flex", justifyContent:"center"}}>
                    <Button type="primary">Apply Leave</Button>
                  </Col>
                </Row>
              </BaseCard>
            </Col>
            <Col span={24}>
              <h4 style={{ marginBottom: 0 }}>YOUR TIME OFF ALLOWANCE</h4>
            </Col>
            <Col span={24}>
              <BaseCard size="small"><Row gutter={[16, 8]}>
                  <Col span={12}>
                    <div>
                      <p>5.0 Hours</p>
                      <p style={{ fontSize: 12 }}>APPROVED</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <p>15 Hours</p>
                      <p style={{ fontSize: 12 }}>REMAINING</p>
                    </div>
                  </Col>
                  <Col span={24} style={{display:"flex", justifyContent:"center"}}>
                    <Button type="primary">Apply Time Off</Button>
                  </Col>
                </Row></BaseCard>
            </Col>
            <Col span={24}>
              <h4 style={{ marginBottom: 0 }}>UPCOMING HOLIDAY</h4>
            </Col>
            <Col span={24}>
              <BaseCard size="small">Mon 20 May 2019 - Ramzan</BaseCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </List>
  );
};
