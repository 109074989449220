import {
  Checkbox,
  Col,
  Create,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Upload,
  UploadProps,
  message,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ILeave, ILeaveType } from "interfaces";
import { useState } from "react";
import dayjs from "dayjs";
import { axiosInstance } from "authProvider";
import { TOKEN_KEY, API_URL } from "../../constants";

export const LeaveCreate: React.FC = () => {
  const t = useTranslate();
  const compId = localStorage.getItem("COMPANY_ID");
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;

  const pid = localStorage.getItem("PID");
  const token = localStorage.getItem(TOKEN_KEY);
  const format = "YYYY-MM-DD";

  const [isFileUpload, setIsFileUpload] = useState(false);
  const [dates, setDates] = useState<any>();
  const [dateLeave, setDateLeave] = useState<string | [string, string]>([
    dayjs(new Date()).format(format),
    dayjs(new Date()).format(format),
  ]);

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { formProps, saveButtonProps } = useForm<ILeave>({
    redirect: "list",
  });

  const { selectProps: leavProps } = useSelect<ILeaveType>({
    resource: "leave-types",
    optionLabel: "leave_type_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const onChangeDateRange = (
    value: any,
    dateString: [string, string] | string
  ) => {
    setDateLeave(dateString);
  };

  const checkLeave = async (leave_type: string, user_profile: string) => {
    try {
      const { data: response, status } = await axiosInstance.get<any>(
        API_URL +
          `/api/leave/check?filters[company][id][$eq]=${compId}&filters[leave_type][id][$eq]=${leave_type}&filters[user_profile][id][$eq]=${user_profile}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }

    const tooLate =
      dates[0] &&
      current.diff(dates[0], "days") >
        new Date(
          new Date(dates[0]).getFullYear(),
          new Date(dates[0]).getMonth() + 1,
          0
        ).getDate() -
          new Date(dates[0]).getDate();

    return !!tooLate;
  };

  return (
    <Create title="Create Leave" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{
          leave_option: "full",
        }}
        layout="vertical"
        onFinish={async (values: any) => {
          
          // values.start_date = dateLeave[0];
          // values.end_date = dateLeave[1];
          // values.user_creator = pid;
          // values.user_profile = pid;
          // values.company = compId;
          // values.attach_file = values.attach_file
          //   ? values.attach_file.file.response[0].id
          //   : null;

          // const chk = await checkLeave(values.leave_type, values.user_profile);
          
          // if (chk) {

          //   return formProps.onFinish?.({
          //     ...mediaUploadMapper(values),
          //   });

          // }
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("leave.type")}
                  name="leave_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select leave type.",
                    },
                  ]}
                >
                  <Select
                    {...leavProps}
                    onChange={(val: any) => {
                      if (val === 1) {
                        setIsFileUpload(true);
                      } else {
                        setIsFileUpload(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col className="gutter-row" span={11}>
            <Row gutter={16}>
              <Col className="gutter-row" span={20}>
                <Form.Item
                  label="date"
                  name="date"
                  getValueProps={(value) => ({
                    value: value
                      ? [dayjs(value[0]), dayjs(value[1])]
                      : [dayjs(dateLeave[0]), dayjs(dateLeave[1])],
                  })}
                >
                  <RangePicker
                    onCalendarChange={(val) => setDates(val)}
                    disabledDate={disabledDate}
                    allowClear={false}
                    onChange={onChangeDateRange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            className="gutter-row"
            style={{ display: "flex", alignItems: "end" }}
            span={3}
          >
            <Form.Item
              label=""
              name="leave_option"
              // getValueProps={(value) => ({
              //   defaultChecked: value,
              // })}
            >
              {/* <Checkbox onChange={val => setData({isLeaveFull : val.target.checked})}>ลาเต็มวัน</Checkbox> */}
              <Radio.Group>
                <Radio value={"full"}>ลาเต็มวัน</Radio>
                <Radio value={"half_morning"}>ลาเช้า</Radio>
                <Radio value={"half_afternoon"}>ลาบ่าย</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("leave.reason")}
              name="leave_detail"
              rules={[
                {
                  required: true,
                  message: "Please enter leave reason.",
                },
              ]}
            >
              <TextArea
                rows={3}
                style={{
                  height: "90px",
                  maxHeight: "90px",
                  overflow: "hidden",
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {isFileUpload && (
              <Form.Item
                name="attach_file"
                label="Attach File"
                valuePropName="fileList"
                rules={[
                  {
                    required: isFileUpload,
                    message: "Please select file.",
                  },
                ]}
                getValueProps={(data) => getValueProps(data, "")}
              >
                <Upload.Dragger {...props}>
                  <p className="ant-upload-text">
                    Drag & drop a file in this area, File size 5 MB, File type
                    .png, .jpeg, .jpg
                  </p>
                </Upload.Dragger>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Create>
  );
};