import { Checkbox, Col, Row, Show, Space } from "@pankod/refine-antd";
import { RoleMasters, RoleManagements } from "components/page/role";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState} from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  HttpError,
  useCreateMany,
  useList,
  useUpdate,
} from "@pankod/refine-core";
import { IModuleAccess, IModuleMaster, IRoleManagement } from "interfaces";

export const RolePermissions: React.FC = () => {
  const columns: ColumnsType<IModuleAccess> = [
    {
      title: "Module Permission",
      dataIndex: "permission",
      key: "permission",
      render: (_, record) => <>{record.module_master?.module_name}</>,
    },
    {
      title: "Create",
      key: "act_create",
      dataIndex: "act_create",
      align: "center",
      render: (_, record) => (
        <>
          <Checkbox
            checked={record.act_create}
            onChange={(e) => onChangePermissions(record, "act_create", e)}
          />
        </>
      ),
    },
    {
      title: "Delete",
      key: "act_delete",
      dataIndex: "act_delete",
      align: "center",
      render: (_, record) => (
        <>
          <Checkbox
            checked={record.act_delete}
            onChange={(e) => onChangePermissions(record, "act_delete", e)}
          />
        </>
      ),
    },
    {
      title: "Update",
      key: "act_update",
      dataIndex: "act_update",
      align: "center",
      render: (_, record) => (
        <>
          <Checkbox
            checked={record.act_update}
            onChange={(e) => onChangePermissions(record, "act_update", e)}
          />
        </>
      ),
    },
    {
      title: "Import",
      key: "act_import",
      dataIndex: "act_import",
      align: "center",
      render: (_, record) => (
        <>
          <Checkbox
            checked={record.act_import}
            onChange={(e) => onChangePermissions(record, "act_import", e)}
          />
        </>
      ),
    },
    {
      title: "Export",
      key: "act_export",
      dataIndex: "act_export",
      align: "center",
      render: (_, record) => (
        <>
          <Checkbox
            checked={record.act_export}
            onChange={(e) => onChangePermissions(record, "act_export", e)}
          />
        </>
      ),
    },
  ];
  const userId = localStorage.getItem("COMPANY_ID");
  let roles: IRoleManagement[] = [];
  let moduleAccess: IModuleAccess[] = [];
  let moduleMaster: IModuleMaster[] = [];
  const [roleId, setRoleId] = useState<string>("0");
  const [moduleAccState, setModuleAccState] = useState<IModuleAccess[]>([]);

  const { mutate: updateModule } = useUpdate<IModuleAccess>();
  const { mutate: creatModule } = useCreateMany<IModuleAccess>();

  const roleRes = useList<IRoleManagement, HttpError>({
    resource: "role-managements",
    metaData: {
      populate: ["company"],
    },
    config: {
      sort: [{ order: "asc", field: "id" }],
      filters: [{ field: "company].[id]", operator: "eq", value: userId }],
    },
  });

  const moduleAccessRes = useList<IModuleAccess>({
    resource: "module-accesses",
    metaData: {
      populate: ["role", "role.role_master", "module_master"],
    },
    config: {
      sort: [{ order: "asc", field: "module_master.module_code" }],
      filters: [{ field: "role].[id]", operator: "eq", value: roleId }],
    },
  });

  const moduleMasterRes = useList<IModuleMaster>({
    resource: "module-masters",
    config: {
      sort: [{ order: "asc", field: "module_code" }],
    },
  });

  const { data: roleData } = roleRes;
  roles = roleData?.data ? roleData.data : [];

  const { data: moduleAccessData, isFetching } = moduleAccessRes;
  moduleAccess = moduleAccessData?.data ? moduleAccessData?.data : [];

  const { data: moduleMasterData } = moduleMasterRes;
  moduleMaster = moduleMasterData?.data ? moduleMasterData?.data : [];

  useEffect(() => {
    if (roles.length !== 0) setRoleId(roles[0]?.id);
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (moduleAccess !== undefined && moduleAccess.length !== 0) {
      setModuleAccState(
        moduleAccess.map((val) => ({
          ...val,
          isCreat: false,
        }))
      );
    } else {
      setModuleAccState(
        moduleMaster.map((val, idx) => ({
          id: idx.toString(),
          act_create: false,
          act_delete: false,
          act_export: false,
          act_import: false,
          act_update: false,
          use_workflow: false,
          active: false,
          isCreat: true,
          module_master: val,
          role: roles.find(
            (val: IRoleManagement) => val.id.toString() === roleId
          ),
        }))
      );
    }
  }, [moduleAccess]);

  const selectRole = (val: string) => {
    setRoleId(val);
  };
  const onChangePermissions = (
    record: any,
    key: string,
    e: CheckboxChangeEvent
  ) => {
    const stateCheck = moduleAccState.map((obj) => {
      if (obj.id === record.id) {
        return { ...obj, [key]: e.target.checked };
      }
      return obj;
    });
    setModuleAccState(stateCheck);

    const result = stateCheck.filter((f: IModuleAccess) => f.id === record.id);
    if (record.isCreat) {
      const listCreat = stateCheck.map((val) => ({
        module_master: val.module_master?.id,
        active: val.active,
        role: val.role?.id,
        act_create: val.act_create,
        act_update: val.act_update,
        act_delete: val.act_delete,
        act_import: val.act_export,
        act_export: val.act_export,
        use_workflow: val.use_workflow,
      }));
      creatModule({
        resource: "module-accesses",
        values: listCreat,
        successNotification: false,
      });
    } else {
      const listCreat = result.map((val) => ({
        module_master: val.module_master?.id,
        active: val.active,
        role: val.role?.id,
        act_create: val.act_create,
        act_update: val.act_update,
        act_delete: val.act_delete,
        act_import: val.act_export,
        act_export: val.act_export,
        use_workflow: val.use_workflow,
      }));
      updateModule({
        resource: "module-accesses",
        id: record.id,
        values: listCreat[0],
        successNotification: false,
      });
    }
  };
  
  const onChangeModule = (record: any) => {
    const stateCheck = moduleAccState.map((obj) => {
      if (obj.id === record.id) {
        return { ...obj, active: !obj.active };
      }
      return obj;
    });
    setModuleAccState(stateCheck);
    const result = stateCheck.filter((f: IModuleAccess) => f.id === record.id);

    if (record.isCreat) {
      const listCreat = stateCheck.map((val) => ({
        module_master: val.module_master?.id,
        active: val.active,
        role: val.role?.id,
        act_create: val.act_create,
        act_update: val.act_update,
        act_delete: val.act_delete,
        act_import: val.act_export,
        act_export: val.act_export,
        use_workflow: val.use_workflow,
      }));
      creatModule({
        resource: "module-accesses",
        values: listCreat,
        successNotification: false,
      });
    } else {
      updateModule({
        resource: "module-accesses",
        id: record.id,
        values: {
          active: result[0].active,
        },
        successNotification: false,
      });
    }
  };

  return (
    <Show
      isLoading={isFetching}
      title="Roles & Permissions"
      contentProps={{
        className: "wrapper-body",
      }}
      headerButtons={<Space />}
    >
      <Row gutter={[26, 16]}>
        <Col span={6}>
          <RoleMasters
            data={roles}
            onSelectRole={(val: string) => selectRole(val)}
          />
        </Col>
        <Col span={18}>
          <RoleManagements
            columns={columns}
            dataAccess={moduleAccState}
            onChange={(val: any) => onChangeModule(val)}
          />
        </Col>
      </Row>
    </Show>
  );
};
