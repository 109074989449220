import {
  IBranch,
  ICompany,
  IContentPost,
  IContentPostType,
  IDepartment,
  IPosition,
} from "interfaces";
import { useApiUrl } from "@pankod/refine-core";
import dayjs, { Dayjs } from "dayjs";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Row,
  Col,
  Button,
  Icons,
  UploadProps,
  message,
  Upload,
  UploadFile,
  Checkbox,
} from "@pankod/refine-antd";
// import { TOKEN_KEY } from "../../constants";
import { DatePicker } from "antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { useState } from "react";
import type { RangePickerProps } from "antd/es/date-picker";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, API_URL } from "../../constants";

export const ContentpostCreate = () => {
  const { TextArea } = Input;

  const compId = localStorage.getItem("COMPANY_ID");
  const usrId = localStorage.getItem("USER_ID");
  const [dates, setDates] = useState<any>();

  const { formProps, saveButtonProps } = useForm<IContentPost>({
    redirect: "list",
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { selectProps: postTypeProps } = useSelect<IContentPostType>({
    resource: "content-post-types",
    optionLabel: "content_type_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: branchesProps } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "branch_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: departmentProps } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: positionProps } = useSelect<IPosition>({
    resource: "positions",
    optionLabel: "pos_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  //   const { selectProps: companyProps } = useSelect<ICompany>({
  //     resource: "companies",
  //     optionLabel: "company_name",
  //   });

  //   const { ...uploadProps } = useStrapiUpload({
  //     maxCount: 1,
  //   });

  const [vPosttype, setPosttype] = useState<any>([]);
  const [vComp, setComp] = useState<any>();

  function onChangePosttype(va: any) {
    vPosttype.pop();
    vPosttype.push(va);
    setPosttype(vPosttype);
  }

  const UpLoadImge = (prop: any) => {
    const { fileList, onChange } = prop;
    const fileLists: UploadFile[] =
      fileList && !fileList.file
        ? [
            {
              uid: fileList.id.toString(),
              name: fileList.name,
              status: "done",
              url: fileList.url,
            },
          ]
        : [];

    return (
      <Upload.Dragger
        {...props}
        onChange={onChange}
        defaultFileList={
          !fileList ? undefined : fileList.file ? undefined : fileLists
        }
      >
        <p className="ant-upload-text">
          Drag & drop a file in this area, File size 5 MB, File type .png,
          .jpeg, .jpg
        </p>
      </Upload.Dragger>
    );
  };

  const selectAll = (e: any, value: string, options: any) =>
    e.target.checked
      ? formProps.form?.setFieldsValue({
          [value]: options.map((val: any) => val.value),
        })
      : formProps.form?.setFieldsValue({ [value]: [] });

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) =>
    dayjs().add(-1, "days") >= current || dayjs().add(1, "month") <= current;

  return (
    <Create
      title="Create Post"
      footerButtons={
        <Button
          icon={<Icons.SendOutlined />}
          {...saveButtonProps}
          type="primary"
        >
          Send
        </Button>
      }
    >
      <Form
        {...formProps}
        initialValues={{
          post_status: "ACTIVE",
          // branch : [59, 68]
        }}
        layout="vertical"
        onFinish={(values: any) => {
          values.company = compId;
          values.user_creator = usrId;
          values.post_date = dayjs(new Date()).format("YYYY-MM-DD");
          values.post_images = values.post_images
            ? values.post_images.file?.response[0].id ?? values.attach_file.id
            : null;

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Post type"
              name="content_post_types"
              rules={[
                {
                  required: true,
                  message: "Please select post type.",
                },
              ]}
            >
              <Select
                {...postTypeProps}
                mode="multiple"
                onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Branch"
              name="branches"
              rules={[
                {
                  required: true,
                  message: "Please select branch.",
                },
              ]}
            >
              <Select
                {...branchesProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item label="All" name="all_branch">
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "branches", branchesProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Department"
              name="departments"
              rules={[
                {
                  required: true,
                  message: "Please select department.",
                },
              ]}
            >
              <Select
                {...departmentProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item label="All" name="all_department">
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "departments", departmentProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Position"
              name="positions"
              rules={[
                {
                  required: true,
                  message: "Please select position.",
                },
              ]}
            >
              <Select
                {...positionProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item label="All" name="all_position">
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "positions", positionProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Post status" name="post_status">
              <Select
                options={[
                  {
                    label: "ACTIVE",
                    value: "ACTIVE",
                  },
                  {
                    label: "INACTIVE",
                    value: "INACTIVE",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Send message date"
              name="send_msg_date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Send message date!",
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item
              label="Title"
              name="post_title"
              rules={[
                {
                  required: true,
                  message: "Please enter title.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Sub title"
              name="post_sub_title"
              rules={[
                {
                  required: true,
                  message: "Please enter sub title.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item
              label="Content"
              name="post_content"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
              {/* <CKEditor
              // name="detail"
              editor={ClassicEditor}
              //data={recordData.detail}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "ckfinder",
                  "|",
                  "imageTextAlternative",
                  "imageUpload",
                  "imageStyle:full",
                  "imageStyle:side",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              onInit={(editor: any) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
                console.log(
                  "toolbar: ",
                  Array.from(editor.ui.componentFactory.names())
                );
                console.log(
                  "plugins: ",
                  ClassicEditor.builtinPlugins.map(
                    (plugin: any) => plugin.pluginName
                  )
                );
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setContent(data);
                // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
              }}
              onBlur={(editor: any) => {
                console.log("Blur.", editor);
              }}
              onFocus={(editor: any) => {
                console.log("Focus.", editor);
              }}
            /> */}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              name="post_images"
              label="Images"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select file.",
              //   },
              // ]}
              valuePropName="fileList"
            >
              <UpLoadImge />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
