import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,

  //LoginPage,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { useTranslation } from "react-i18next";
import "styles/antd.less";
import "styles/App.css";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import { LoginPage } from "pages/login";

import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
  EmployeeShow,
  EmployeeShow1,
} from "pages/employee";
// import {
//   CompanyCreate,
//   CompanyEdit,
//   CompanyList,
//   CompanyShow,
// } from "pages/company";
import {
  BranchCreate,
  BranchEdit,
  BranchesList,
  BranchShow,
} from "pages/branches";
import {
  DepartmentList,
  DepartmentCreate,
  DepartmentEdit,
  DepartmentShow,
} from "pages/department";
import {
  PositionList,
  PositionCreate,
  PositionEdit,
  PositionShow,
} from "pages/position";
import {
  ContentpostCreate,
  ContentpostEdit,
  ContentpostList,
  ContentpostShow,
} from "pages/contentpost";
import { HolidayCreate, HolidayEdit, HolidayList } from "pages/holiday";
import { BankList, BankCeate, BankEdit } from "pages/bank";
import { ClockList, ClockCreate, ClockEdit } from "pages/clock";
import { PackageList, PackageCreate, PackageEdit } from "pages/package";
import { Company2List, CreateCompany, EditCompany } from "pages/company_list";
import { LeaveList, LeaveCreate, LeaveEdit } from "pages/leave";
import {
  LeaveTypeList,
  LeaveTypeCreate,
  LeaveTypeEdit,
} from "pages/leave_type";
import { Register } from "pages/register";
import { AttendancAdmin, AttendancUser } from "pages/attendance";
import { RolePermissions } from "pages/role";
import { DashboardPage } from "pages/dashboard";
import { MessageList } from "pages/message";
import { CreateShift, ShiftList, EditShift } from "pages/shift";
import { CreatePlan, ListPlan } from "pages/plan";
import { ListLeaveUser } from "pages/leave_users/list";
import {
  LeaveAdminCreate,
  LeaveAdminEdit,
  LeaveListAdmin,
} from "pages/leave_admin";
import {
  ContentPostTypeCreate,
  ContentPostTypeEdit,
  ContentPostTypeList,
} from "pages/content_post_types";
import LeaveManagementList from "pages/leave_management/list";
import LeaveManagementCreate from "pages/leave_management/create";
import LeaveManagementEdit from "pages/leave_management/edit";

function App() {
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <RefineKbarProvider>
      <Refine
        notificationProvider={notificationProvider}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Company2List />,
              path: "/companyselect",
            },
            {
              element: <CreateCompany />,
              path: "/companyselect/create",
            },
            {
              element: <EditCompany />,
              path: "/companyselect/edit/:id",
            },
            {
              element: <Register />,
              path: "/register/create",
            },
          ],
        }}
        authProvider={authProvider}
        dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
        LoginPage={LoginPage}
        DashboardPage={DashboardPage}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        i18nProvider={i18nProvider}
        accessControlProvider={{
          can: async ({ resource, action, params }) => {
            if (localStorage.getItem("USER_TYPE") === "Admin") {
              if (
                resource === "user-profiles" ||
                resource === "attendance_list" ||
                resource === "branches" ||
                resource === "departments" ||
                resource === "dashboard" ||
                resource === "content-posts" ||
                resource === "companyselect" ||
                resource === "positions" ||
                resource === "shift-managements" ||
                resource === "plan-managements" ||
                resource === "role-managements" ||
                resource === "leaves-admin" ||
                resource === "leaves-user" ||
                resource === "holidays" ||
                resource === "leave-types" ||
                resource === "content-post-types" ||
                resource === "leave-managements"
              ) {
                return Promise.resolve({
                  can: true,
                });
              } else {
                return Promise.resolve({
                  can: false,
                  reason: "Unauthorized",
                });
              }
            } else {
              if (
                resource === "attendances" ||
                resource === "leaves" ||
                resource === "dashboard" ||
                (resource === "user-profiles" && action === "show")
              ) {
                return Promise.resolve({
                  can: true,
                });
              } else {
                return Promise.resolve({
                  can: false,
                  reason: "Unauthorized",
                });
              }
            }
          },
        }}
        resources={[
          {
            name: "emp",
            options: {
              label: "Employees",
            },
          },
          {
            name: "user-profiles",
            parentName: "emp",
            list: EmployeeList,
            create: EmployeeCreate,
            edit: EmployeeEdit,
            show: EmployeeShow1,
            options: {
              label: "All Employees",
            },
          },
          {
            name: "attendances",
            parentName: "emp",
            list: AttendancUser,
            options: {
              label: "Attendance(Employee)",
            },
          },
          {
            name: "attendance_list",
            parentName: "emp",
            list: AttendancAdmin,
            options: {
              label: "Attendance(Admin)",
            },
          },
          {
            name: "plan-managements",
            parentName: "emp",
            list: ListPlan,
            create: CreatePlan,
            options: {
              label: "Plan Management",
            },
          },
          {
            name: "setting",
            options: {
              label: "Settings",
            },
          },
          // {
          //   name        : 'companies',
          //   parentName  : 'setting',
          //   list        : CompanyList,
          //   show        : CompanyShow,
          //   edit        : CompanyEdit,
          //   create      : CompanyCreate,
          //   //list    : CheckInList,
          //   options : {
          //     label : "Company"
          //   }
          // },
          {
            name: "branches",
            parentName: "setting",
            list: BranchesList,
            show: BranchShow,
            edit: BranchEdit,
            create: BranchCreate,
            //list    : CheckInList,
            options: {
              label: "Branch",
            },
          },
          {
            name: "departments",
            parentName: "setting",
            list: DepartmentList,
            show: DepartmentShow,
            edit: DepartmentEdit,
            create: DepartmentCreate,
            options: {
              label: "Department",
            },
          },
          {
            name: "holidays",
            parentName: "setting",
            list: HolidayList,
            // show        : BranchShow,
            edit: HolidayEdit,
            create: HolidayCreate,
            options: {
              label: "Holiday",
            },
          },
          {
            name: "positions",
            parentName: "setting",
            list: PositionList,
            show: PositionShow,
            edit: PositionEdit,
            create: PositionCreate,
            options: {
              label: "Position",
            },
          },
          {
            name: "banks",
            parentName: "setting",
            list: BankList,
            edit: BankEdit,
            create: BankCeate,
            options: {
              label: "Bank",
            },
          },
          {
            name: "clocks",
            parentName: "setting",
            list: ClockList,
            edit: ClockEdit,
            create: ClockCreate,
            options: {
              label: "Clock",
            },
          },
          {
            name: "packages",
            parentName: "setting",
            list: PackageList,
            edit: PackageEdit,
            create: PackageCreate,
            options: {
              label: "Package",
            },
          },
          {
            name: "content-post-types",
            parentName: "setting",
            list: ContentPostTypeList,
            create: ContentPostTypeCreate,
            edit: ContentPostTypeEdit,
            options: {
              label: "Content Post Types",
            },
          },
          {
            name: "leaves",
            parentName: "emp",
            list: LeaveList,
            // show        : PositionShow,
            edit: LeaveEdit,
            create: LeaveCreate,
            options: {
              label: "Leave",
            },
          },
          {
            name: "leaves-admin",
            parentName: "emp",
            // show        : PositionShow,
            list: LeaveListAdmin,
            create: LeaveAdminCreate,
            edit: LeaveAdminEdit,
            options: {
              label: "Leave (Admin)",
            },
          },
          {
            name: "leaves-user",
            parentName: "emp",
            list: ListLeaveUser,

            options: {
              label: "Leave User",
            },
          },
          {
            name: "leave-types",
            parentName: "setting",
            list: LeaveTypeList,
            // show        : PositionShow,
            edit: LeaveTypeEdit,
            create: LeaveTypeCreate,
            options: {
              label: "Leave Type",
            },
          },
          {
            name: "leave-managements",
            parentName: "setting",
            list: LeaveManagementList,
            // show        : PositionShow,
            edit: LeaveManagementEdit,
            create: LeaveManagementCreate,
            options: {
              label: "Leave Management",
            },
          },
          {
            name: "shift-managements",
            parentName: "setting",
            list: ShiftList,
            create: CreateShift,
            edit: EditShift,
            options: {
              label: "Shift Management ",
            },
          },
          {
            name: "news",
            options: {
              label: "New",
            },
          },
          {
            name: "content-posts",
            parentName: "news",
            list: ContentpostList,
            show: ContentpostShow,
            edit: ContentpostEdit,
            create: ContentpostCreate,
            //list    : CheckInList,
            options: {
              label: "News & Announcement",
            },
          },
          {
            name: "role-managements",
            parentName: "setting",
            list: RolePermissions,
            options: {
              label: "Role & Permissions",
            },
          },
        ]}
      />
    </RefineKbarProvider>
  );
}

export default App;
