import {
  Col,
  Edit,
  Form,
  InputNumber,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ILeaveManagement, ILeaveType } from "interfaces";
import React from "react";

type Props = {};

const LeaveManagementEdit = (props: Props) => {
  const compId = localStorage.getItem("COMPANY_ID");

  const { formProps, saveButtonProps, formLoading } = useForm<ILeaveManagement>(
    {
      redirect: "list",
      metaData: {
        populate: ["leave_type", "company"],
      },
    }
  );

  const { selectProps: leavProps } = useSelect<ILeaveType>({
    resource: "leave-types",
    fetchSize: 100,
    optionLabel: "leave_type_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  return (
    <Edit
      title="Edit Leave Management"
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        // initialValues={{
        //   employee_type: "full time",
        //   longevity_month: "0",
        //   longevity_year: "0",
        //   receive_leave: "0",
        // }}
        layout="horizontal"
        onFinish={(values: any) => {
          values.company = compId;

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Leave Type"
              name={["leave_type", "id"]}
              rules={[
                {
                  required: true,
                  message: "Please select leave type.",
                },
              ]}
            >
              <Select {...leavProps} disabled/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Employee Type"
              name="employee_type"
              rules={[
                {
                  required: true,
                  message: "Please select employee type.",
                },
              ]}
            >
              <Select
                options={[
                  { value: "full time", label: "full time" },
                  { value: "part time", label: "part time" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label="Longevity" name={"longevity_year"}>
              <InputNumber addonAfter="year" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label="" name={"longevity_month"}>
              <InputNumber addonAfter="month" style={{ width: "70%" }} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Receive Leave" name="receive_leave">
              <InputNumber addonAfter="Days" style={{ width: "40%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

export default LeaveManagementEdit;
