import {
  Button,
  Checkbox,
  Col,
  ExportButton,
  Form,
  Icons,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  useDelete,
  useExport,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import { IBranch, ICompany, IDepartment, IEmployee, IPlan } from "interfaces";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/es/table";

type OptionType = {
  value: string;
  label: string;
};

const dt: any = new Date();
const year: OptionType[] = [];

for (let i = 2000; i <= dt.getFullYear(); i++) {
  year.push({
    value: i.toString(),
    label: i.toString(),
  });
}

const initialFilter = [
  `${dt.getFullYear()}-${dt.getMonth() + 1}-01`,
  `${dt.getFullYear()}-${dt.getMonth() + 1}-${new Date(
    dt.getFullYear(),
    dt.getMonth() + 1,
    0
  ).getDate()}`,
];

const month: OptionType[] = [
  {
    value: "01",
    label: "January",
  },
  {
    value: "02",
    label: "February",
  },
  {
    value: "03",
    label: "March",
  },
  {
    value: "04",
    label: "April",
  },
  {
    value: "05",
    label: "May",
  },
  {
    value: "06",
    label: "June",
  },
  {
    value: "07",
    label: "July",
  },
  {
    value: "08",
    label: "August",
  },
  {
    value: "09",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];

type IFormPlan = {
  company: number;
  year: string;
  department: number;
  month: string;
};

export const ListPlan: React.FC = () => {
  const comId: any = localStorage.getItem("COMPANY_ID");
  const { push } = useNavigation();
  const [dataColum, setdataColum] = useState<ColumnsType<any>>([]);

  const { mutate: update } = useUpdate<IPlan, HttpError, {}>();
  const { mutate: deletePlan } = useDelete();

  const { selectProps: companyProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: "company_name",
  });

  const { selectProps: department } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: comId,
      },
    ],
  });

  const { selectProps: branch } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "branch_name",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: comId,
      },
    ],
  });

  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
  } = useTable<IEmployee, HttpError, IFormPlan>({
    resource: "user-profile/plan",
    metaData: {
      populate: [
        "companies",
        "plan_managements",
        "plan_managements.shift_management",
        "department",
        "branches",
      ],
    },
    initialFilter: [
      {
        field: "companies].[id]",
        operator: "eq",
        value: comId,
      },
      // {
      //   field: "department].[id]",
      //   operator: "eq",
      //   value: JSON.parse(depart).department,
      // },
      {
        field: "plan_managements].[plan_date]",
        operator: "between",
        value: initialFilter,
      },
    ],
    onSearch: (params: IFormPlan) => {
      const filters: CrudFilters = [];
      const { month, year, department } = params;
      const numDay = new Date(parseInt(year), parseInt(month), 0).getDate();
      const dateSearch = [
        dayjs(new Date(parseInt(year), parseInt(month) - 1, 1)).format(
          "YYYY-MM-DD"
        ),
        dayjs(new Date(parseInt(year), parseInt(month) - 1, numDay)).format(
          "YYYY-MM-DD"
        ),
      ];

      filters.push(
        {
          field: "plan_managements].[plan_date]",
          operator: "between",
          value: dateSearch,
        },
        {
          field: "department].[id]",
          operator: "eq",
          value: department,
        }
      );
      setColum(new Date(parseInt(year), parseInt(month), 0).getDate());
      return filters;
    },
  });

  const { selectProps: selectShift } = useSelect<IDepartment>({
    resource: "shift-managements",
    optionLabel: "shift_code",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: comId,
      },
    ],
  });

  const {
    modalProps,
    formProps,
    show: editModalShow,
    close,
    id,
  } = useModalForm<IPlan>({
    resource: "plan-managements",
    action: "edit",
    metaData: {
      populate: "*",
    },
  });

  useEffect(() => {
    setColum(new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate());
  }, []);

  const setColum = (numDate: number) => {
    const columns: ColumnsType<any> = [
      {
        title: "Profile",
        dataIndex: "profile_name",
        key: "profile_name",
        fixed: "left",
        ellipsis: true,
        width: 175,
        render: (text) => <TextField value={text} />,
      },
    ];

    for (let index = 0; index < numDate; index++) {
      columns.push({
        title: `${index + 1}`,
        dataIndex: `${index + 1}`,
        key: `${index + 1}`,
        width: 75,
        align: "center",
        render: (_, record) => {
          return (
            <>
              {record[index + 1]?.shift_management?.color && (
                <Tag
                  style={{ cursor: "pointer" }}
                  color={record[index + 1].shift_management.color}
                  // onClick={() => editModalShow(record[index + 1].id)}
                >
                  <Popconfirm
                    title=""
                    cancelText="Delete"
                    okText="View"
                    icon={<></>}
                    onCancel={() => {
                      deletePlan(
                        {
                          resource: "plan-managements",
                          id: record[index + 1].id,
                        },
                        {
                          onSuccess() {
                            refetch();
                          },
                        }
                      );
                    }}
                    onConfirm={() => editModalShow(record[index + 1].id)}
                  >
                    <TextField
                      value={record[index + 1].shift_management.shift_code}
                    />
                  </Popconfirm>
                </Tag>
              )}
            </>
          );
        },
      });
    }
    setdataColum(columns);
  };

  const selectAll = (e: any, value: string, options: any) =>
    e.target.checked
      ? searchFormProps.form?.setFieldsValue({
          [value]: options.map((val: any) => val.value),
        })
      : searchFormProps.form?.setFieldsValue({ [value]: [] });

  const { triggerExport, isLoading: exportLoading } = useExport<any>({
    resourceName: "user-profile/plan/export",
    metaData: {
      populate: [
        "companies",
        "plan_managements",
        "plan_managements.shift_management",
        "department",
        "branches",
      ],
    },
    // sorter: [{ order: "asc", field: "time_date" }],
    filters: [
      {
        field: "companies].[id]",
        operator: "eq",
        value: comId,
      },
      {
        field: "plan_managements].[plan_date]",
        operator: "between",
        value: [
          dayjs(
            new Date(
              parseInt(searchFormProps.form?.getFieldValue("year")),
              parseInt(searchFormProps.form?.getFieldValue("month")) - 1,
              1
            )
          ).format("YYYY-MM-DD"),
          dayjs(
            new Date(
              parseInt(searchFormProps.form?.getFieldValue("year")),
              parseInt(searchFormProps.form?.getFieldValue("month")) - 1,
              new Date(
                parseInt(searchFormProps.form?.getFieldValue("year")),
                parseInt(searchFormProps.form?.getFieldValue("month")),
                0
              ).getDate()
            )
          ).format("YYYY-MM-DD"),
        ],
      },
      {
        field: "department].[id]",
        operator: "eq",
        value: 23,
      },
    ],

    mapData: (item) => {
      return {    
        "Branch" : item.Branch,
        "Department" :item.Department,
        "EmployeeCode" :item.EmployeeCode,
        "Name/Surname" : item.Name ,
        ...item
      }; 
    },
  });

  return (
    <List
      title="Plan Management"
      pageHeaderProps={{
        extra: (
          <Space>
            <ExportButton onClick={triggerExport} loading={exportLoading}>
              Export CSV.
            </ExportButton>
          </Space>
        ),
      }}
    >
      <Form
        {...searchFormProps}
        initialValues={{
          company: parseInt(comId),
          year: dt.getFullYear(),
          month: month[dt.getMonth()].value,
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Form.Item label="Company" name="company">
              <Select placeholder="Select" {...companyProps} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item label="Branch" name="branch">
              <Select placeholder="Select" {...branch} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item label="Department" name="department">
              <Select
                placeholder="Select"
                {...department}
                // mode="multiple"
                // maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="All">
              <Checkbox
                onChange={(e) => selectAll(e, "department", department.options)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item label="Year" name="year">
              <Select placeholder="Select" options={year.reverse()} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={5}>
            <Form.Item label="Month" name="month">
              <Select placeholder="Select" options={month} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={15}>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>

          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={4}>
            <Button onClick={() => push("create?action=all")}>
              <Icons.UsergroupAddOutlined />
              สร้างแผนงานทั้งฝ่าย
            </Button>
          </Col>
          <Col className="gutter-row" span={4}>
            <Button onClick={() => push("create?action=single")}>
              <Icons.UserAddOutlined />
              สร้างแผนงานรายบุคคล
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        {...tableProps}
        columns={dataColum}
        scroll={{ x: 1300 }}
        size="small"
        rowKey={(val) => val.id}
      />

      <Modal
        {...modalProps}
        // okButtonProps={{
        //   disabled: formProps.initialValues?.plan_date && formProps.initialValues?.plan_date ===  dayjs(new Date()).format('YYYY-MM-DD') ? true : false,
        // }}
        width={400}
        bodyStyle={{ paddingTop: 16 }}
        title="Edit Shift"
        className="modeal-custom"
      >
        <Form
          {...formProps}
          onFinish={(values: any) => {
            update(
              {
                resource: "plan-managements",
                id: id ?? 0,
                values: values,
              },
              {
                onError: (error: any, variables: any, context: any) => {
                  console.log("An error occurred:", error.response);
                },
                onSuccess: (data: any, variables, context: any) => {
                  refetch();
                  close();
                },
              }
            );
          }}
          layout="vertical"
        >
          <Form.Item label="Date" name="plan_date">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Profile"
            name="user_profile"
            getValueProps={(value) => ({
              value: value?.first_name
                ? `${value.first_name} ${value.last_name}`
                : "",
            })}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Old Shift"
            name={["shift_management", "shift_code"]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item label="New Shift" name={["shift_management", "id"]}>
            <Select placeholder="Select" {...selectShift} />
          </Form.Item>
        </Form>
      </Modal>
    </List>
  );
};
