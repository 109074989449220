import {
  Col,
  EditButton,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Timeline,
  useModalForm,
} from "@pankod/refine-antd";
import { CustomCard, DescriptionCard } from "components/common/card";
import { IEmployee } from "interfaces";
import {
  columPers,
  columEmer,
  columBank,
} from "../../../pages/employee/const/colum_card";
export const ProfileTab = ({ empData }: { empData: any }) => {
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columnTable = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_Birth",
      key: "date_of_Birth",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const dataEducation = [
    {
      institution: "International College of Arts and Science (UG)",
      major: "Bsc Computer Science",
      graduation: "2000 - 2003",
    },
    {
      institution: "International College of Arts and Science (UG)",
      major: "Msc Computer Science",
      graduation: "2000 - 2003",
    },
  ];

  const dataExperience = [
    {
      company: "Zen Corporation",
      position: "Web Designer",
      date: "Jan 2013 - Present (5 years 2 months)",
    },
    {
      company: "Ron-tech",
      position: "Web Designer",
      date: "Jan 2013 - Present (5 years 2 months)",
    },
    {
      company: "Dalt Technology",
      position: "Web Designer",
      date: "Jan 2013 - Present (5 years 2 months)",
    },
  ];

  const {
    modalProps,
    formProps,
    show: editModalShow,
  } = useModalForm<IEmployee>({
    action: "edit",
  });

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={
                  <h2 style={{ marginBottom: 10 }}>Personal Informations</h2>
                }
                description={
                  <DescriptionCard profile={empData} colum={columPers} />
                }
              />
            </Col>
            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={<h2 style={{ marginBottom: 10 }}>Emergency Contact</h2>}
                description={
                  <DescriptionCard profile={empData} colum={columEmer} />
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={<h2 style={{ marginBottom: 10 }}>Bank information</h2>}
                description={
                  <DescriptionCard profile={empData} colum={columBank} />
                }
              />
            </Col>

            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={
                  <h2 style={{ marginBottom: 10 }}>Family Informations</h2>
                }
                description={
                  <Table
                    rowKey={(row) => row.key}
                    dataSource={dataSource}
                    columns={columnTable}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={
                  <h2 style={{ marginBottom: 10 }}>Family Informations</h2>
                }
                description={
                  <Timeline style={{ marginTop: 10 }}>
                    {dataEducation.map((value, index) => (
                      <Timeline.Item key={index}>
                        <p style={{ fontWeight: "bold" }}>
                          {value.institution}
                        </p>
                        <p>{value.major}</p>
                        <p style={{ fontSize: 12 }}>{value.graduation}</p>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                }
              />
            </Col>

            <Col className="gutter-row" span={12}>
              <CustomCard
                isEdit={false}
                isEditTitle={false}
                title={<h2 style={{ marginBottom: 10 }}>Experience</h2>}
                description={
                  <Timeline>
                    {dataExperience.map((value, index) => (
                      <Timeline.Item key={index}>
                        <p
                          style={{ fontWeight: "bold" }}
                        >{`${value.position} at ${value.company}`}</p>
                        <p style={{ fontSize: 12 }}>{value.date}</p>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <EditButton onClick={() => editModalShow(empData.id)} /> */}
      <Modal {...modalProps}>
        <Form {...formProps} layout="vertical">
          <Form.Item label="First name" name="first_name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
