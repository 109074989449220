import {
  Col,
  Edit,
  Form,
  Input,
  RefreshButton,
  Row,
  useForm,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IContentPostType } from "interfaces";
import React from "react";

export const ContentPostTypeEdit: React.FC = () => {
  const compId = localStorage.getItem("COMPANY_ID");

  const { formProps, saveButtonProps, formLoading } = useForm<IContentPostType>(
    {
      redirect: "list",
    }
  );

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={() => <RefreshButton />}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          if (formProps.initialValues?.type_flage !== "GLOBAL") {
            values.company = compId;
          }

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Content Type Name"
              name="content_type_name"
              rules={[
                {
                  required: true,
                  message: "Please enter content type name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
