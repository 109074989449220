import {
  Breadcrumb,
  Col,
  Edit,
  Form,
  Row,
  Upload,
  message,
  Input,
  useForm,
  useSimpleList,
  UploadFile,
} from "@pankod/refine-antd";
import { TimePicker } from "antd";
import {
  HttpError,
  useNavigation,
  useRouterContext,
  useShow,
  useUpdate,
} from "@pankod/refine-core";
import { getValueProps } from "@pankod/refine-strapi-v4";
import React, { useEffect, useRef, useState } from "react";
import type { UploadProps } from "antd";
import { API_URL } from "../../constants";
import { TOKEN_KEY } from "../../constants";
import moment from "moment";
import dayjs from "dayjs";
import { ICompany } from "interfaces";

export const EditCompany: React.FC = () => {
  
  const renderRef = useRef(false);
  const [count, setCount] = useState(0);
  const { goBack, push } = useNavigation();
  const { useParams } = useRouterContext();
  const [loading, setLoading] = useState<boolean>(false);
  const format = "HH:mm";
  const id = useParams<{ id: string }>()?.id;

  const { mutate } = useUpdate<ICompany, HttpError, {}>();

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { form, formProps, saveButtonProps, formLoading } = useForm<ICompany>({
    resource: "companies",
    redirect: "list",
    // metaData: {populate: "*"},
    id: id,
  });

  const { queryResult } = useShow<ICompany>({
    resource: "companies",
    metaData: {
      populate: "*",
    },
    id: id,
  });
  let recordData: any;
  const { data, isLoading } = queryResult;
  recordData = data?.data ? data?.data : [];

  useEffect(() => {
    if (count < 2) {
      const interval = setInterval(() => {
        setLoading((prev) => !prev);
        setCount((prev) => prev + 1);
        const fileListBrand: UploadFile[] = recordData?.brand
          ? [
              {
                uid: recordData.brand.id.toString(),
                name: recordData.brand.name,
                status: "done",
                url: recordData.brand.url,
                // thumbUrl: recordData.brand.url,
              },
            ]
          : [];

        const fileListLogo: UploadFile[] = recordData?.logo
          ? [
              {
                uid: recordData.logo.id.toString(),
                name: recordData.logo.name,
                status: "done",
                url: recordData.logo.url,
                // thumbUrl: recordData.logo.url,
              },
            ]
          : [];

        formProps?.form?.setFieldsValue({
          company_name: recordData.company_name ?? "",
          company_description: recordData.company_description ?? "",
          default_work_start: moment(recordData.default_work_start, "HH:mm:ss"),
          default_work_stop: moment(recordData.default_work_stop, "HH:mm:ss"),
          check_radius: recordData.check_radius ?? "0",
          logo: fileListLogo,
          brand: fileListBrand,
          shift_config: recordData?.shift_config ?? "",
        });
      }, 1000);
      setLoading((prev) => !prev);
      return () => {
        clearInterval(interval);
      };
    }
  }, [count]);

  const UpLoadImge = (prop: any) => {
    const { fileList, onChange } = prop;
    return (
      <Upload.Dragger {...props} onChange={onChange} defaultFileList={fileList}>
        <p className="ant-upload-text">
          Drag & drop a file in this area, File size 5 MB, File type .png,
          .jpeg, .jpg
        </p>
      </Upload.Dragger>
    );
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Edit
          isLoading={formLoading || isLoading || loading}
          saveButtonProps={saveButtonProps}
          headerButtons={<></>}
          pageHeaderProps={{
            onBack: () => goBack(),
            // breadcrumb: <Breadcrumb />,
          }}
          title="Edit Company"
        >
          <Form
            {...formProps}
            onFinish={(values: any) => {
              values.logo = !values.logo?.fileList
                ? values.logo
                : values.logo.fileList.length === 0
                ? []
                : values.logo.fileList[0].response;

              values.brand = !values.brand?.fileList
                ? values.brand
                : values.brand.fileList.length === 0
                ? []
                : values.brand.fileList[0].response;

              const workStart = dayjs(
                moment(values.default_work_start, "HH:mm:ss").format(
                  "HH:mm:ss"
                ),
                "HH:mm:ss"
              ).format("HH:mm:ss.SSS");
              const workStop = dayjs(
                moment(values.default_work_stop, "HH:mm:ss").format("HH:mm:ss"),
                "HH:mm:ss"
              ).format("HH:mm:ss.SSS");

              const body = {
                logo: values.logo[0]?.uid ?? values.logo[0]?.id ?? null,
                brand: values.brand[0]?.uid ?? values.brand[0]?.id ?? null,
                company_name: values.company_name,
                company_description: values.company_description,
                default_work_start: workStart,
                default_work_stop: workStop,
                check_radius: values.check_radius,
                shift_config: values.shift_config,
              };

              mutate(
                {
                  resource: "companies",
                  id: id,
                  values: body,
                },
                {
                  onError: (error: any, variables: any, context: any) => {
                    setLoading((prev) => false);
                    console.log("An error occurred:", error.response);
                  },
                  onSuccess: (data: any, variables, context: any) => {
                    setLoading((prev) => !prev);
                    push("/companyselect");
                  },
                }
              );

              // getValueProps={(data) => getValueProps(data, "")}
            }}
            layout="vertical"
          >
            <Form.Item
              name="logo"
              label="Logo"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, "")}
            >
              <UpLoadImge />
            </Form.Item>
            <Form.Item
              name="brand"
              label="Brand"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, "")}
            >
              <UpLoadImge />
            </Form.Item>
            <Form.Item
              label="Company name"
              name="company_name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Company description"
              name="company_description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item
                  label="Check radius (m.)"
                  name="check_radius"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Time attendance"
                  name="default_work_start"
                  rules={[
                    {
                      required: true,
                      message: "Please enter time attendance.",
                    },
                  ]}
                  getValueProps={(value) => ({
                    value: moment(value, format),
                    defaultValue: moment(value, format),
                    format: format,
                    clearIcon: false,
                  })}
                >
                  <TimePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Time off"
                  name="default_work_stop"
                  rules={[
                    {
                      required: true,
                      message: "Please enter time off.",
                    },
                  ]}
                  getValueProps={(value) => ({
                    value: moment(value, format),
                    defaultValue: moment(value, format),
                    format: format,
                    clearIcon: false,
                  })}
                >
                  <TimePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item
                  label="Check in before work (min.)"
                  name={["shift_config", "before_work"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Leave morning (hr.)"
                  name={["shift_config", "half_morning"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Leave afternoon (hr.)"
                  name={["shift_config", "half_afternoon"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Edit>
      </Col>
    </Row>
  );
};
