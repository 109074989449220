import {
  AntdLayout,
  Col,
  Create,
  Form,
  Row,
  Input,
  TimePicker,
  Upload,
  useForm,
  Breadcrumb,
  message,
} from "@pankod/refine-antd";

import dayjs from "dayjs";
import {
  getValueProps,
  mediaUploadMapper,
  useStrapiUpload,
} from "@pankod/refine-strapi-v4";
import { API_URL, TOKEN_KEY } from "../../constants";
import { useState } from "react";
import { ICompany } from "interfaces";
import { HttpError, useCreate } from "@pankod/refine-core";
import { useNavigation } from "@pankod/refine-core";
import moment from "moment";
import type { UploadProps } from "antd";
export const CreateCompany: React.FC = () => {
  const { goBack, push } = useNavigation();
  const [loading, setLoading] = useState<boolean>(false);
  const { formProps, saveButtonProps } = useForm<ICompany, HttpError, {}>();

  const { mutate } = useCreate<ICompany, HttpError, {}>();

  const format = "HH:mm";
  const [vAttenTime, setAttenTime] = useState<any>();
  const [vTimeOff, setTimeOff] = useState<any>();

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const attenTime = (time: any, timeString: any) => {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setAttenTime(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  };

  const timeOff = (time: any, timeString: any) => {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setTimeOff(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  };
  return (
    <Row>
      <Col span={12} offset={6}>
        <Create
          isLoading={loading}
          saveButtonProps={saveButtonProps}
          pageHeaderProps={{
            onBack: () => goBack(),
            breadcrumb: <Breadcrumb />,
          }}
          title="Create Company"
        >
          <Form
            {...formProps}
            initialValues={{
              ...formProps.initialValues,
              shift_config: {
                before_work: "0",
                half_morning: "0",
                half_afternoon: "0",
              }
            }}
            onFinish={(values: any) => {
              setLoading((prev) => !prev);
              mutate(
                {
                  resource: "companies",
                  values: {
                    logo: values.logo ? values.logo.file.response[0].id : null,
                    brand: values.brand
                      ? values.brand.file.response[0].id
                      : null,
                    company_name: values.company_name,
                    company_description: values.company_description,
                    default_work_start: vAttenTime,
                    default_work_stop: vTimeOff,
                    check_radius: values.check_radius,
                    shift_config : values.shift_config,
                  },
                },
                {
                  onError: (error: any, variables: any, context: any) => {
                    setLoading((prev) => !prev);
                    console.log("An error occurred:", error.response);
                  },
                  onSuccess: (data: any, variables, context: any) => {
                    setLoading((prev) => !prev);
                    push("/companyselect");
                  },
                }
              );
            }}
            layout="vertical"
          >
            <Form.Item
              name="logo"
              label="Logo"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, "")}
            >
              <Upload.Dragger {...props}>
                <p className="ant-upload-text">
                  Drag & drop a file in this area, File size 5 MB, File type
                  .png, .jpeg, .jpg
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              name="brand"
              label="Brand"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, "")}
            >
              <Upload.Dragger {...props}>
                <p className="ant-upload-text">
                  Drag & drop a file in this area, File size 5 MB, File type
                  .png, .jpeg, .jpg
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              label="Company name"
              name="company_name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Company description"
              name="company_description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item
                  label="Check radius (m.)"
                  name="check_radius"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Time attendance"
                  name="default_work_start"
                  rules={[
                    {
                      required: true,
                      message: "Please enter time attendance.",
                    },
                  ]}
                >
                  <TimePicker
                    format={format}
                    onChange={(date, dateString) => attenTime(date, dateString)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Time off"
                  name="default_work_stop"
                  rules={[
                    {
                      required: true,
                      message: "Please enter time off.",
                    },
                  ]}
                >
                  <TimePicker
                    format={format}
                    onChange={(date, dateString) => timeOff(date, dateString)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item
                  label="Check in before work (min.)"
                  name={["shift_config", "before_work"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Leave morning (hr.)"
                  name={["shift_config", "half_morning"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Leave afternoon (hr.)"
                  name={["shift_config", "half_afternoon"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Create>
      </Col>
    </Row>
  );
};
