import { IBranch, IEmployee } from "interfaces";
import { useTranslate, useApiUrl, useList } from "@pankod/refine-core";

import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  TimePicker,
  Row,
  Col,
  Upload,
  message,
} from "@pankod/refine-antd";
import { KEY_GOOGLE_MAP, TOKEN_KEY } from "../../constants";
import moment from "moment";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import type { UploadProps } from "antd";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "components/common/map";

interface IGeolocation {
  latitude: number;
  longitude: number;
}

export const BranchCreate = () => {
  const { form, formProps, saveButtonProps } = useForm<IBranch>({
    redirect: "list",
  });

  const [vComp, setComp] = useState<any>();
  const [vAttenTime, setAttenTime] = useState<any>();
  const [vTimeOff, setTimeOff] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<IGeolocation>({
    latitude: 0,
    longitude: 0,
  });

  const compID = localStorage.getItem("COMPANY_ID");
  const API_URL = useApiUrl();
  const format = "HH:mm";

  const t = useTranslate();

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  function attenTime(time: any, timeString: any) {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setAttenTime(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  }

  function timeOff(time: any, timeString: any) {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setTimeOff(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  }

  const defaultProps = {
    center: {
      lat: location?.latitude,
      lng: location?.longitude,
    },
    zoom: 17,
  };

  // useEffect(() => {
    
  //   navigator.geolocation.watchPosition(function (position) {
  //     setLocation({
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude,
  //     });
     
  //   });
  
    
   
  // },[]);



  return (
    <Create
      isLoading={loading}
      title="Create Branch"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.company = compID;
          values.default_work_start = vAttenTime;
          values.default_work_stop = vTimeOff;
          values.lat = location.latitude.toString();
          values.lng = location.longitude.toString();
          values.branch_logo = values.branch_logo?.file?.response[0]?.id;

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={[8, 8]}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.code")}
                  name="branch_code"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter branch code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("branch.name")}
                  name="branch_name"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter branch name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.ta")}
                  name="default_work_start"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter time attendance.",
                    },
                  ]}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format={format}
                    onChange={(date, dateString) => attenTime(date, dateString)}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.to")}
                  name="default_work_stop"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter time off.",
                    },
                  ]}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format={format}
                    onChange={(date, dateString) => timeOff(date, dateString)}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("branch.cinr")}
                  name="check_redius"
                  rules={[
                    {
                      required: true,
                      message: "Please select branch.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={12}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t("branch.logo")}
                name="branch_logo"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, API_URL)}
              >
                <Upload.Dragger style={{ borderRadius: 10 }} {...props}>
                  <p className="ant-upload-text">
                    {" "}
                    Drag & drop a file in this area, File size 5 MB, File type
                    .png, .jpeg, .jpg
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}></Row>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <div style={{ width: "100%", height: "50vh" }}>
              {location.latitude && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: `${KEY_GOOGLE_MAP}`,
                  }}
                  defaultCenter={{
                    lat: location.latitude,
                    lng: location.longitude ,
                  }}
                  // center={{ lat: location.latitude, lng: location.longitude }}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onClick={(val) => console.log(val)}
                >
                  <MapMarker
                    lat={location?.latitude}
                    lng={location?.longitude}
                  />
                </GoogleMapReact>
              )}
            </div>
          </Col>
        </Row> */}
      </Form>
    </Create>
  );
};
