import {
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IClock, IEmployee } from "interfaces";
import { useState } from "react";
import dayjs from "dayjs";
import { useList, useTranslate } from "@pankod/refine-core";

export const ClockCreate = () => {

  const t = useTranslate();
  const { Option } = Select;
  const compID = localStorage.getItem("COMPANY_ID");
  const format = "HH:mm:ss";
  
  const [vTimeOff, setTimeOff] = useState<any>();
  const [ vUser , setUser ] = useState<any>();

  const { formProps, saveButtonProps, formLoading } = useForm<IClock>({
    redirect: "list",
  });

  const UserProfile = useList<IEmployee>({ 
    resource: "user-profiles", 
    metaData: {
        populate: '*',
    },
    config:{
        filters: [
            {
                field: "companies][id]",
                operator: "eq",
                value: compID,
            }
        ]
    }
  });
  
  const { data: DataUser , isLoading} = UserProfile;
  let UserData:any = []
  UserData = DataUser?.data? DataUser?.data : [];

  function onchangeUser(value:any) {
    setUser(value);
  }

  function timeOff(time: any, timeString: any) {
    setTimeOff(dayjs(timeString, "HH:mm:ss").format("HH:mm:ss.SSS"));
  }

  return (
    <Create
      title="Create Clock"
      isLoading={formLoading || isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
         values.time_track = vTimeOff
         values.user_profile = vUser
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>

            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("clock.time")}
                  name="time_track"
                  rules={[
                    {
                      required: true,
                      message: "Please select time.",
                    },
                  ]}
                >
                  <TimePicker style={{width: "100%"}}
                    format={format}
                    onChange={(date, dateString) => timeOff(date, dateString)}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={9}>
                <Form.Item
                  label={t("clock.date")}
                  name="date_track"
                  rules={[
                    {
                      required: true,
                      message: "Please select date.",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={9}>
                <Form.Item
                  label={t("clock.user-p")}
                  name="user_profile"
                  rules={[
                    {
                      required: true,
                      message: "Please select user.",
                    },
                  ]}
                >
                  <Select onChange={onchangeUser}>
                    {
                      UserData !== undefined?
                        UserData.map((item:any,key:any) => {
                              return <>
                                        <Option key={key} value={item.id}>{item.first_name + ' ' + item.last_name}</Option>
                                    </>
                          })
                      :
                          <></>
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>

          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("clock.location")}
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter location.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("clock.clock-by")}
                  name="clock_by"
                  rules={[
                    {
                      required: true,
                      message: "Please enter clock by.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

      </Form>
    </Create>
  );
};
