import {
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { IPackage } from "interfaces";

export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IPackage,
    HttpError,
    IPackage
  >({
    metaData: {
      populate: "*",
    },
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { package_name } = params;
      fillters.push({
        field: "package_name",
        operator: "contains",
        value: package_name,
      });

      return fillters;
    },
  });
  return (
    <List
      title="Package List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="package_name">
          <Input placeholder="Package Name" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} size="small">
        <Table.Column
          dataIndex="package_name"
          title="Package Name"
          render={(value: any, record: any) => {
            return <TextField value={record.package_name} />;
          }}
        />

        <Table.Column
          dataIndex="package_status"
          title="Package Status"
          render={(value: any, record: any) => {
            return <TextField value={record.package_status} />;
          }}
        />

        <Table.Column<IPackage>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton disabled hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
