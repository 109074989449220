import { ICompany, IDepartment } from "interfaces";

import {
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Row,
  Col,
  Edit,
} from "@pankod/refine-antd";

import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { useEffect, useState } from "react";
import { useShow, useTranslate } from "@pankod/refine-core";

export const DepartmentEdit = () => {

  const compID          = localStorage.getItem("COMPANY_ID");
  const t = useTranslate();

  const { form, formProps, saveButtonProps } = useForm<IDepartment>({
    redirect: "list",
  });

  const { queryResult } = useShow<ICompany>({
    metaData: {
      populate: "*",
    },
  });

  const { isLoading } = queryResult;
  let recordData: any;
  recordData = queryResult.data?.data ? queryResult.data?.data : [];

  const { selectProps: companyProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: "company_name",
    optionValue: "id",
  });

  const [count, setCount] = useState(0);
  const [ vCompany , setCompany ] = useState<any>([]);


  useEffect(() => {
    if (count < 2) {
      const interval = setInterval(() => {
        setCount((prev) => prev + 1);

        recordData?.company !== undefined ?
                    recordData.company.length > 0 ? 
                        recordData?.company.map((v:any)=>{
                            vCompany.push(v.id)
                        }):<></>:<></>

        form.setFieldsValue({
          company:
            recordData?.company !== undefined
              ? recordData.company.length > 0
                ? recordData?.company.map((v: any) => {
                    return { value: v.id, label: v.company_name };
                  })
                : form.resetFields(["company"])
              : form.resetFields(["company"]),
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  function onChangecompany(va:any){
    setCompany(va)
};

  return (
    <Edit title="Edit Department" isLoading={isLoading} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values:any) => {

          values.company = compID;//vCompany

          return formProps.onFinish?.({
              ...mediaUploadMapper(values)
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>

            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("department.code")}
                  name="dept_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter depart code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("department.name")}
                  name="dept_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter depart name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Form.Item
              label={t("department.desc")}
              name="dept_description"
              rules={[
                {
                  required: true,
                  message: "Please enter description code.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("department.comp")}
              name="company"
              // name={["project_teams", "id"]}
              rules={[
                {
                  required: true,
                  message: "Please select company.",
                },
              ]}
            >
              <Select mode="multiple" {...companyProps}
              onChange={onChangecompany} />
            </Form.Item>
          </Col>

        </Row> */}
      </Form>
    </Edit>
  );
};
