import {
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { ILeaveType } from "interfaces";

export const LeaveTypeList: React.FC = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<
    ILeaveType,
    HttpError,
    ILeaveType
  >({
    metaData: {
      populate: ["leave_condition", "create_by", "edit_by"],
      
    },
    hasPagination : false ,
    initialFilter: [
      {
        //field       : "emp_status][id]",
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { leave_type_name } = params;
      fillters.push({
        field: "leave_type_name",
        operator: "contains",
        value: leave_type_name,
      });

      return fillters;
    },
  });
  return (
    <List
      title="Leave Type List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="leave_type_name">
          <Input placeholder="Leave Name" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} size="small">
        <Table.Column
          dataIndex="leave_type_code"
          title="Leave Code"
          render={(value: any, record: any) => {
            return <TextField value={record.leave_type_code} />;
          }}
        />
        <Table.Column
          dataIndex="leave_type_name"
          title="Leave Name"
          render={(value: any, record: any) => {
            return <TextField value={record.leave_type_name} />;
          }}
        />

        <Table.Column
          dataIndex="user_creator"
          title="Create By"
          render={(value: any, record: any) => {
            return <TextField value={record?.user_creator?.first_name ?? ""} />;
          }}
        />

        <Table.Column
          dataIndex="user_last_editor"
          title="Edit By"
          render={(value: any, record: any) => {
            return (
              <TextField value={record?.user_last_editor?.first_name ?? ""} />
            );
          }}
        />

        <Table.Column<ILeaveType>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton
                disabled
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
