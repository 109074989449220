import { Button, Menu, Icons } from "@pankod/refine-antd";
import type { MenuProps } from "antd";
import { BaseCard } from "components/common/card";
import { useEffect, useState } from "react";
import "./styles.css";

interface IItemProps {
  id: string;
  custom_role_name: string;
}

interface IBaseMenuPops {
  items: IItemProps[];
  onShowEdit?:(val:string) => any
  onSelectRole?:(val:string) => any
}

export const BaseMenu: React.FC<IBaseMenuPops> = ({
  items,
  onShowEdit = () => ({}),
  onSelectRole = () => ({}),
}) => {
  const [current, setCurrent] = useState("");

  const onClick: MenuProps["onClick"] = (e) => {
    
    setCurrent(e.key);
    onSelectRole(e.key)
   
  };

  const checkAdministrator = (val: string) => {
    if (val.toUpperCase() === "ADMINISTRATOR") return true;
    else return false;
  };

  useEffect(() => {
    setCurrent(items[0]?.id.toString())
  }, [items])
  
  return (
    <BaseCard size="small">
      <Menu mode="inline" onClick={onClick} selectedKeys={[current]}>
        {items &&
          items.map((val, idx) => (
            <Menu.Item className="menu-active" key={val.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>{val.custom_role_name}</p>
                <div
                  className={`${
                    current === val.id.toString() && !checkAdministrator(val.custom_role_name)
                      ? "show"
                      : "hide"
                  }`}
                  style={{zIndex: 1}}
                >
                  <Button
                    onClick={() => onShowEdit(val.id)}
                    style={{ padding: "0px 4px" }}
                    type="primary"
                    shape="round"
                    icon={<Icons.EditOutlined />}
                    size="small"
                  />
                  <Button
                    style={{ padding: "0px 4px", marginLeft: 5 }}
                    type="primary"
                    shape="round"
                    icon={<Icons.DeleteOutlined />}
                    size="small"
                  />
                </div>
              </div>
            </Menu.Item>
          ))}
      </Menu>
    </BaseCard>
  );
};
