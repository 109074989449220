import {
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Select,
  Space,
  Switch,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import { IShift } from "interfaces";
import React from "react";
import moment from "moment";



export const ShiftList: React.FC = () => {
  const { Option } = Select;
  const format = "HH:mm";
  const compID = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<IShift, HttpError, IShift>({
    metaData: {
      populate: ["*", "company"],
    },
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "company].[id]",
        operator: "eq",
        value: compID,
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { ship_code, ship_type, day_type } = params;
      filters.push({
        field: "ship_code",
        operator: "containss",
        value: ship_code,
      },
      {
        field: "ship_type",
        operator: "eq",
        value: ship_type,
      },
      {
        field: "day_type",
        operator: "eq",
        value: day_type,
      },
      );
      return filters;
    },
  });

  return (
    <List
      title="Shift List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="ship_code">
          <Input placeholder="Shift Code" />
        </Form.Item>

        <Form.Item name="ship_type">
          <Select placeholder="Shift Type" style={{ width: 130 }}>
            <Option value="Constant">คงที่</Option>
            <Option value="Expand">ยืดหยุ่น</Option>
          </Select>
        </Form.Item>

        <Form.Item name="day_type">
          <Select placeholder="Day Type" style={{ width: 130 }}>
            <Option value="Working Day">Working Day</Option>
            <Option value="Off Day">Off Day</Option>
            <Option value="Holiday">Holiday</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              searchFormProps.form?.resetFields();
              searchFormProps.form?.submit();
              // refetch()
            }}
          >
            <Icons.UndoOutlined /> Reset
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table size="small" {...tableProps} rowKey={(row) => row.id}>
        <Table.Column
          align="center"
          //   width={35}
          title="Shift Code"
          dataIndex="shift_code"
          render={(value: any, record: any) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          align="center"
          title="Description(thai)"
          dataIndex="desc_th"
          render={(value: any, record: any) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          align="center"
          title="Shift Type"
          dataIndex="shift_type"
          render={(value: any, record: any) => {
            return value === "Expand" ? <TextField value={"กะยืดหยุ่น"} /> : <TextField value={"กะคงที่"} />
          }}
        />
        <Table.Column
          align="center"
          title="Day Type"
          dataIndex="day_type"
          render={(value: any, record: any) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          align="center"
          title="Attendance time"
          dataIndex="default_work_start"
          render={(value: any, record: any) => {
            return <TextField value={moment(value, format).format(format)} />;
          }}
        />
        <Table.Column
          align="center"
          title="Time off"
          dataIndex="default_work_stop"
          render={(value: any, record: any) => {
            return <TextField value={moment(value, format).format(format)} />;
          }}
        />
        <Table.Column
          align="center"
          title="Status"
          dataIndex="status"
          render={(value: any, record: any) => {
            return <Switch defaultChecked={value} />;
          }}
        />
        <Table.Column<IShift>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
