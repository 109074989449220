import {
  Col,
  Create,
  Form,
  Input,
  Radio,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { TimePicker } from "antd";
import { HttpError } from "@pankod/refine-core";
import { IShift } from "interfaces";
import React from "react";
import moment from "moment";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";

export const CreateShift: React.FC = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const format = "HH:mm";
  const compId = localStorage.getItem("COMPANY_ID");
  
  const { formProps, saveButtonProps } = useForm<IShift, HttpError, IShift>({
    redirect: "list",
  });


  
  return (
    <Create title="Create Shift" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{
          shift_type: "Constant",
          day_type: "Working Day",
          status: "Active",
        }}
        onFinish={(values: any) => {
          values.default_work_start = moment(values.default_work_start).format(
            "HH:mm:ss.SSS"
          );
          values.default_work_stop = moment(values.default_work_stop).format(
            "HH:mm:ss.SSS"
          );
          values.company = compId;
           
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Shift Code"
              name="shift_code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={9}>
            <Form.Item
              label="Description(thai)"
              name="desc_th"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={9}>
            <Form.Item
              label="Description(en)"
              name="desc_en"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item label="Shift type" name="shift_type">
              <Radio.Group>
                <Radio value="Constant">คงที่</Radio>
                <Radio value="Expand">ยืดหยุ่น</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={9}>
            <Form.Item label="Day Type" name="day_type">
              <Radio.Group>
                <Radio value="Working Day">Working Day</Radio>
                <Radio value="Off Day">Off Day</Radio>
                <Radio value="Holiday">Holiday</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col
            style={{ display: "flex", gap: 15 }}
            className="gutter-row"
            span={9}
          >
            <Form.Item
              label="Time attendance"
              name="default_work_start"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TimePicker format={format} />
            </Form.Item>
            <Form.Item
              label="Time off"
              name="default_work_stop"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TimePicker format={format} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item
              label="Color"
              name="color"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select style={{ width: 130 }}>
                <Option value="red">red</Option>
                <Option value="blue">blue</Option>
                <Option value="green">green</Option>
                <Option value="orange">orange</Option>
                <Option value="yellow">yellow</Option>
                <Option value="pink">pink</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            style={{ display: "flex", gap: 15 }}
            span={9}
          >
            <Form.Item name="rg_comp" label="% RG Comp">
              <Input />
            </Form.Item>
            <Form.Item name="ot_comp" label="% OT Comp">
              <Input />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            style={{ display: "flex", gap: 15 }}
            span={9}
          >
            <Form.Item label="Status" name="status">
              <Radio.Group>
                <Radio value="Active">Active</Radio>
                <Radio value="Inactive">Inactive</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
