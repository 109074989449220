import {
  useStepsForm,
  Create,
  Steps,
  AntdLayout,
  Row,
  Col,
  Form,
} from "@pankod/refine-antd";
import { API_URL, TOKEN_KEY } from "../../constants";
import { ICompany, IEmployee, IUser } from "interfaces";
import { useEffect, useState } from "react";
import {
  User,
  ActionButtons,
  UserProfile,
  Company,
} from "../../components/page/register/form";
import { axiosInstance } from "authProvider";
import {
  useApiUrl,
  useCreate,
  useNavigation,
  useLogin,
  useCustomMutation,
} from "@pankod/refine-core";
import "./styles.css";
import { AuthHelper } from "@pankod/refine-strapi-v4";
interface IStateList {
  name: string;
  action: boolean;
}
interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

interface IUserPermission {
  userPermission: string;
  username: string;
  password: string;
}

interface IGeolocation {
  latitude: number;
  longitude: number;
}


export const Register: React.FC = () => {
  const apiUrl = useApiUrl();
  const { goBack} = useNavigation();
  const strapiAuthHelper = AuthHelper(apiUrl);
  const { mutate: login } = useLogin<ILoginForm>();
  const { mutate: creatUserProfile } = useCreate<IEmployee>();
  const { mutate: creatCompany } = useCreate<ICompany>();
  const { mutate: register } = useCustomMutation<IUserPermission>();

  const [userPermission, setUserPermission] = useState<IUserPermission>({username:"", password: "" , userPermission : ""});
  const [vAttenTime, setAttenTime] = useState<any>();
  const [vTimeOff, setTimeOff] = useState<any>();
  const [stateList, setStateList] = useState<IStateList[]>([]);
  const [location, setLocation] = useState<IGeolocation>({
    latitude: 0,
    longitude: 0,
  });

  const formList = [
    {
      name: "User",
      action: false,
      component: <User />,
      funtion: (val: any, action: boolean) => onHandleFinishUser(val, action),
    },
    {
      name: "UserProfile",
      action: false,
      component: <UserProfile />,
      funtion: (val: any, action: boolean) =>
        onHandleFinishUserProfile(action, val),
    },
    {
      name: "Company",
      action: false,
      component: (
        <Company
          setAttenTime={(date: any) => setAttenTime(date)}
          setTimeOff={(date: any) => setTimeOff(date)}
        />
      ),
      funtion: (val: any, action: boolean) =>
        onHandleFinishCompany(val, action),
    },
  ];

  const { form, current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm<IUser>();

  const registerLogin = async (username: string, password: string) => {
    const { data, status } = await strapiAuthHelper.login(username, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      localStorage.setItem("USER_ID", data.user.id.toString());
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${data.jwt}`,
      };
    } else {
      console.log("login failed!");
    }
  };

  const onHandleFinishUser = (value: any, action: boolean) => {
    if (!action) {
      axiosInstance
        .post(
          `${API_URL}/api/auth/local/register`,
          {
            username: value.username,
            email: value.email,
            password: value.password,
          },
          { headers: { Authorization: `Bearer ${TOKEN_KEY}` } }
        )
        .then((response) => {
          setUserPermission({
            userPermission: response.data.user.id,
            username: value.username,
            password: value.password,
          });
          registerLogin(value.username, value.password);
          gotoStep(current + 1);
        })
        .catch((error) => {
          console.log("An error occurred:", error.response);
        });
    } else {
      gotoStep(current + 1);
    }
  };

  const onHandleFinishUserProfile = (action: boolean, value: any) => {
    if (!action) {
      creatUserProfile(
        {
          resource: "user-profiles",
          values: {
            emp_no : "EN0001",
            prefix: value.prefix,
            first_name: value.first_name,
            last_name: value.last_name,
            phone_no: value.phone_no,
            user_permission: userPermission?.userPermission,
            avatar: value.avatar ? value.avatar.file.response[0].id : null,
            status: "ACTIVE",
          },          
          successNotification: false,
        },
        {
          onError: (error: any, variables: any, context: any) => {
            console.log("An error occurred:", error.response);
          },
          onSuccess: (data: any, variables, context: any) => {
            gotoStep(current + 1);
          },
        }
      );
    } else {
      gotoStep(current + 1);
    }
  };

  const onHandleFinishCompany = (value: any, action: boolean) => {
    creatCompany(
      {
        resource: "companies",
        values: {
          logo: value.logo ? value.logo.file.response[0].id : null,
          brand: value.brand ? value.brand.file.response[0].id : null,
          company_name: value.company_name,
          company_description: value.company_description,
          default_work_start: vAttenTime,
          default_work_stop: vTimeOff,
          lat: location.latitude ,
          lng: location.longitude
        },
      },
      {
        onError: (error: any) => {
          console.log("Company, An error occurred:", error.response);
        },
        onSuccess: () => {
            const objLogin = {
              username : userPermission.username ,
              password : userPermission.password,
              remember : false
            }
            login(objLogin)
        },
      }
    );
  };

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });

    setStateList(() => {
      const newState = formList.map(({ name, action }) => {
        return { name, action };
      });

      return newState;
    });
  }, []);

  return (
    <AntdLayout>
      <Row>
        <Col span={12} offset={6}>
          <Create
            title="Login"
            pageHeaderProps={{
              onBack: () => goBack(),
              
            }}
            actionButtons={
              <ActionButtons
                gotoStep={gotoStep}
                form={form}
                current={current}
                saveButtonProps={saveButtonProps}
                formLength={formList.length}
                setStateList={() => {
                  setStateList((prevState) => {
                    const newState = prevState.map((val) => {
                      if (val.name === stateList[current - 1].name) {
                        return { ...val, action: true };
                      }
                      return val;
                    });

                    return newState;
                  });
                }}
              />
            }
          >
            <Steps
              {...stepsProps}
              onChange={() => null}
              className="step-layout"
            >
              <Steps.Step title="Create user" />
              <Steps.Step title="Create profile" />
              <Steps.Step title="Create company" />
            </Steps>
            <Form
              style={{marginTop:20}}
              {...formProps}
              onFinish={(value: any) =>
                formList[current].funtion(value, stateList[current].action)
              }
              layout="vertical"
            >
              {formList[current].component}
            </Form>
          </Create>
        </Col>
      </Row>
    </AntdLayout>
  );
};
