import { IBranch, ICompany, IDepartment, IEmployee, IHoliday, IPosition, IPrefix } from "interfaces";
import { useTranslate, useApiUrl, useShow, useList } from "@pankod/refine-core";

import { 
    Create, 
    Form, 
    DatePicker,
    // eslint-disable-next-line 
    Icons, 
    Input, 
    Select, 
    useForm, 
    useSelect, 
    Edit,
    TimePicker,
    Row,
    Col,
    Upload
} from "@pankod/refine-antd";
import { TOKEN_KEY } from "../../constants";
//import { StoreContext } from "context/store";
//import { TOKEN_KEY } from "../../constants";
import {
    useStrapiUpload,
    mediaUploadMapper,
    // eslint-disable-next-line 
    getValueProps,
} from "@pankod/refine-strapi-v4";
// eslint-disable-next-line 
import { useContext, useState } from "react";
import dayjs from "dayjs";

export const HolidayCreate = () => {
    const compID = localStorage.getItem("COMPANY_ID");

    // eslint-disable-next-line 
    const API_URL = useApiUrl();
    const { formProps, saveButtonProps } = useForm<IHoliday>({redirect:"list"});

    const format = 'HH:mm:mm';
    const { Option } = Select;

    const [ vComp, setComp ] = useState<any>();
    const [ vEmpOnComp, setEmpOnComp ] = useState<any>([]);
    const [ vAttenTime, setAttenTime ] = useState<any>();
    const [ vTimeOff, setTimeOff ] = useState<any>();

    const { selectProps: companyProps } = useSelect<ICompany>({
        resource: "companies",
        optionLabel : "company_name"
    });

    function onChangeEmp(va:any){
        vEmpOnComp.pop();
        vEmpOnComp.push(va);
        setEmpOnComp(vEmpOnComp);
    };

    const UserDetail = useList<IEmployee>({ 
        resource: "user-profiles", 
        // metaData: {
        //     populate: ['user_profile'],
        // },
        config:{
            // sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "companies].[id]",
                    operator: "eq",
                    value   : compID
                },
            ]
        }
    });
    const { data: kData } = UserDetail;
    let userData:any = []
    userData = kData?.data? kData?.data : [];
    console.log(userData);
    // eslint-disable-next-line 
    const { ...uploadProps } = useStrapiUpload({
        maxCount: 1,
    });

    // eslint-disable-next-line 
    const t = useTranslate();

    function onchangeCompany(value:any) {
        setComp(value);
    }

    function attenTime(time: any, timeString: any) {
        // console.log(timeString + ":00.000")
        // setAttenTime(timeString + ":00.000");
        setAttenTime(dayjs(timeString, "HH:mm:ss").format('HH:mm:ss.SSS'));
    }

    function timeOff(time: any, timeString: any) {
        // console.log(timeString + ":00.000")
        // dayjs(timeString, "HH:mm:ss").format('HH:mm')
        // setTimeOff(timeString + ":00.000");
        setTimeOff(dayjs(timeString, "HH:mm:ss").format('HH:mm:ss.SSS'));
    }

    return (
        <Create 
            title="Create Holiday"
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    values.company = compID
                    values.year = new Date().getFullYear().toString()
                    // values.user_profile = vEmpOnComp[0]
                    // values.default_work_start = vAttenTime
                    // values.default_work_stop = vTimeOff

                    return formProps.onFinish?.({
                        ...mediaUploadMapper(values)
                        //,stores: store,
                    });
                }}
            >

            {/* <Row>
                <Col span={11}>
                    <Form.Item
                        label="Company"
                        name="company"
                        // name={["project_teams", "id"]}
                        rules={[
                            {
                                required: true, message: 'Please select company.'
                            },
                        ]}
                    >
                        <Select {...companyProps}
                            // mode="multiple"
                            onChange={onchangeCompany}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={11}></Col>
            </Row> */}

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item
                                    label={t("holiday.date")}
                                    name="holiday_date"
                                    rules={[
                                        {
                                            required: true, message: 'Please select holiday date.'
                                        },
                                    ]}
                                >
                                    <DatePicker style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={18}>
                                <Form.Item
                                    label={t("holiday.name")}
                                    name="holiday_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};