import { Checkbox, Col, Row, Switch, Table } from "@pankod/refine-antd";
import { List } from "antd";
import { IModuleAccess } from "interfaces";
import type { ColumnsType } from "antd/es/table";
import React from "react";

interface ITableUserLog {
  columns: any;
  dataAccess?: any[];
  onChange?:(record:any)=>void
}

export const RoleManagements: React.FC<ITableUserLog> = ({
  dataAccess,
  columns,
  onChange=()=>({})
}) => {
  
  return (
    <Row gutter={[26, 16]}>
      <Col style={{ height: 40 }} span={24}>
        <h2>Module Access</h2>
      </Col>
      <Col span={24}>
       
        <List
          bordered
          dataSource={dataAccess}
          renderItem={(item) => (
            
            <>
              <List.Item key={item.id} style={{ fontWeight: 500 }}>
                <div>{item.module_master?.module_name}</div>
                <Switch onChange={()=> onChange(item)} checked={item.active}  />
              </List.Item>
            </>
          )}
        />
      </Col>

      <Col span={24}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={dataAccess}
        />
      </Col>
    </Row>
  );
};
