import {
  ICompany,
  IDepartment,
} from "interfaces";

import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Row,
  Col,
} from "@pankod/refine-antd";

import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { useTranslate } from "@pankod/refine-core";

export const DepartmentCreate = () => {

  const compID          = localStorage.getItem("COMPANY_ID");
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IDepartment>({
    redirect: "list",
  });

  // const { selectProps: companyProps } = useSelect<ICompany>({
  //   resource: "companies",
  //   optionLabel: "company_name",
  //   optionValue: "id"
  // });

  

  return (
    <Create title="Create Department" saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={(values: any) => {
         values.company = compID;
         return formProps.onFinish?.({
            ...mediaUploadMapper(values)
            //,stores: store,
        });
      }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>

            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("department.code")}
                  name="dept_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter depart code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("department.name")}
                  name="dept_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter depart name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Form.Item
              label={t("department.desc")}
              name="dept_description"
              rules={[
                {
                  required: true,
                  message: "Please enter description code.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("department.comp")}
              name="company"
              // name={["project_teams", "id"]}
              rules={[
                {
                  required: true,
                  message: "Please select company.",
                },
              ]}
            >
              <Select mode="multiple" {...companyProps} />
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Create>
  );
};
