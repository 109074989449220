import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  ShowButton,
  Icons,
  CreateButton,
  Form,
  Avatar,
  Input,
  Button,
  ExportButton,
} from "@pankod/refine-antd";
import { IEmployee, ICompany, IBranch } from "interfaces";

import customParseFormat from "dayjs/plugin/customParseFormat";

// id                  : string
// branch_code         : string
// branch_name         : string
// branch_description  : string
// default_work_start  : string
// default_work_stop   : string
// lat                 : string
// lng                 : string
// check_radius        : string

import dayjs from "dayjs";

export const BranchesList: React.FC<IResourceComponentsProps> = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  dayjs.extend(customParseFormat);
  const format = "HH:mm:mm";
  const { tableProps, searchFormProps } = useTable<IBranch, HttpError, IBranch>(
    {
      metaData: {
        populate: "*",
      },
      initialFilter: [
        {
          field: "company][id]",
          operator: "eq",
          value: compID,
        },
      ],
      initialSorter: [
        {
          field: "id",
          order: "desc",
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { branch_name } = params;
        // console.log(branch_name);
        filters.push(
          {
            field: "branch_name",
            operator: "containss",
            value: branch_name,
          }
          // {
          //     field: "emo_no",
          //     operator: "contains",
          //     value: emo_no,
          // },
          // {
          //     field: "emp_name",
          //     operator: "eq",
          //     value: first_name,
          // },
        );
        return filters;
      },
    }
  );

  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };
  // const { triggerExport, isLoading: exportLoading } = useExport<IEmployee>({
  //     mapData: (item) => {
  //         return {
  //             id              : item.id,
  //             first_name      : item.first_name,
  //             last_name       : item.last_name
  //         };
  //     },
  // });

  // const categoryIds =
  //     tableProps?.dataSource?.map((item) => item.category.id) ?? [];
  // const { data, isLoading } = useMany<ICategory>({
  //     resource: "categories",
  //     ids: categoryIds,
  //     queryOptions: {
  //         enabled: categoryIds.length > 0,
  //     },
  // });
  const { triggerExport, isLoading: exportLoading } = useExport<IBranch>({
    resourceName: "branches",
    sorter: [{ order: "asc", field: "id" }],
    filters: [{ field: "company].[id]", operator: "eq", value: compID }],
    exportOptions: {
      filename: `Branches for KEEP`,
    },
    mapData: (item) => {
      return {
        "Branches ID": item.id,
        "Employee Name": item.branch_name,
      };
    },
  });
  // function onStatusChange() {
  //   searchFormProps.form?.submit();
  // }

  return (
    <>
      <List
        title="Branches List"
        pageHeaderProps={{
          extra: (
            <Space>
              <CreateButton />
              <ExportButton
                disabled={tableProps.dataSource?.length === 0 ? true : false}
                onClick={triggerExport}
                loading={exportLoading}
              >
                Export CSV.
              </ExportButton>
            </Space>
          ),
        }}
      >
        <Form {...searchFormProps} layout="inline">
          <Form.Item name="branch_name">
            <Input placeholder="Branches Name" />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={onReset} htmlType="submit" type="dashed">
              <Icons.UndoOutlined /> Reset
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Table
          {...tableProps}
          // bordered
          size="small"
          rowKey="id"
        >
          <Table.Column
            align="center"
            width={35}
            dataIndex="id"
            title="#"
            render={(value: any, record: any) => {
              return <TextField value={value} />;
            }}
          />
          <Table.Column
            align="center"
            width={35}
            dataIndex="branch_logo"
            title="Logo"
            render={(value: any, record: any) => {
              return (
                <>
                  {value ? (
                    <Avatar size={32} src={value.url} />
                  ) : (
                    <>
                      <Avatar size={32} icon={<Icons.UserOutlined />} />
                    </>
                  )}
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="branch_name"
            title="Branch name"
            render={(value: any, record: any) => {
              // {
              //     console.log(record)
              // }
              return (
                <>
                  <TextField value={record.branch_name} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="default_work_start"
            title="Attendance time"
            render={(value: any, record: any) => {
              // console.log(record)
              // const abc = dayjs(value).format('HH:mm:ss');
              const time = dayjs(value, "HH:mm");
              // console.log(day.format("HH:mm:ss"));
              return (
                <>
                  <TextField value={time.format("HH:mm")} />
                  {/* <TextField value={dayjs(value).format("HH:mm:ss")}  /> */}
                  {/* <TextField value={dayjs(value)}  /> */}
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="default_work_stop"
            title="Time off"
            render={(value: any, record: any) => {
              const time = dayjs(value, "HH:mm");
              return (
                <>
                  {/* <TextField value={value}  /> */}
                  <TextField value={time.format("HH:mm")} />
                </>
              );
            }}
          />

          <Table.Column<IEmployee>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                ></ShowButton>
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
