import {
  Avatar,
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Popconfirm,
  Select,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
  useSelect,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  useGetIdentity,
  useUpdate,
} from "@pankod/refine-core";
import { ILeave, ILeaveType } from "interfaces";

export const LeaveList: React.FC = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const pID = localStorage.getItem("PID");
  const { data: user } = useGetIdentity();
  const { Option } = Select;
  const { mutate, isLoading } = useUpdate<ILeave, HttpError>();

  const { tableProps, searchFormProps } = useTable<ILeave, HttpError, ILeave>({
    metaData: {
      populate: ["user_profile.avatar", "user_profile", "leave_type"],
    },
    initialFilter: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
      {
        field: "user_profile][id]",
        operator: "eq",
        value: pID ? pID : "0",
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { leave_type } = params;
      fillters.push(
        {
          field: "leave_type][id]",
          operator: "contains",
          value: leave_type,
        }
        // {
        //   field: "status",
        //   operator: "contains",
        //   value: leave_type,
        // }
      );

      return fillters;
    },
  });
  const { selectProps: leavProps } = useSelect<ILeaveType>({
    resource: "leave-types",
    optionLabel: "leave_type_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  const onConfirm = (id: any) => {
    mutate(
      {
        resource: "leaves",
        id: id,
        values: { status: "SEND_REQUEST" },
      },
      {
        onError: (error, variables, context) => {
          // An error happened!
        },
        onSuccess: (data, variables, context) => {
          // Let's celebrate!
          console.log(data);
        },
      }
    );
  };
  // const data1 = new Date("2022-04-01")
  // const data2 = new Date("2022-04-04")
  // var diff = data2.getTime() - data1.getTime() ;   
  // var daydiff = (diff / (1000 * 60 * 60 * 24)) +1;   
  // console.log(daydiff)


  const diffDate = (start:string , end:string) => {
    const data1 = new Date(start)
    const data2 = new Date(end)
    const diff = data2.getTime() - data1.getTime() ;   
    const daydiff = (diff / (1000 * 60 * 60 * 24)) +1;   
    return daydiff
  }
  return (
    <List
      title="Leave List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="leave_type">
          <Select
            placeholder="leave type"
            style={{ width: "15rem" }}
            {...leavProps}
          />
        </Form.Item>
        {/* <Form.Item name="status">
          <Select placeholder="status" style={{ width: "15rem" }}>
            <Option value="DRAFT">DRAFT</Option>
            <Option value="SEND_REQUEST">SEND_REQUEST</Option>
            <Option value="APPROVE">APPROVE</Option>
            <Option value="REJECT">REJECT</Option>
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => onReset()} htmlType="submit" type="dashed">
            <Icons.UndoOutlined /> Reset
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} loading={isLoading} size="small">
        <Table.Column
          dataIndex="leave_detail"
          title="Reason"
          render={(value: any, record: any) => {
            return <TextField value={record?.leave_detail} />;
          }}
        />
        <Table.Column
          dataIndex="leave_type"
          title="Leave Type"
          render={(value: any, record: any) => {
            return <TextField value={record?.leave_type?.leave_type_name} />;
          }}
        />
        <Table.Column
          dataIndex="start_date"
          title="Form"
          render={(value: any, record: any) => {
            return <TextField value={record?.start_date} />;
          }}
        />
        <Table.Column
          dataIndex="end_date"
          title="To"
          render={(value: any, record: any) => {
            return <TextField value={record?.end_date} />;
          }}
        />
        <Table.Column
          dataIndex="no_of_days"
          title="No of Days"
          render={(value: any, record: any) => {
            return <TextField value={diffDate(record.start_date , record.end_date)} />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value: any, record: any) => {
            return <TextField value={record?.status} />;
          }}
        />
        <Table.Column
          dataIndex="user_approve_by"
          title="Approve By"
          render={(value: any, record: any) => {
            return <TextField value={record?.user_approve_by} />;
          }}
        />

        <Table.Column<ILeave>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {record.status === "DRAFT" && (
                <>
                  <Popconfirm
                    title="Send to approve leave ?"
                    onConfirm={() => onConfirm(record.id)}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<Icons.SendOutlined />}
                    />
                  </Popconfirm>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </>
              )}

              <ShowButton
                disabled
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
