import { Form, Input, TimePicker, Upload,message } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { API_URL, TOKEN_KEY } from "../../../../constants";
import type { UploadProps } from "antd";
import moment from "moment";

interface ICompanyProps {
  setAttenTime: (date: any) => void;
  setTimeOff: (date: any) => void;
}

export const Company: React.FC<ICompanyProps> = ({
  setAttenTime,
  setTimeOff,
}) => {
  const format = "HH:mm";

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024*1024) > 5 ? false : true
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount:1,
    accept:".png,.jpeg,.jpg",
    action:`${API_URL}/api/upload`,
    name:"files",
    headers:{ Authorization: `Bearer ${TOKEN_KEY}`,},
    listType:"picture"
  };

  const attenTime = (time: any, timeString: any) => {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setAttenTime(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  };

  const timeOff = (time: any, timeString: any) => {
    const newTime = moment(timeString, "HH:mm:ss").format("HH:mm:ss");
    setTimeOff(dayjs(newTime, "HH:mm:ss").format("HH:mm:ss.SSS"));
  };

  return (
    <>
      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList"
        getValueProps={(data) => getValueProps(data, "")}
      >
        <Upload.Dragger
          
          {...props}
        >
          <p className="ant-upload-text">Drag & drop a file in this area, File size 5 MB, File type
            .png, .jpeg, .jpg</p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        name="brand"
        label="Brand"
        valuePropName="fileList"
        getValueProps={(data) => getValueProps(data, "")}
      >
        <Upload.Dragger
          {...props}
        >
          <p className="ant-upload-text">Drag & drop a file in this area, File size 5 MB, File type
            .png, .jpeg, .jpg</p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label="Company name"
        name="company_name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company description"
        name="company_description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Time attendance"
        name="default_work_start"
        rules={[
          {
            required: true,
            message: "Please enter time attendance.",
          },
        ]}
      >
        <TimePicker
          format={format}
          onChange={(date, dateString) => attenTime(date, dateString)}
        />
      </Form.Item>
      <Form.Item
        label="Time off"
        name="default_work_stop"
        rules={[
          {
            required: true,
            message: "Please enter time off.",
          },
        ]}
      >
        <TimePicker
          format={format}
          onChange={(date, dateString) => timeOff(date, dateString)}
        />
      </Form.Item>
    </>
  );
};
