import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IPackage } from "interfaces";

export const PackageCreate = () => {

  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IPackage>({
    redirect: "list",
  });
  const { Option } = Select;
  return (
    <Create title="Create Package" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("package.name")}
                  name="package_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter package name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("package.status")}
                  name="package_status"
                  rules={[
                    {
                      required: true,
                      message: "Please enter package number .",
                    },
                  ]}
                >
                  <Select style={{ width: 250 }}>
                    <Option value="ACTIVE">ACTIVE</Option>
                    <Option value="INACTIVE">INACTIVE</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
