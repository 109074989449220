import {
  Col,
  Row,
  Show,
} from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";
import { ProfileTab } from "components/page/profile";
import { ProfileMain } from "components/page/profile/profile_main";
import { CustomTab } from "components/common/tab";
import { IEmployee } from "interfaces";
import "./css/style.css";

export const EmployeeShow1= () => {
  let empData: any;
  const { queryResult } = useShow<IEmployee>({
    metaData: {
      populate: "*",
    },
  });

  empData = queryResult.data?.data ? queryResult.data?.data : [];
  const { isLoading } = queryResult;

  return (
    <>
      <Show
        isLoading={isLoading}
        headerProps={{
          style: {
            backgroundColor: "transparent",
            padding: 0,
          },
        }}
        contentProps={{
          style: {
            backgroundColor: "transparent",
            padding: 0,
          },
        }}
        headerButtons={<></>}
      >
        <Row gutter={[8, 16]}>
          <Col style={{ paddingLeft: 0, paddingRight: 0 }} span={24}>
            <Row className="wrap-con" style={{ marginBottom: 5 }}>
              <ProfileMain empData={ empData} />
            </Row>
            <CustomTab
              items={[
                {
                  label: `profile`,
                  children: <ProfileTab empData={empData} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Show>
      
    </>
  );
};
