import {
  Col,
  Descriptions,
  Row,
  Show,
  TextField,
  Image,
  Tag,
  Divider,
} from "@pankod/refine-antd";

import { ICompany } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow } from "@pankod/refine-core";

import {
  // Row,
  // Col,
  // Divider,
  // DatePicker,
  // Space,
  // Avatar,
  // Upload,
  // Grid,
  // getValueFromEvent,
  Card,
} from "@pankod/refine-antd";
import { RiseOutlined } from "@ant-design/icons";

import dayjs from "dayjs";

import GoogleMapReact from "google-map-react";
import { MapMarker } from "components/common/map";

import { KEY_GOOGLE_MAP } from "../../constants";
import { parse } from "path";
export const BranchShow = () => {
  // const { queryResult } = useShow<ICompany>();

  const { queryResult } = useShow<ICompany>({
    metaData: {
      populate: ["company.logo"],
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;
  // const myJSON = JSON.stringify(record?.company_logo);
  // const apiUrl = useApiUrl();
  let dataCompany: any;
  dataCompany = queryResult.data?.data ? queryResult.data?.data : [];
  // console.log("xxx",record);
  // {"https://maps.google.com/?q=" + dataCompany?.lat + "," + dataCompany?.lng}
  let companyCoordinate =
    "https://maps.google.com/?q=" + dataCompany?.lat + "," + dataCompany?.lng;
  //   console.log("dataCompany", dataCompany);
  //   console.log("companyCoordinate", companyCoordinate);
  // let companyLogo = dataCompany.company_logo.url;
  // console.log("companyLogo", companyLogo);
  // const { formProps, saveButtonProps } = useForm<IUserProfile>({redirect:"list"});

  //const t = useTranslate();
  // const { selectProps: projectTypeSelectProps } = useSelect<IProjectType>({
  //     resource: "project-types",
  //     optionLabel : "projectTypeName"
  // });
  //   console.log(defaultProps);
  //console.log(projectTypeSelectProps);
  return (
    <Show title="Branch Information" isLoading={isLoading}>
      <br />

      <Row
        style={{
          backgroundImage:
            "url(https://tsxkeep.s3.ap-southeast-1.amazonaws.com/thumbnail_bg_d221b9853d.png?width=1920&height=788)",
          backgroundSize: "cover",
          borderRadius: "10px",
          marginBottom: "3%",
        }}
      >
        <Col span={12}>
          <Row gutter={16}>
            <Col span={8}>
              {dataCompany?.company?.logo !== undefined &&
              dataCompany?.company?.logo !== null ? (
                <>
                  <Image
                    src={dataCompany?.company?.logo?.url + ""}
                    width={"100%"}
                    height={"auto"}
                    style={{ objectFit: "cover", padding: "5%" }}
                    preview={false}
                  />
                </>
              ) : (
                <>
                  <div style={{ height: "10vh" }} />
                </>
              )}
            </Col>
            <Col span={16}></Col>
          </Row>
        </Col>
        <Col span={12}>
          <> {console.log(dataCompany)}</>
          <Card style={{ borderRadius: "10px", overflow: "hidden" }}>
            {dataCompany.length !== 0 && (
              <GoogleMapReact
                style={{ height: "50vh" }}
                bootstrapURLKeys={{
                  key: `${KEY_GOOGLE_MAP}`,
                }}
                defaultCenter={{
                  lat: parseFloat(dataCompany.lat),
                  lng: parseFloat(dataCompany.lng),
                }}
                defaultZoom={17}
                yesIWantToUseGoogleMapApiInternals
              >
                <MapMarker
                  lat={dataCompany?.lat !== null ? dataCompany?.lat : 13.7195}
                  lng={dataCompany?.lng !== null ? dataCompany.lng : 100.4826}
                />
              </GoogleMapReact>
            )}
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <Card style={{ borderRadius: "10px", overflow: "hidden" }}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <span style={{ verticalAlign: "center", fontSize: 16 }}>
                  <b>
                    {" "}
                    <Tag color="blue">{dataCompany?.branch_code}</Tag>{" "}
                    <>&nbsp;</>
                  </b>
                </span>
                <br />
                <span style={{ verticalAlign: "center", fontSize: 28 }}>
                  <b>
                    {" "}
                    {dataCompany?.branch_name} <>&nbsp;</>
                  </b>
                </span>
                <br />
                <span style={{ verticalAlign: "center", fontSize: 18 }}>
                  <b>
                    {" "}
                    {dataCompany?.branch_desc} <>&nbsp;</>
                  </b>
                </span>
                <br />

                {/* <div style={{marginTop: "5%"}}>
                                    <span style={{border: "1px solid #ddd",borderRadius: "5px",padding: "1%"}}>
                                        <TextField style={{fontSize: "0.83vw", fontWeight: 300,marginRight: "5%"}} value={dataCompany?.company?.company_code !== null && dataCompany?.company?.company_code !== undefined ?
                                            dataCompany.company.company_code : "ไม่ระบุ"
                                        } />

                                        <span style={{fontSize: "0.83vw", fontWeight: 700, marginTop: "3%"}}>
                                            <TextField style={{fontSize: "1vw", fontWeight: 500}} value={dataCompany?.company?.company_name !== null && dataCompany?.company?.company_name !== undefined ?
                                                dataCompany.company.company_name : "ไม่ระบุ"
                                            } />
                                        </span>
                                    </span>
                                </div> */}
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={8}>
                    <div style={{ fontSize: "0.83vw", fontWeight: 700 }}>
                      Check-in
                      <Tag
                        color="green"
                        style={{
                          fontSize: "0.83vw",
                          fontWeight: 300,
                          marginLeft: "3%",
                        }}
                      >
                        {dayjs(
                          dataCompany?.default_work_start,
                          "HH:mm:ss"
                        ).format("HH:mm")}
                      </Tag>
                    </div>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <div style={{ fontSize: "0.83vw", fontWeight: 700 }}>
                      Check-out
                      <Tag
                        color="volcano"
                        style={{
                          fontSize: "0.83vw",
                          fontWeight: 300,
                          marginLeft: "3%",
                        }}
                      >
                        {dayjs(
                          dataCompany?.default_work_stop,
                          "HH:mm:ss"
                        ).format("HH:mm")}
                      </Tag>
                    </div>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <div style={{ fontSize: "0.83vw", fontWeight: 700 }}>
                      Coordinate
                      <Tag
                        color="blue"
                        style={{
                          fontSize: "0.83vw",
                          fontWeight: 300,
                          marginLeft: "3%",
                        }}
                      >
                        <span style={{ fontSize: "0.83vw", fontWeight: 300 }}>
                          <a href={companyCoordinate} target="_blank">
                            View{" "}
                          </a>
                        </span>
                      </Tag>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          {/* <Row gutter={16} style={{ marginTop: "1%" }}>
            <Col className="gutter-row" span={24}>
              <Card style={{ borderRadius: "10px", overflow: "hidden" }}>
                <GoogleMapReact
                  style={{ height: "50vh" }}
                  bootstrapURLKeys={{
                    key: `${KEY_GOOGLE_MAP}`,
                  }}
                  // defaultCenter={{lat: defaultProps.center.lat, lng : defaultProps.center.lng}}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  // defaultZoom={defaultProps.zoom}
                  // center={{lat: vLat, lng : vLng}}
                >
                  <MapMarker
                    lat={dataCompany?.lat !== null ? dataCompany?.lat : 13.7195}
                    lng={dataCompany?.lng !== null ? dataCompany.lng : 100.4826}
                  />
                </GoogleMapReact>
              </Card>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Show>
  );
};
