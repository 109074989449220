import { Card, Col, Progress, Row } from '@pankod/refine-antd'
import { BaseCard } from 'components/common/card'
import React from 'react'
interface IData {
    name: string,
    total: string,
    hours: string,
    percen: number,
}
interface IStatistics {
    statics : IData[]
}

export const Statistics:React.FC<IStatistics> = ({statics}) => {
  return (
    <Col className="gutter-row" span={8}>
          <BaseCard
            size="small"
            title={<p style={{ fontSize: 20, fontWeight: 500 }}>Statistics</p>}
          >
            <Row gutter={[8, 30]}>
              {statics.map(({ name, total, hours, percen }, index) => (
                <Col key={index} span={24}>
                  <BaseCard size="small">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 12, marginBottom: 5 }}>{name}</p>
                      <p style={{ fontSize: 12, fontWeight: 700 }}>
                        {`${hours} / ${total}`}
                      </p>
                    </div>

                    <Progress percent={percen} size="small" />
                  </BaseCard>
                </Col>
              ))}
            </Row>
          </BaseCard>
        </Col>
  )
}