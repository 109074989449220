import {
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Row,
  Col,
  Edit,
} from "@pankod/refine-antd";
import { IBank } from "interfaces";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { useTranslate } from "@pankod/refine-core";
export const BankEdit = () => {

  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IBank>({
    redirect: "list",
  });
  return (
    <Edit
      title="Edit Bank"
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("bank.number")}
                  name="bank_number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bank number .",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("bank.name")}
                  name="bank_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bank name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

          </Col>

          <Col span={1}></Col>

          <Col span={11}>

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("bank.code")}
                  name="bank_ifsc_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bank code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("bank.no")}
                  name="bank_panNo"
                  rules={[
                    {
                      required: true,
                      message: "Please enter bank panNo.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
