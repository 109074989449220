import { Col, Create, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IContentPostType } from "interfaces";
import React from "react";

export const ContentPostTypeCreate: React.FC = () => {
  const compId = localStorage.getItem("COMPANY_ID");

  const { formProps, saveButtonProps } = useForm<IContentPostType>({
    redirect: "list",
  });
  return (
    <Create title="Create Leave" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.company = compId;
          values.type_flage = "CUSTOM";

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Content Type Name"
              name="content_type_name"
              rules={[
                {
                  required: true,
                  message: "Please enter content type name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
