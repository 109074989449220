import { Card , CardProps} from "@pankod/refine-antd";
import React from "react";

interface ICard extends CardProps {}

export const BaseCard: React.FC<ICard> = ({children , ...resProps}) => {
  return (
    <Card
      {...resProps}
      headStyle={{ fontSize: 20, fontWeight: 600, borderBottom: 0 }}
      style={{height:"100%" ,width: "100%"}}
    >
        {children}
    </Card>
  );
};
