import { IBranch, ICompany, IDepartment, IEmployee, IHoliday, IPosition, IPrefix } from "interfaces";
import { useTranslate, useApiUrl, useShow, useList } from "@pankod/refine-core";

import { 
    Create, 
    Form, 
    DatePicker,
    // eslint-disable-next-line 
    Icons, 
    Input, 
    Select, 
    useForm, 
    useSelect, 
    Edit,
    TimePicker,
    Row,
    Col,
    Upload
} from "@pankod/refine-antd";
// import { TOKEN_KEY } from "../../constants";
//import { StoreContext } from "context/store";
//import { TOKEN_KEY } from "../../constants";
import {
    useStrapiUpload,
    mediaUploadMapper,
    // eslint-disable-next-line 
    getValueProps,
} from "@pankod/refine-strapi-v4";
// eslint-disable-next-line 
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";

export const HolidayEdit = () => {
    const compID = localStorage.getItem("COMPANY_ID");

    // eslint-disable-next-line
    const API_URL = useApiUrl();
    const { form, formProps, saveButtonProps } = useForm<IHoliday>({redirect:"list"});

    // const format = 'HH:mm:mm';
    // const { Option } = Select;

    const [ count, setCount] = useState(0);
    const [ vComp, setComp ] = useState<any>();
    const [ vEmpOnComp, setEmpOnComp ] = useState<any>([]);
    const [ vAttenTime, setAttenTime ] = useState<any>();
    const [ vTimeOff, setTimeOff ] = useState<any>();
    const [ vHolidayDate, setHolidayDate ] = useState('');
    const { selectProps: companyProps } = useSelect<ICompany>({
        resource: "companies",
        optionLabel : "company_name"
    });

    // function onChangeEmp(va:any){
    //     // console.log(va)
    //     vEmpOnComp.pop();
    //     vEmpOnComp.push(va);
    //     setEmpOnComp(vEmpOnComp);
    //     console.log(vEmpOnComp);
    // };

    

    const { queryResult } = useShow<IHoliday>({metaData: {
        populate: '*'
    }, });
    const { data,isLoading } = queryResult;
    
    let recordData:any = []
    recordData = data?.data? data?.data : [];
    // console.log(recordData.holiday_date)

    useEffect(() => {
        if (count < 3) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)

            // setPosttype(recordData.content_post_types ? recordData.content_post_types : []);
            setComp(recordData.company ? recordData.company.id : '');
            // setStatus(recordData.post_status ? recordData.post_status : '');
            setHolidayDate(recordData.holiday_date ? recordData.holiday_date : null);
            form.setFieldsValue({
                // content_post_types: 
                //     recordData?.content_post_types !== undefined ?
                //         recordData.content_post_types.length > 0 ? 
                //             recordData?.content_post_types.map((v:any)=>{
                //                 // console.log(v)
                //                 return {value:v.id,label:v.type_name}
                //             }) 
                //         : form.resetFields(["content_post_types"])
                //     : form.resetFields(["content_post_types"]),
                company :
                    recordData?.company !== undefined ?
                        recordData.company.company_name
                    : <></>,
            });

            // form.resetFields(["cover_pix"]);
            // form.resetFields(["pix_list"]);

          }, 1000)
          return () => clearInterval(interval)
          
        }
    }, [count])
    

    // const UserDetail =useList<IEmployee>({ 
    //     resource: "user-profiles", 
    //     // metaData: {
    //     //     populate: ['user_profile'],
    //     // },
    //     config:{
    //         // sort: [{ order: "asc", field: "id" }],
    //         filters: [
    //             {
    //                 field   : "company][id]",
    //                 operator: "eq",
    //                 value   : vComp + ""
    //             },
    //         ]
    //     }
    // });
    // const { data: kData } = UserDetail;
    // let userData:any = []
    // userData = kData?.data? kData?.data : [];
    // console.log(userData);

    // eslint-disable-next-line 
    const { ...uploadProps } = useStrapiUpload({
        maxCount: 1,
    });

    // eslint-disable-next-line 
    const t = useTranslate();

    function onchangeCompany(value:any) {
        setComp(value);
    }

    function attenTime(time: any, timeString: any) {
        // console.log(timeString + ":00.000")
        // setAttenTime(timeString + ":00.000");
        setAttenTime(dayjs(timeString, "HH:mm:ss").format('HH:mm:ss.SSS'));
    }

    function timeOff(time: any, timeString: any) {
        // console.log(timeString + ":00.000")
        // dayjs(timeString, "HH:mm:ss").format('HH:mm')
        // setTimeOff(timeString + ":00.000");
        setTimeOff(dayjs(timeString, "HH:mm:ss").format('HH:mm:ss.SSS'));
    }

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setHolidayDate(dateString);
        // callAPI(dateString);
    };

    // console.log(vHolidayDate);

    return (
        <Create 
            title="Edit Holiday"
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    // 2022-09-06 
                    // 2022-09-30
                    values.holiday_date = vHolidayDate
                    values.company = compID
                    return formProps.onFinish?.({
                        ...mediaUploadMapper(values)
                        //,stores: store,
                    });
                }}
            >

            {/* <Row>
                <Col span={11}>
                    <Form.Item
                        label="Company"
                        name="company"
                        // name={["project_teams", "id"]}
                        rules={[
                            {
                                required: true, message: 'Please select company.'
                            },
                        ]}
                    >
                        <Select {...companyProps}
                            // mode="multiple"
                            onChange={onchangeCompany}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={11}></Col>
            </Row> */}

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item
                                    label={t("holiday.date")}
                                    name="holiday_date"
                                    rules={[
                                        {
                                            required: true, message: 'Please select holiday date.'
                                        },
                                    ]}
                                    getValueProps={(value) => ({
                                        value: value ? dayjs(value) : "",
                                    })}
                                >
                                    <DatePicker style={{width:'100%'}}
                                        onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} 
                                        // disabledDate={(current) => {
                                        //     let customDate = moment().format("YYYY-MM-DD");
                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                        // }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={18}>
                                <Form.Item
                                    label={t("holiday.name")}
                                    name="holiday_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Form>
        </Create>
    );
};