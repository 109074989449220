import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  EditButton,
  ShowButton,
  Icons,
  CreateButton,
  Form,
  Input,
  Button,
} from "@pankod/refine-antd";
import { IBank } from "interfaces";

export const BankList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IBank, HttpError, IBank>({
    metaData: {
      populate: "*",
    },
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { bank_name } = params;
      fillters.push({
        field: "bank_name",
        operator: "contains",
        value: bank_name,
      });

      return fillters;
    },
  });
  return (
    <List
      title="Bank List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
        <Form {...searchFormProps} layout="inline">
          <Form.Item name="bank_name">
            <Input placeholder="Bank Name" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Table {...tableProps} size="small">
          <Table.Column
            dataIndex="bank_name"
            title="Bank Name"
            render={(value: any, record: any) => {
              return <TextField value={record.bank_name} />;
            }}
          />

          <Table.Column
            dataIndex="bank_number"
            title="Bank Number"
            render={(value: any, record: any) => {
              return <TextField value={record.bank_number} />;
            }}
          />
          <Table.Column
            dataIndex="bank_ifsc_code"
            title="Bank Code"
            render={(value: any, record: any) => {
              return <TextField value={record.bank_ifsc_code} />;
            }}
          />
          <Table.Column
            dataIndex="bank_panNo"
            title="Bank PanNO"
            render={(value: any, record: any) => {
              return <TextField value={record.bank_panNo} />;
            }}
          />
          <Table.Column<IBank>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton
                  disabled
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
              </Space>
            )}
          />
        </Table>
    </List>
  );
};
