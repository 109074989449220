import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
} from "@pankod/refine-core";
import {
  Button,
  CreateButton,
  EditButton,
  ExportButton,
  Form,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { IPosition } from "interfaces";
import React from "react";

export const PositionList: React.FC<IResourceComponentsProps> = () => {
  const compID          = localStorage.getItem("COMPANY_ID");
  const { tableProps, searchFormProps } = useTable<
    IPosition,
    HttpError,
    IPosition
  >({
    
    metaData: {
      populate: "*",
    },
    initialFilter: [
      {
        //field       : "emp_status][id]",
        field     : "company].[id]",
        operator  : "eq",
        value     : compID,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { pos_name } = params;
      fillters.push({
        field: "pos_name",
        operator: "containss",
        value: pos_name,
      });

      return fillters;
    },
  });
  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };
  const { triggerExport: triggerExport, isLoading: exportLoading } =
  useExport<IPosition>({
    resourceName: "positions",
    sorter: [{ order: "asc", field: "id" }],
    filters: [{ field: "company].[id]", operator: "eq", value: compID }],
    exportOptions: {
      filename: `Position for KEEP`,
    },
    mapData: (item) => {
      return {
        "Position ID": item.id,
        "Position Name": item.pos_name,
      };
    },
  });
  return (
    <List
      title="Position List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
            <ExportButton
                disabled={tableProps.dataSource?.length === 0 ? true : false}
                onClick={triggerExport}
                loading={exportLoading}
              >
                Export CSV.
              </ExportButton>
          </Space>
        ),
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="pos_name">
          <Input placeholder="Position Name" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
        <Form.Item>
            <Button onClick={() => onReset()} htmlType="submit" type="dashed">
              <Icons.UndoOutlined /> Reset
            </Button>
          </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} size="small">
      <Table.Column
          dataIndex="id"
          title="#"
          render={(value, record: IPosition) => {
            return <TextField value={value} />;
          }}
        />
        <Table.Column
          dataIndex="pos_code"
          title="Position Code"
          render={(_, record: IPosition) => {
            return <TextField value={record.pos_code} />;
          }}
        />
        <Table.Column
          dataIndex="pos_name"
          title="Position Name"
          render={(_, record: IPosition) => {
            return <TextField value={record.pos_name} />;
          }}
        />
        <Table.Column
          dataIndex="pos_description"
          title="Position Description"
          render={(_, record: IPosition) => {
            return <TextField value={record.pos_description} />;
          }}
        />
        <Table.Column<IPosition>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record:IPosition) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              ></ShowButton>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
