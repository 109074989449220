import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/images/logo2.png"}
        alt="Keep Application"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 24px",
          width : "90px"
        }}
      />
    ) : (
      <img
        src={"/images/logo2.png"}
        alt="Refine"
        style={{
          width: "100px",
          padding: "12px 24px",
        }}
      />
    )}
  </Link>
);
