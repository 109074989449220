import {
  Checkbox,
  Col,
  Create,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Upload,
  UploadProps,
  message,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { useList, useShow, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ILeave, IEmployee, ILeaveType } from "interfaces";
import { useState } from "react";
import dayjs from "dayjs";

import { axiosInstance } from "authProvider";
import { TOKEN_KEY, API_URL } from "../../constants";

export const LeaveAdminCreate: React.FC = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const t = useTranslate();
  const compId = localStorage.getItem("COMPANY_ID");
  const pid = localStorage.getItem("PID");
  const token = localStorage.getItem(TOKEN_KEY);
  const format = "YYYY-MM-DD";

  const [isFileUpload, setIsFileUpload] = useState(false);
  const [dates, setDates] = useState<any>();
  const [dateLeave, setDateLeave] = useState<string | [string, string]>([
    dayjs(new Date()).format(format),
    dayjs(new Date()).format(format),
  ]);

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { formProps, saveButtonProps } = useForm<ILeave>({
    redirect: "list",
  });

  const { selectProps: leavProps } = useSelect<ILeaveType>({
    resource: "leave-types",
    optionLabel: "leave_type_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  // console.log(leavProps)

  // console.log(dayjs("2023-07-23").day())
  // console.log(dayjs().day(dayjs("2023-07-23").day()).format("dddd"));

  const UserProfile = useList<IEmployee>({
    resource: "user-profiles",
    metaData: {
      populate: "*",
    },
    config: {
      filters: [
        {
          field: "companies][id]",
          operator: "eq",
          value: compId,
        },
      ],
    },
  });

  const { data: DataUser } = UserProfile;
  let UserData: any = [];
  UserData = DataUser?.data ? DataUser?.data : [];

  const onChangeDateRange = (
    value: any,
    dateString: [string, string] | string
  ) => {
    setDateLeave(dateString);
  };

  const checkLeave = async (leave_type: string, user_profile: string) => {
    try {
      const { data: response, status } = await axiosInstance.get<any>(
        API_URL +
          `/api/leave/check?filters[company][id][$eq]=${compId}&filters[leave_type][id][$eq]=${leave_type}&filters[user_profile][id][$eq]=${user_profile}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (status === 200) {
        return response;
      }
      console.log(response);
      // return false;
    } catch (error) {
      return false;
    }
  };

  const getShiftConfig = async () => {
    try {
      const { data: response, status } = await axiosInstance.get<any>(
        API_URL + `/api/shift-configs?filters[company][id][$eq]=${compId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (status === 200) {
        return response;
      }
      console.log(response);
      // return false;
    } catch (error) {
      return false;
    }
  };
  const getCompany = async () => {
    try {
      const { data: response, status } = await axiosInstance.get<any>(
        API_URL + `/api/companies/${compId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (status === 200) {
        return response;
      }
      console.log(response);
      // return false;
    } catch (error) {
      return false;
    }
  };

  const chkWeekend = (
    weeken: any,
    day: string,
    sum: number,
    option: string,
    shift: any,
    date: string
  ) => {
    let summary_hour = sum;
    let dateString = date;
    const fnWeeken = weeken.filter((f: any) => f.label === day);

    if (fnWeeken.length !== 0) {
      summary_hour =
        summary_hour -
        (option === "Full"
          ? shift.data[0].half_afternoon + shift.data[0].half_morning
          : option === "Half Morning"
          ? shift.data[0].half_morning
          : shift.data[0].half_afternoon);
    } else {
      summary_hour = 0;
      dateString = "";
    }

    return { summary_hour, dateString };
  };

  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }

    const tooLate =
      dates[0] &&
      current.diff(dates[0], "days") >
        new Date(
          new Date(dates[0]).getFullYear(),
          new Date(dates[0]).getMonth() + 1,
          0
        ).getDate() -
          new Date(dates[0]).getDate();

    return !!tooLate;
  };

  function dateRange(startDate: string, endDate: string, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(dayjs(new Date(currentDate)).format("YYYY-MM-DD"));
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  }

  return (
    <Create title="Create Leave" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{
          leave_option: "Full",
        }}
        layout="vertical"
        onFinish={async (values: any) => {
          const chkCompany = getCompany();
          const chkLeave = checkLeave(values.leave_type, values.user_profile);
          const shiftConfig = getShiftConfig();
          const [leave, shift, company] = await Promise.all([
            chkLeave,
            shiftConfig,
            chkCompany,
          ]);

          const dates = dateRange(dateLeave[0], dateLeave[1]);
          let dataObj = {
            summary_hour:
              dates.length *
              (values.leave_option === "Full"
                ? shift.data[0].half_afternoon + shift.data[0].half_morning
                : values.leave_option === "Half Morning"
                ? shift.data[0].half_morning
                : shift.data[0].half_afternoon),
            weekend: [] as string[],
          };

          for (let index = 0; index < dates.length; index++) {
            const dayOf = dayjs().day(dayjs(dates[index]).day()).format("dddd");
            const { summary_hour, dateString } = chkWeekend(
              company.data.weekend,
              dayOf,
              dataObj.summary_hour,
              values.leave_option,
              shift,
              dates[index]
            ) ;

            if (summary_hour !== 0) dataObj.summary_hour = summary_hour;
            if (dateString !== "") dataObj.weekend.push(dates[index]);
          }
          
          if (leave.leaveRemain > dataObj.summary_hour) {
            values.start_date = dateLeave[0];
            values.end_date = dateLeave[1];
            values.user_creator = pid;
            values.company = compId;
            values.status = "APPROVE";
            values.attach_file = values.attach_file
              ? values.attach_file.file.response[0].id
              : null;
            values.dataObj = dataObj;
            
            return formProps.onFinish?.({
              ...mediaUploadMapper(values),
            });
          } else {
          }
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("leave.type")}
                  name="leave_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select leave type.",
                    },
                  ]}
                >
                  <Select
                    {...leavProps}
                    onChange={(val: any) => {
                      if (val === 1) {
                        setIsFileUpload(true);
                      } else {
                        setIsFileUpload(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("leave.employee")}
                  name="user_profile"
                  rules={[
                    {
                      required: true,
                      message: "Please select employee.",
                    },
                  ]}
                >
                  <Select>
                    {UserData !== undefined ? (
                      UserData.map((item: any, key: any) => {
                        return (
                          <Option key={key} value={item.id}>
                            {item.first_name + " " + item.last_name}
                          </Option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col className="gutter-row" span={11}>
            <Row gutter={16}>
              <Col className="gutter-row" span={20}>
                <Form.Item
                  label="date"
                  name="date"
                  getValueProps={(value) => ({
                    value: value
                      ? [dayjs(value[0]), dayjs(value[1])]
                      : [dayjs(dateLeave[0]), dayjs(dateLeave[1])],
                  })}
                >
                  <RangePicker
                    onCalendarChange={(val) => setDates(val)}
                    disabledDate={disabledDate}
                    allowClear={false}
                    onChange={onChangeDateRange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            className="gutter-row"
            style={{ display: "flex", alignItems: "end" }}
            span={3}
          >
            <Form.Item
              label=""
              name="leave_option"
              // getValueProps={(value) => ({
              //   defaultChecked: value,
              // })}
            >
              {/* <Checkbox onChange={val => setData({isLeaveFull : val.target.checked})}>ลาเต็มวัน</Checkbox> */}
              <Radio.Group>
                <Radio value={"Full"}>ลาเต็มวัน</Radio>
                <Radio value={"Half Morning"}>ลาเช้า</Radio>
                <Radio value={"Half Afternoon"}>ลาบ่าย</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("leave.reason")}
              name="leave_detail"
              rules={[
                {
                  required: true,
                  message: "Please enter leave reason.",
                },
              ]}
            >
              <TextArea
                rows={3}
                style={{
                  height: "90px",
                  maxHeight: "90px",
                  overflow: "hidden",
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {isFileUpload && (
              <Form.Item
                name="attach_file"
                label="Attach File"
                valuePropName="fileList"
                rules={[
                  {
                    required: isFileUpload,
                    message: "Please select file.",
                  },
                ]}
                getValueProps={(data) => getValueProps(data, "")}
              >
                <Upload.Dragger {...props}>
                  <p className="ant-upload-text">
                    Drag & drop a file in this area, File size 5 MB, File type
                    .png, .jpeg, .jpg
                  </p>
                </Upload.Dragger>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
