import {
  IBranch,
  IDepartment,
  IEmployee,
  IPosition,
  IPrefix,
} from "interfaces";
import { useTranslate, useApiUrl } from "@pankod/refine-core";
import { API_URL, TOKEN_KEY } from "../../constants";
import {
  Create,
  Form,
  DatePicker,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Row,
  Col,
  message,
} from "@pankod/refine-antd";
import type { UploadProps } from "antd";
import {
  useStrapiUpload,
  mediaUploadMapper,
  // eslint-disable-next-line
  getValueProps,
} from "@pankod/refine-strapi-v4";
// eslint-disable-next-line
import { useState } from "react";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";

export const EmployeeCreate = () => {
  // eslint-disable-next-line
  const t = useTranslate();
  const compID = localStorage.getItem("COMPANY_ID");
  const { formProps, saveButtonProps } = useForm<IEmployee>({
    redirect: "list",
  });
  const { Option } = Select;

  const [vPassword, setPassword] = useState("");
  const [vJoinDate, setJoinDate] = useState<any>("");

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { selectProps: deptProps } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const { selectProps: posProps } = useSelect<IPosition>({
    resource: "positions",
    optionLabel: "pos_name",
    filters: [
      {
        field: "company].[id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const { selectProps: selBranches } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "branch_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  return (
    <Create title="Create employee" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.companies = compID;
          values.flage_add = true;
          values.status= "ACTIVE";
          if (values.emp_no === undefined) values.emp_no = "";

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={3}>
            <Form.Item
              label="Prefix"
              name="prefix"
              rules={[
                {
                  required: true,
                  message: "Please select Prefix.",
                },
              ]}
            >
              <Select style={{ width: 130 }}>
                <Option value="Mr.">นาย</Option>
                <Option value="Mrs.">นาง</Option>
                <Option value="Ms.">นางสาว</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="First name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter First Name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            {" "}
            <Form.Item
              label="Last name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter Last Name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Nick name"
              name="nick_name"
              rules={[
                {
                  required: true,
                  message: "Please enter Nick name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Date of birth"
              name="date_of_birth"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Join Date.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter Email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter Password",
                },
                () => ({
                  validator(_, value){
                    if (!value || value.length >= 8 &&  value.length <= 16) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password must be at least 8-16 characters'));
                  }
                })
              ]}
            >
              <Input.Password type="Password"  placeholder="●●●●●●●●"/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Confirm password"
              name="confirmpass"
              rules={[
                { required: true, message: "please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                    return Promise.reject(
                      new Error("password does not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                type="Password"
                // minLength={8}
                // maxLength={16}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Employee ID"
              name="emp_no"
              rules={[
                {
                  required: true,
                  message: "Please enter Employee ID",
                },
              ]}

              getValueProps={(value)=> ({value : value !== undefined ? value.toUpperCase() : value})}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="phone_no"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter Phone Number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Branches"
              name="branches"
              rules={[
                {
                  required: true,
                  message: "Please select Branches.",
                },
              ]}
            >
              <Select {...selBranches} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: true,
                  message: "Please select Department",
                },
              ]}
            >
              <Select {...deptProps} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Position"
              name="position"
              rules={[
                {
                  required: true,
                  message: "Please select Postion",
                },
              ]}
            >
              <Select {...posProps} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Join Date"
              name="join_date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Join Date.",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Avatar"
          name="avatar"
          valuePropName="fileList"
          getValueProps={(data) => getValueProps(data, "")}
        >
          <Upload.Dragger style={{ borderRadius: 10 }} {...props}>
            <p className="ant-upload-text">
              {" "}
              Drag & drop a file in this area, File size 5 MB, File type .png,
              .jpeg, .jpg
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Create>
  );
};
