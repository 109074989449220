import {
  Avatar,
  Button,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Popconfirm,
  Select,
  ShowButton,
  Space,
  Table,
  TextField,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  useGetIdentity,
  useUpdate,
} from "@pankod/refine-core";
import { ILeave, ILeaveType } from "interfaces";
import moment from "moment";
export const LeaveListAdmin: React.FC = () => {
  const compID = localStorage.getItem("COMPANY_ID");
  const pID = localStorage.getItem("PID");
  const { data: user } = useGetIdentity();
  const { Option } = Select;
  const { selectProps: leavProps } = useSelect<ILeaveType>({
    resource: "leave-types",
    optionLabel: "leave_type_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });
  const { mutate, isLoading } = useUpdate<ILeave, HttpError>();
  const { tableProps, searchFormProps  } = useTable<ILeave, HttpError, ILeave>({
    resource: "leaves",
    metaData: {
      populate: [
        "user_profile.avatar",
        "user_profile",
        "leave_type",
        "user_approve_by",
      ],
    },
    initialFilter: [
      {
        //field       : "emp_status][id]",
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
      {
        //field       : "emp_status][id]",
        field: "status",
        operator: "ncontains",
        value: "DRAFT",
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const fillters: CrudFilters = [];
      const { leave_type, status } = params;
      fillters.push(
        {
          field: "leave_type][id]",
          operator: "eq",
          value: leave_type,
        },
        {
          field: "status",
          operator: "contains",
          value: status,
        }
      );

      return fillters;
    },
  });

  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  const onConfirm = (id: any, status: string) => {
    mutate(
      {
        resource: "leaves",
        id: id,
        values: { status: status, user_approve_by: pID },
      },
      {
        onError: (error, variables, context) => {
          // An error happened!
        },
        onSuccess: (data, variables, context) => {
          // Let's celebrate!
          console.log(data);
        },
      }
    );
  };

  
  return (
    <List
      title="Leave List"
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >

      <Form {...searchFormProps} layout="inline">
        <Form.Item name="leave_type">
          <Select
            placeholder="leave type"
            style={{ width: "12rem" }}
            {...leavProps}
          />
        </Form.Item>
        <Form.Item name="status">
          <Select placeholder="status" style={{ width: "12rem" }}>
            <Option value="SEND_REQUEST">SEND_REQUEST</Option>
            <Option value="APPROVE">APPROVE</Option>
            <Option value="REJECT">REJECT</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            <Icons.SearchOutlined /> Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => onReset()} htmlType="submit" type="dashed">
            <Icons.UndoOutlined /> Reset
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Table {...tableProps} loading={isLoading ? isLoading : tableProps.loading} size="small">
        <Table.Column
          dataIndex="user_profile"
          title="Employee"
          render={(value: any, record: any) => {
            //console.log(value)
            return (
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {value ? (
                  value.avatar !== null && value.avatar !== undefined ? (
                    <Avatar size={32} src={record.avatar?.url} />
                  ) : (
                    <Avatar size={32} icon={<Icons.UserOutlined />} />
                  )
                ) : (
                  <Avatar size={32} icon={<Icons.UserOutlined />} />
                )}

                <TextField
                  value={`${record.user_profile?.first_name} ${record.user_profile?.last_name}`}
                />
              </div>
            );
          }}
        />
        <Table.Column
          dataIndex="leave_detail"
          title="Leave"
          render={(value: any, record: any) => {
            return <TextField value={record?.leave_detail} />;
          }}
        />
        <Table.Column
          dataIndex="leave_type"
          title="Leave Type"
          render={(value: any, record: any) => {
            return <TextField value={record?.leave_type?.leave_type_name} />;
          }}
        />
        <Table.Column
          dataIndex="start_date"
          title="Form"
          render={(value: any, record: any) => {
            return <TextField value={record?.start_date} />;
          }}
        />
        <Table.Column
          dataIndex="end_date"
          title="To"
          render={(value: any, record: any) => {
            return <TextField value={record?.end_date} />;
          }}
        />
        <Table.Column
          dataIndex="no_of_days"
          title="No of Days"
          render={(value: any, record: any) => {
            return <TextField value={"-"} />;
          }}
        />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value: any, record: any) => {
            return <TextField value={record?.status} />;
          }}
        />
        <Table.Column
          dataIndex="user_approve_by"
          title="Approve By"
          render={(value: any, record: any) => {
            return <TextField value={record.user_approve_by?.first_name ?? ""} />;
          }}
        />
        <Table.Column<ILeave>
          width={50}
          title="Actions"
          align="center"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton
                disabled
                hideText
                size="small"
                recordItemId={record.id}
              />
              {record?.status === "SEND_REQUEST" && (
                <>
                  <Popconfirm
                    title="Approve leave ?"
                    onConfirm={() => onConfirm(record.id, "APPROVE")}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<Icons.CheckOutlined />}
                    />
                  </Popconfirm>
                  <Popconfirm
                    title="Reject leave ?"
                    onConfirm={() => onConfirm(record.id, "REJECT")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      size="small"
                      type="primary"
                      danger
                      icon={<Icons.CloseOutlined />}
                    />
                  </Popconfirm>
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
