import {
  IBranch,
  IDepartment,
  IEmployee,
  IPosition,
  IPrefix,
} from "interfaces";
import { useTranslate, useApiUrl, useShow } from "@pankod/refine-core";

import {
  Create,
  Descriptions,
  Form,
  DatePicker,
  // eslint-disable-next-line
  Icons,
  Input,
  Select,
  useForm,
  useSelect,
  Edit,
  Upload,
  UploadFile,
} from "@pankod/refine-antd";

//import { StoreContext } from "context/store";
// import { TOKEN_KEY } from "../../constants";
//import { API_URL ,TOKEN_KEY } from "../../constants";
import {
  useStrapiUpload,
  mediaUploadMapper,
  // eslint-disable-next-line
  getValueProps,
} from "@pankod/refine-strapi-v4";
// eslint-disable-next-line
import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line
// import moment from "moment";
import dayjs from "dayjs";
import { TOKEN_KEY } from "../../constants";

export const EmployeeEdit = () => {
  // eslint-disable-next-line
  const API_URL = useApiUrl();
  const { Option } = Select;
  //const [store, setStore] = useContext(StoreContext);
  const compID = localStorage.getItem("COMPANY_ID");
  const { form, formProps, saveButtonProps } = useForm<IEmployee>({
    redirect: "list",
  });

  // eslint-disable-next-line
  const t = useTranslate();

  const [count, setCount] = useState(0);
  const [vImg2, setImg2] = useState<any>([]); // IMG Avatar
  const { Dragger } = Upload;

  const { selectProps: deptProps } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const { selectProps: posProps } = useSelect<IPosition>({
    resource: "positions",
    optionLabel: "pos_name",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const { selectProps: selBranches } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "branch_name",
    optionValue: "id",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compID,
      },
    ],
  });

  const { queryResult } = useShow<IEmployee>({
    metaData: {
      // populate: '*'
      populate: [
        "avatar",
        "branches",
        "company",
        "date_of_birth",
        "emp_no",
        "user_permission",
        "department",
        "position",
      ],
    },
  });

  const { data, isLoading } = queryResult;
  let recordData: any = [];
  recordData = data?.data ? data?.data : [];

  function fileChange(e: any) {
    if (e.file.response) {
      let res = e.file.response;
    }
  }

  useEffect(() => {
    if (count < 2) {
      const interval = setInterval(() => {
        setCount((prev) => prev + 1);

        // setConfDraftPub(recordData.isConfirmed ? recordData.isConfirmed : false);
        // setStaffHasProp(
        //     recordData.staff_has_properties !== undefined ?
        //             recordData.staff_has_properties.length > 0 ? recordData.staff_has_properties[0].id : '-'
        //         : '-');
        // setDate1(recordData.appointmentDate  ? recordData.appointmentDate : '-');

        form.setFieldsValue({
          department: recordData?.department
            ? recordData.department.dept_name
            : "-",
          position: recordData?.position
            ? recordData?.position !== undefined
              ? recordData.position.pos_name
              : null
            : null,
          branches: recordData?.branches
            ? recordData.branches[0].branch_name
            : "-",
        });

        // IMAGE Avatar
        const fileList2: UploadFile[] =
          recordData.avatar !== undefined
            ? recordData.avatar
              ? [
                  {
                    uid: recordData.avatar.id,
                    name: recordData.avatar.name,
                    status: "done",
                    url: recordData.avatar.url,
                    thumbUrl: recordData.avatar.url,
                  },
                ]
              : []
            : [];
        setImg2({
          name: "files",
          multiple: true,
          defaultFileList: [...fileList2],
        });
        formProps.form?.resetFields(["avatar"]);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  return (
    <Edit
      isLoading={isLoading}
      title="Edit employee"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          // console.log(values)
          values.company = compID;
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
            //,stores: store,
          });
        }}
      >
        <Descriptions
          size="small"
          // title={<Tag color="blue"> test </Tag>}
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
          bordered
        >
          <Descriptions.Item label="Prefix">
            <Form.Item
              // name={["user_prefix","id"]}
              name="prefix"
              rules={[
                {
                  required: true,
                  message: "Please select Prefix.",
                },
              ]}
            >
              {/* <Select style={{width:130}} {...prefixProps} /> */}
              <Select style={{ width: 130 }}>
                <Option value="Mr.">นาย</Option>
                <Option value="Mrs.">นาง</Option>
                <Option value="Ms.">นางสาว</Option>
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="First name">
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please select First Name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            // span={1}
            label="Last name"
          >
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please select Last Name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Nick name">
            <Form.Item
              name="nick_name"
              rules={[
                {
                  required: true,
                  message: "Please enter Nick name.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Date of birth">
            <Form.Item
              name="date_of_birth"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Join Date.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            // span={2}
            label="Email"
          >
            <Form.Item
              name="email"
              // rules={[
              //     {
              //         required: true, message: 'Please select Email'
              //     },
              // ]}
            >
              <Input disabled />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            // span={3}
            label="Password"
          >
            <Form.Item
              name="password"
              // rules={[
              //     {
              //         required: true, message: 'Please input Password'
              //     },
              // ]}
            >
              <Input type="Password" disabled />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item label="Employee ID">
            <Form.Item
              name="emp_no"
              rules={[
                {
                  required: true,
                  message: "Please input Employee ID",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item label="Phone">
            <Form.Item
              name="phone_no"
              rules={[
                {
                  required: true,
                  message: "Please input Phone",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Descriptions.Item>


          <Descriptions.Item label="Branches">
            <Form.Item
              name="branches"
              rules={[
                {
                  required: true,
                  message: "Please select Branches",
                },
              ]}
            >
              <Select {...selBranches} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Department">
            <Form.Item
              // name={["department","id"]}
              name="department"
              rules={[
                {
                  required: true,
                  message: "Please select Department",
                },
              ]}
            >
              <Select {...deptProps} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Position">
            <Form.Item
              // name={["position","id"]}
              name="position"
              rules={[
                {
                  required: true,
                  message: "Please select Postion",
                },
              ]}
            >
              <Select {...posProps} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Join Date">
            <Form.Item
              name="join_date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Join Date.",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Descriptions.Item>


          <Descriptions.Item label="Avatar">
            <Form.Item name="avatar">
              <Dragger
                style={{ borderRadius: "10px" }}
                {...vImg2}
                action={`${API_URL}/upload`}
                headers={{
                  Authorization: `Bearer ${TOKEN_KEY}`,
                }}
                listType="picture"
                maxCount={1}
                onChange={fileChange}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Dragger>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </Edit>
  );
};
