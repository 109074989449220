import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useCreate,
  useCreateMany,
  useExport,
  useUpdate,
} from "@pankod/refine-core";

import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  ShowButton,
  Icons,
  CreateButton,
  Form,
  Radio,
  Avatar,
  Input,
  Button,
  ExportButton,
  EditButton,
  Switch,
} from "@pankod/refine-antd";

import { IBranch, IDepartment, IEmployee, IPosition } from "interfaces";
import { CsvBaseUpload } from "components/common/upload";
import { useEffect, useState } from "react";
import moment from "moment";

export const EmployeeList: React.FC<IResourceComponentsProps> = () => {
  const [dataCsv, setDataCsv] = useState<any>([]);
  const { mutate } = useCreate<IEmployee>();
  const { mutate: updateUser } = useUpdate<IEmployee>();
  const compID = localStorage.getItem("COMPANY_ID");

  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
  } = useTable<IEmployee, HttpError, IEmployee>({
    metaData: {
      populate: [
        "avatar",
        "branches",
        "companies",
        "user_permission",
        "position",
        "department",
      ],
    },
    initialFilter: [
      {
        field: "companies].[id]",
        operator: "eq",
        value: compID,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { status, emp_no, first_name } = params;
      filters.push(
        {
          field: "emp_no",
          operator: "containss",
          value: emp_no,
        },
        {
          field: "first_name",
          operator: "containss",
          value: first_name,
        },
        {
          field: "status",
          operator: "eq",
          value: status,
        }
      );

      return filters;
    },
  });

  const { triggerExport, isLoading: exportLoading } = useExport<IEmployee>({
    metaData: {
      populate: [
        "avatar",
        "branches",
        "companies",
        "user_permission",
        "position",
        "department",
      ],
    },
    sorter: [{ order: "asc", field: "id" }],
    filters: [
      { field: "companies].[id]", operator: "eq", value: compID },
      { field: "emp_no", operator: "ne", value: "EN0001" },
    ],
    exportOptions: {
      filename: `${localStorage.getItem("COMPANY_NAME")} Employee for KEEP`,
    },
    maxItemCount: 1,
    mapData: (item) => {
      return {
        No: 1,
        "Employee ID": "xxxxxxxx",
        Prefix: "Mr",
        "First name": "xxx",
        "Last name": "xxx",
        "Nick name": "xxx",
        "E-mail": "e@mail.com",
        Phone: "0800000000",
        Gender: "M",
        "Date of birth": "วัน/เดือน/ปี:01/12/1994",
        "Join Date": "วัน/เดือน/ปี:01/12/1994",
        Department: item.department?.id ?? "",
        Position: item.position?.id ?? "",
        Branches: item.branches[0]?.id,
      };
    },
  });

  const onReset = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  function onStatusChange() {
    searchFormProps.form?.submit();
  }

  useEffect(() => {
    if (dataCsv.length !== 0) {
      let csv = dataCsv.map((val: any, inx: number) => ({
        prefix: val.prefix,
        first_name: val.first_name,
        last_name: val.last_name,
        nick_name: val.nick_name ?? "",
        date_of_birth:
          val.date_of_birth !== null && val.date_of_birth !== undefined && val.date_of_birth !== ""
            ? moment(
                Date.parse(
                  `${val.date_of_birth.split("/")[2]}-${
                    val.date_of_birth.split("/")[1]
                  }-${val.date_of_birth.split("/")[0]}`
                )
              ).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
        email: val.e_mail !== '' && val.e_mail !== null && val.e_mail !== undefined ? val.e_mail :  'e@mail.com',
        password: "As@123456789",
        confirmpass: "As@123456789",
        emp_no: val.employee_id,
        phone_no: val.phone,
        branches: parseInt(val.branches),
        department: parseInt(val.department),
        position: parseInt(val.position),
        join_date:  val.join_date !== null && val.join_date !== undefined && val.join_date !== ""
          ? moment(
              Date.parse(
                `${val.join_date.split("/")[2]}-${
                  val.join_date.split("/")[1]
                }-${val.join_date.split("/")[0]}`
              )
            ).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
        companies: compID,
        flage_add: true,
        status: "ACTIVE",
      }));

      if (csv.length !== 0)
  
        mutate(
          {
            resource: "user-profile/imp-user",
            values: {
              data : [...csv] ,
              company : compID
            },
          },
          {
            onError: (error, variables, context) => {
              // An error happened!
              csv = [];
              setDataCsv([]);
              console.log(error);
            },
            onSuccess: (data, variables, context) => {
              // Let's celebrate!
              csv = [];
              setDataCsv([]);
              refetch();
            },
          }
        );
    }
  }, [dataCsv]);

  const onChangeMutiBranch = (checked: boolean, record: IEmployee) => {

    updateUser(
      {
        resource: "user-profiles",
        id: record.id,
        values: {
          use_multi_time_slot: checked,
        },
      },
      {
        onError: (error, variables, context) => {
          console.log(error);
        },
        onSuccess: (data, variables, context) => {},
      }
    );
  };

  return (
    <>
      <List
        title="Employee List"
        pageHeaderProps={{
          extra: (
            <Space>
              <CreateButton />
              <CsvBaseUpload setDataCsv={setDataCsv} />
              <ExportButton
                onClick={triggerExport}
                loading={exportLoading}
                icon={<Icons.DownloadOutlined />}
              >
                Download Template
              </ExportButton>
            </Space>
          ),
        }}
      >
        <Form {...searchFormProps} layout="inline">
          <Form.Item name="emp_no">
            <Input placeholder="Employee ID" />
          </Form.Item>
          <Form.Item name="first_name">
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="status">
            <Radio.Group onChange={onStatusChange}>
              <Radio.Button value={"ACTIVE"}>ACTIVE</Radio.Button>
              <Radio.Button value={"INACTIVE"}>IN-ACTIVE</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              <Icons.SearchOutlined /> Search
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={() => onReset()} htmlType="submit" type="dashed">
              <Icons.UndoOutlined /> Reset
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Table
          {...tableProps}
          loading={tableProps.loading}
          size="small"
          rowKey={(val) => val.id}
        >
          <Table.Column
            align="center"
            width={35}
            dataIndex="line_pictureUrl"
            title="Avatar"
            render={(value: any, record: any) => {
              return (
                <>
                  {value ? (
                    <Avatar size={32} src={value} />
                  ) : (
                    <>
                      <Avatar size={32} icon={<Icons.UserOutlined />} />
                    </>
                  )}
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="first_name"
            title="Name"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField
                    value={record.first_name + " " + record.last_name}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="nick_name"
            title="Nick name"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="emp_no"
            title="Employee ID"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="user_permission"
            title="Email"
            render={(value: any, record: any) => {
              // console.log(record)
              return (
                <>
                  <TextField value={value?.email ?? ""} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="phone_no"
            title="Phone"
            render={(value: any, record: any) => {
              // console.log(record)
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="position"
            title="Position"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value ? value.pos_name : ""} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="department"
            title="Department"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value ? value.dept_name : ""} />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex="join_date"
            title="Join Date"
            render={(value: any, record: any) => {
              return (
                <>
                  <TextField value={value} />
                </>
              );
            }}
          />

          <Table.Column
            dataIndex="use_multi_time_slot"
            title="Multi Branch"
            align="center"
            render={(value: any, record: any) => {
              return (
                <>
                  <Switch
                    size="small"
                    defaultChecked={value}
                    onChange={(e) => onChangeMutiBranch(e, record)}
                  />
                </>
              );
            }}
          />

          <Table.Column<IEmployee>
            width={50}
            title="Actions"
            align="center"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
                <ShowButton hideText size="small" recordItemId={record.id}>
                  รายละเอียด
                </ShowButton>
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
