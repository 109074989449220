import { List, Row } from "@pankod/refine-antd";
import type { ColumnsType } from "antd/es/table";
import {
  Statistics,
  TableLogUser,
  TimesSheet,
  TodayActivity,
} from "../../components/page/attendance/user";

interface DataType {
  id: string;
  date: string;
  punch_in: string;
  punch_out: string;
  production: string;
  break: string;
  overtime: string;
}
const dataSource: DataType[] = [
  {
    id: "1",
    date: "19 Feb 2019",
    punch_in: "10 AM",
    punch_out: "7 PM",
    production: "9 hrs",
    break: "1 hrs",
    overtime: "0",
  },
];
const columns: ColumnsType<DataType> = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Punch In",
    dataIndex: "punch_in",
    key: "punch_in",
  },
  {
    title: "Punch Out",
    key: "punch_out",
    dataIndex: "punch_out",
  },
  {
    title: "Production",
    key: "production",
    dataIndex: "production",
  },
  {
    title: "Break",
    key: "break",
    dataIndex: "break",
  },
  {
    title: "Overtime",
    key: "overtime",
    dataIndex: "overtime",
  },
];
const dataAttenDamce = {
  timesheet: {
    date: "11 Mar 2019",
    full_date: "Wed, 11th Mar 2019 10.00 AM",
    hours: "3.45",
    break: "1.21",
    overtime: "3",
  },
  statics: [
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
    {
      name: "Today",
      total: "8",
      hours: "3.45",
      percen: 30,
    },
  ],
  today: [
    {
      activity: "Punch In at",
      time: "10.00 AM.",
    },
    {
      activity: "Punch Out at",
      time: "11.00 AM.",
    },
    {
      activity: "Punch In at",
      time: "11.15 AM.",
    },
    {
      activity: "Punch Out at",
      time: "1.30 PM.",
    },
    {
      activity: "Punch In at",
      time: "2.00 PM.",
    },
    {
      activity: "Punch Out at",
      time: "7.30 PM.",
    },
  ],
};

export const AttendancUser = () => {
  return (
    <List title="Attendance">
      <Row gutter={[26, 16]}>
        <TimesSheet timesheet={dataAttenDamce.timesheet} />
        <Statistics statics={dataAttenDamce.statics} />
        <TodayActivity today={dataAttenDamce.today} />
        <TableLogUser dataSource={dataSource} columns={columns}/>
      </Row>
    </List>
  );
};
