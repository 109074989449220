import {
  IBranch,
  IContentPost,
  IContentPostType,
  IDepartment,
  IPosition,
} from "interfaces";
import dayjs from "dayjs";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Row,
  Col,
  UploadProps,
  message,
  Upload,
  UploadFile,
  Checkbox,
  RefreshButton,
  Edit,
  Pagination,

} from "@pankod/refine-antd";
import { DatePicker } from "antd";
// import { TOKEN_KEY } from "../../constants";
import type { RangePickerProps } from "antd/es/date-picker";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, API_URL } from "../../constants";
import { useEffect, useRef } from "react";

export const ContentpostEdit: React.FC = () => {
  const { TextArea } = Input;

  const compId = localStorage.getItem("COMPANY_ID");
  const usrId = localStorage.getItem("USER_ID");

  // const efRender = useRef(false);

  const { formProps, saveButtonProps, formLoading } = useForm<IContentPost>({
    redirect: "list",
    metaData: {
      populate: [
        "post_images",
        "content_post_types",
        "branches",
        "departments",
        "positions",
        "company",
      ],
    },
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: { Authorization: `Bearer ${TOKEN_KEY}` },
    listType: "picture",
  };

  const { selectProps: postTypeProps } = useSelect<IContentPostType>({
    resource: "content-post-types",
    optionLabel: "content_type_name",
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: branchesProps } = useSelect<IBranch>({
    resource: "branches",
    optionLabel: "branch_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: departmentProps } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "dept_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const { selectProps: positionProps } = useSelect<IPosition>({
    resource: "positions",
    optionLabel: "pos_name",
    fetchSize: 100,
    filters: [
      {
        field: "company][id]",
        operator: "eq",
        value: compId,
      },
    ],
  });

  const UpLoadImge = (prop: any) => {
    const { fileList, onChange } = prop;
    const fileLists: UploadFile[] =
      fileList !== undefined && fileList && fileList[0]
        ? [
            {
              uid: fileList[0].id.toString(),
              name: fileList[0].name,
              status: "done",
              url: fileList[0].url,
            },
          ]
        : [];

    return (
      <Upload.Dragger
        {...props}
        onChange={onChange}
        defaultFileList={fileLists}
      >
        <p className="ant-upload-text">
          Drag & drop a file in this area, File size 5 MB, File type .png,
          .jpeg, .jpg
        </p>
      </Upload.Dragger>
    );
  };

  const selectAll = (e: any, value: string, options: any) =>
    e.target.checked
      ? formProps.form?.setFieldsValue({
          [value]: options.map((val: any) => val.value),
        })
      : formProps.form?.setFieldsValue({ [value]: [] });

      const disabledDate: RangePickerProps["disabledDate"] = (current: any) =>
      dayjs().add(-1, "days") >= current || dayjs().add(1, "month") <= current;

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={() => <RefreshButton />}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
          content_post_types: formProps.initialValues?.content_post_types.map(
            (val: any) => val.id
          ),
          branches: formProps.initialValues?.branches.map((val: any) => val.id),
          departments: formProps.initialValues?.departments.map(
            (val: any) => val.id
          ),
          positions: formProps.initialValues?.positions.map(
            (val: any) => val.id
          ),
          // all_branch: false,
          // all_department: false,
          // all_position: false
        }}
        layout="vertical"
        onFinish={(values: any) => {
          values.company = compId;
          values.user_creator = usrId;
          values.post_date = dayjs(new Date()).format("YYYY-MM-DD");
          // values.post_images = values.post_images
          //   ? values.post_images.file?.response[0].id ?? values.attach_file.id
          //   : null;
      
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Post type"
              name="content_post_types"
              rules={[
                {
                  required: true,
                  message: "Please select post type.",
                },
              ]}
            >
              <Select {...postTypeProps} mode="multiple" />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Branch"
              name="branches"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select branch.",
              //   },
              // ]}
            >
              <Select
                {...branchesProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item
              label="All"
              name="all_branch"
              getValueProps={(value) => ({
                defaultChecked: value,
              })}
            >
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "branches", branchesProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Department"
              name="departments"
              rules={[
                {
                  required: true,
                  message: "Please select department.",
                },
              ]}
            >
              <Select
                {...departmentProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item
              label="All"
              name="all_department"
              getValueProps={(value) => ({
                defaultChecked: value,
              })}
            >
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "departments", departmentProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Position"
              name="positions"
              rules={[
                {
                  required: true,
                  message: "Please select position.",
                },
              ]}
            >
              <Select
                {...positionProps}
                mode="multiple"
                maxTagCount="responsive"
                // onChange={onChangePosttype}
              />
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: 15 }} span={1}>
            <Form.Item
              label="All"
              name="all_position"
              getValueProps={(value) => ({
                defaultChecked: value,
              })}
            >
              <Checkbox
                onChange={(e) =>
                  selectAll(e, "positions", positionProps.options)
                }
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Post status" name="post_status">
              <Select
                options={[
                  {
                    label: "ACTIVE",
                    value: "ACTIVE",
                  },
                  {
                    label: "INACTIVE",
                    value: "INACTIVE",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Send message date"
              name="send_msg_date"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: "Please select Send message date!",
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate}/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item
              label="Title"
              name="post_title"
              rules={[
                {
                  required: true,
                  message: "Please enter title.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              label="Sub title"
              name="post_sub_title"
              rules={[
                {
                  required: true,
                  message: "Please enter sub title.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item
              label="Content"
              name="post_content"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item
              name="post_images"
              label="Images"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select file.",
              //   },
              // ]}
              valuePropName="fileList"
            >
              <UpLoadImge />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

// ContentpostEdit
