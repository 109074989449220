import {
  Avatar,
  Col,
  DatePicker,
  Form,
  Icons,
  Input,
  Modal,
  Row,
  Select,
  useModalForm,
} from "@pankod/refine-antd";
import { CustomCard, DescriptionCard } from "components/common/card";
import { IEmployee } from "interfaces";
import { columDesc } from "../../../pages/employee/const/colum_card";
import dayjs from "dayjs";

const AvatarCard = ({ avatar_url }: { avatar_url: string }) => (
  <Avatar size={120} icon={<Icons.UserOutlined size={50} />} />
);
export const ProfileMain = ({ empData }: { empData: any }) => {
  const { Option } = Select;

  const {
    modalProps,
    formProps,
    show: editModalShow,
  } = useModalForm<IEmployee>({
    action: "edit",
    redirect: "show",
    metaData: {
      populate: "*",
    },
  });

  let dataEmps:any

  if(empData.length !== 0) {
    dataEmps = {
      ...empData,
      phone_no: empData.phone_no,
      email: empData?.user_permission?.email,
      date_of_birth: empData.date_of_birth,
      address: "-",
      gender: empData.prefix === "Mr." ? "M" : "F",
    };
  }

  const upDateUser = (value:IEmployee) => {

  }

  return (
    <>
      <Col className="profile-con left-con" span={12}>
        <CustomCard
          isEdit={false}
          avatar={<AvatarCard avatar_url={dataEmps?.url} />}
          title={
            <div className="profile-desc">
              <h2>{`${dataEmps?.first_name} ${dataEmps?.last_name}`}</h2>
              <h5>Department: {dataEmps?.department?.dept_name ?? "-"}</h5>
              <h5>position: {dataEmps?.position?.pos_name ?? "-"}</h5>
            </div>
          }
          description={
            <>
              <div className="profile-no">
                {`Employee ID : ${dataEmps?.emp_no}`}
              </div>
              <div className="profile-join">{`Date of Join : ${
                dataEmps?.join_date != null ? dataEmps?.join_date : ""
              }`}</div>
            </>
          }
        />
      </Col>
      <Col className="profile-con rigth-con" span={12}>
        <CustomCard
          onEdit={() => editModalShow(empData.id)}
          isEdit={true}
          description={<DescriptionCard profile={dataEmps} colum={columDesc} />}
        />
      </Col>
      <Modal
        {...modalProps}
        width={800}
        bodyStyle={{ paddingTop: 16 }}
        title="Profile Information"
        className="modeal-custom"
      >
        <Form {...formProps} onFinish={(values: any) => {
          console.log(values)
            // values.company = comId;
            // values.user_creator = userId
            // return formCreate.onFinish?.({
            //   ...mediaUploadMapper(values),
            // });
          }} layout="vertical">
          <Row gutter={16}>
            <Col span={3}>
              <Form.Item
                label="Prefix"
                name="prefix"
                rules={[
                  {
                    required: true,
                    message: "Please select Prefix.",
                  },
                ]}
              >
                <Select >
                  <Option value="Mr.">นาย</Option>
                  <Option value="Mrs.">นาง</Option>
                  <Option value="Ms.">นางสาว</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="First name" name="first_name"  rules={[
                {
                  required: true,
                  message: "Please enter First Name.",
                },
              ]}>
                <Input className="input" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Last name" name="last_name" rules={[
                {
                  required: true,
                  message: "Please enter Last Name.",
                },
              ]}>
                <Input className="input" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Nick name" name="nick_name" >
                <Input className="input" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Date of birth"
                name="date_of_birth"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: "Please enter Join Date.",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item label="Email" name={['user_permission' , 'email']} rules={[
                {
                  required: true,
                  message: "Please enter email.",
                },
              ]}>
                <Input className="input"  />
              </Form.Item>
            </Col>
            
          </Row>
        </Form>
      </Modal>
    </>
  );
};
