import { IBranch, IEmployee } from "interfaces";
import { useTranslate, useApiUrl, useShow, useList } from "@pankod/refine-core";

import {
  Form,
  Input,
  useForm,
  Edit,
  Row,
  Col,
  message,
  UploadFile,
} from "@pankod/refine-antd";
import { TimePicker, Upload } from "antd";

import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
import moment from "moment";
import { useEffect, useState } from "react";
import { KEY_GOOGLE_MAP, TOKEN_KEY, API_URL } from "../../constants";

import type { UploadProps } from "antd";
export const BranchEdit = () => {
 
  const { form, formProps, saveButtonProps } = useForm<IBranch>({
    redirect: "list",
  });
  const { Dragger } = Upload;
  let props: UploadProps = {
    beforeUpload: (file) => {
      const validateSize = file.size / (1024 * 1024) > 5 ? false : true;
      if (!validateSize) {
        message.error(`file size is over 5mb.`);
      }
      return validateSize || Upload.LIST_IGNORE;
    },
    maxCount: 1,
    accept: ".png,.jpeg,.jpg",
    action: `${API_URL}/api/upload`,
    name: "files",
    headers: {
      Authorization: `Bearer ${TOKEN_KEY}`,
    },
    listType: "picture",
 
  };

  const { queryResult } = useShow<IBranch>({
    metaData: {
      populate: "*",
    },
  });

  let recordData: any;
  recordData = queryResult.data?.data ? queryResult.data?.data : [];

  const compID = localStorage.getItem("COMPANY_ID");
  const t = useTranslate();

  const [count, setCount] = useState(0);
  const [vTimeIn, setTimeIn] = useState<any>();
  const [vTimeOff, setTimeOff] = useState<any>();
  const [logoBranch, setLogoBranch] = useState<any>([]);

  useEffect(() => {
    if (count < 2) {
      const interval = setInterval(() => {
        setCount((prev) => prev + 1);

        setTimeIn(recordData ? recordData.default_work_start : "");
        setTimeOff(recordData ? recordData.default_work_stop : "");
        const fileList:UploadFile[] = recordData?.branch_logo
          ? [
              {
                uid: recordData.branch_logo.id.toString(),
                name: recordData.branch_logo.name,
                status: "done",
                url: recordData.branch_logo.url,
                thumbUrl: recordData.branch_logo.url,
              },
            ]
          : [];

        setLogoBranch({
          defaultFileList: [...fileList],
        });

        form.resetFields(["branch_logo"]);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  function opTime(time: any, timeString: any) {
    setTimeIn(timeString);
  }

  function clTime(time: any, timeString: any) {
    setTimeOff(timeString);
  }

  const CustomTimePicker = (props: any) => {
    const { onChange, value } = props;
    const time = value ?? "00:00:00";
    return (
      <TimePicker
        // allowClear={false}
        format={"HH:mm"}
        defaultValue={moment(time, "HH:mm")}
        onChange={onChange}
      />
    );
  };

  return (
    <Edit title="Edit Branch" saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          values.company = compID;
          values.default_work_start = vTimeIn;
          values.default_work_stop = vTimeOff;

          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
            //,stores: store,
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.code")}
                  name="branch_code"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter branch code.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={t("branch.name")}
                  name="branch_name"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter branch name.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("branch.ltt")}
                  name="lat"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter company coordinate.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("branch.lgt")}
                  name="lng"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter company coordinate.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.ta")}
                  name="default_work_start"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter time attendance.",
                    },
                  ]}
                >
                  {/* <TimePicker
                    style={{ width: "100%" }}
                    value={moment(
                      recordData ? vTimeIn : "00:00:00",
                      "HH:mm:ss"
                    )}
                    onChange={(date, dateString) => opTime(date, dateString)}
                  /> */}
                  <CustomTimePicker/>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={t("branch.to")}
                  name="default_work_stop"
                  rules={[
                    {
                      required: true,
                      // max: 3,
                      message: "Please enter time off.",
                    },
                  ]}
                >
                  {/* <TimePicker
                    style={{ width: "100%" }}
                    value={moment(
                      recordData ? vTimeOff : "00:00:00",
                      "HH:mm:ss"
                    )}
                    onChange={(date, dateString) => clTime(date, dateString)}
                  /> */}
                  <CustomTimePicker/>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={12}>
                <Form.Item
                  label={t("branch.cinr")}
                  name="check_redius"
                  rules={[
                    {
                      required: true,
                      message: "Please select branch.",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col className="gutter-row" span={11}>
         
            <Form.Item
              label={t("branch.logo")}
              name="branch_logo"
            >
            
              <Upload.Dragger
                style={{ borderRadius: 10 }}
                {...props}
               
                {...logoBranch}
              >
                
                <p className="ant-upload-text">
                  Drag & drop a file in this area, File size 5 MB, File type
                  .png, .jpeg, .jpg
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
