import {
  AntdLayout,
  AntdList,
  Breadcrumb,
  CreateButton,
  Form,
  List,
  Space,
  Tabs,
  useSimpleList,
} from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps } from "@pankod/refine-core";

import CompanyCard from "components/common/company_card/company_card";
import { ICompany } from "interfaces";
import { Header } from "components/layout/header";
import { useEffect } from "react";
export interface ILoginForm {
  username: string;
  password: string;
}
export const Company2List: React.FC<IResourceComponentsProps> = () => {
  const uId = localStorage.getItem("USER_ID");

  const { listProps, searchFormProps , queryResult : {refetch}  } = useSimpleList<ICompany, HttpError>({
    resource: "companies",
    metaData: {
      populate: ["*" , "user_profiles" , "user_profiles.department"],
    },
    initialFilter: [
      {
        field: "user_profiles][id]",
        operator: "eq",
        value: uId,
      },
    ]
  });

  const renderItem = (comapny_data: ICompany) => (
    <CompanyCard item={comapny_data} />
  );

  useEffect(() => {
    const compId = localStorage.getItem("COMPANY_ID");
    if (compId) {
      localStorage.removeItem("COMPANY_ID");
      localStorage.removeItem("COMPANY_NAME");
      localStorage.removeItem("COMPANY");
      // localStorage.removeItem("Default_VALUE");

    }
  }, []);

  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      <AntdLayout>
        <Header border={true} />
        <AntdLayout.Content>
          <Form {...searchFormProps}>
            <Form.Item>
              <div
                style={{
                  padding: "0 50px",
                  width: "1080px",
                  margin: "0 auto",
                }}
              >
                <List
                  title="Company list"
                  pageHeaderProps={{
                    breadcrumb: (
                      <Breadcrumb breadcrumbProps={{ separator: "/" }} />
                    ),
                    extra: (
                      <Space>
                        <CreateButton />
                      </Space>
                    ),
                  }}
                >
                  <AntdList
                    grid={{ gutter: 16, column: 4 }}
                    {...listProps}
                    renderItem={renderItem}
                  />
                </List>
              </div>
            </Form.Item>
          </Form>
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
};
