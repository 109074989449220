import { Col, Descriptions, Form, Row, Show } from "@pankod/refine-antd";
import { useShow } from "@pankod/refine-core";
import { IPosition } from "interfaces";

export const PositionShow = () => {
  const { queryResult } = useShow<IPosition>({
    metaData: {
      populate: "*",
    },
  });

  const { isLoading } = queryResult;
  let recordPosition: any;
  recordPosition = queryResult.data?.data ? queryResult.data?.data : [];

  return (
    <Show title="Position Information" isLoading={isLoading}>
      <Descriptions title="" bordered>
        <Descriptions.Item label="Position code">{`- ${recordPosition.pos_code}`}</Descriptions.Item>
        <Descriptions.Item label="Position name">{`- ${recordPosition.pos_name}`}</Descriptions.Item>
      </Descriptions>

      <Descriptions title="" bordered style={{marginTop: "1%"}}>
        <Descriptions.Item label="Position description">{`- ${recordPosition.pos_description}`}</Descriptions.Item>
      </Descriptions>



      {/* <Form layout="vertical">
        <Row>
          <Col span={11}>
            <Form.Item label="Position code" name="pos_code">
              <p
                style={{ marginBottom: 0, fontWeight: 600 }}
              >{`- ${recordPosition.pos_code}`}</p>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Form.Item label="Position name" name="pos_name">
              <p
                style={{ marginBottom: 0, fontWeight: 600 }}
              >{`- ${recordPosition.pos_name}`}</p>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Position description" name="pos_description">
              <p
                style={{ marginBottom: 0, fontWeight: 600 }}
              >{`- ${recordPosition.pos_description}`}</p>
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
    </Show>
  );
};
