import { Avatar, List, Space, Table, TextField, useTable } from "@pankod/refine-antd";
import React, { useEffect, useRef, useState } from "react";
import { TOKEN_KEY, API_URL } from "../../constants";
import { axiosInstance } from "authProvider";
import { HttpError } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";

interface IFetchData {
  data: ILeaveType[];
  status: number;
}

interface ILeaveType {
  id: number;
  leave_days: number;
  leave_type_code: string;
  leave_type_name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export const ListLeaveUser: React.FC = () => {
  const compId = localStorage.getItem("COMPANY_ID");
  const token = localStorage.getItem(TOKEN_KEY);
  const efRender = useRef(false);
  const [dataColum, setDataColum] = useState<ColumnsType<any>>([])
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
  } = useTable<any, HttpError, any>({
    resource: "user-profile/all/leave",
    metaData: {
      // populate: [
      //   "avatar",
      //   "branches",
      //   "companies",
      //   "user_permission",
      //   "position",
      //   "department",
      // ],
    },
    initialFilter: [
      {
        field: "companies].[id]",
        operator: "eq",
        value: compId,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    
  });

  const fetchLeaveType = async () => {
    try {
      const { data: response, status } = await axiosInstance.get<IFetchData>(
        API_URL + `/api/leave-types?filters[company][id][$eq]=${compId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (status === 200) {
        setTableColumn(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setTableColumn =(data : ILeaveType[])=> { 

    const columns: ColumnsType<any>  = [
      {
        title: "",
        dataIndex: "line_pictureUrl",
        key: "line_pictureUrl",
        align: "center",
        width: 8,
        fixed: "left",
        render: (_ , { line_pictureUrl }) => <Avatar
        size={32}
        src={line_pictureUrl}
      />,
      },
      {
        title: "Emp No",
        dataIndex: "emp_no",
        key: "emp_no",
        ellipsis: true,
        fixed: "left",
        width: 15,
        render: (text) => <TextField value={text} />,
      },
      {
        title: "Profile",
        dataIndex: "profile",
        key: "profile",
        ellipsis: true,
        fixed: "left",
        width: 30,
        render: (text) => <TextField value={text} />,
      },
      
    ]

    data.map((value:ILeaveType) => columns.push({
        title: `${value.leave_type_name}`,
        dataIndex: `${value.leave_type_name}`,
        key: `${value.leave_type_name}`,
        ellipsis: true,
        width: 15,
        render: (text) => <TextField value={text} />,
    }))

    setDataColum(columns)
    
  }
  useEffect(() => {
    if (!efRender.current) {
      fetchLeaveType();
    }

    return () => {
      efRender.current = true;
    };
  }, []);

  return (
    <List
      title="List Leave User"
      headerProps={{
        extra: <Space></Space>,
      }}
    >
      <Table
        {...tableProps}
        columns={dataColum}
        scroll={{ x: 1200}}
        size="small"
        rowKey={(val) => val.id}
      />
    </List>
  );
};

