import { Col, Icons, Timeline } from '@pankod/refine-antd'
import { BaseCard } from 'components/common/card'
import React from 'react'
interface IData {
    activity: string,
    time: string,
}
interface ITodayActivity {
    today:IData[]
}

export const TodayActivity:React.FC<ITodayActivity> = ({today}) => {
  return (
    <Col className="gutter-row" span={8}>
          <BaseCard
            size="small"
            title={
              <p style={{ fontSize: 20, fontWeight: 500 }}>Today Activity</p>
            }
          >
            <Timeline>
              {today.map((value, index) => (
                <Timeline.Item key={index}>
                  <p style={{ fontWeight: 500 }}>{value.activity}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icons.ClockCircleOutlined
                      style={{ fontSize: "12px", color: "#808080b8" }}
                    />
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#808080b8",
                      }}
                    >
                      {value.time}
                    </p>
                  </div>
                </Timeline.Item>
              ))}
            </Timeline>
          </BaseCard>
        </Col>
  )
}